import WidgetGrid from './widget_grid';
import { KrTool } from './global/tools';

export let wrapper, widgetGrid;

export function init() {
  wrapper = document.querySelector('.report-widget-grid');

  initWidgetSlider();
  initWidgetItemDrag();
  bindListeners();

  if (wrapper) {
    widgetGrid = new WidgetGrid(wrapper);
  }
}

function initWidgetSlider() {
  var slider = document.querySelector('.owl-carousel');

  if (slider !== null) {
    $(slider).owlCarousel({
      mouseDrag: false,
      dots: false,
      autoplay: false,
      nav: true,
      navText: [
        '<i class="material-icons">chevron_left</i>',
        '<i class="material-icons">chevron_right</i>'
      ],
      slideBy: 2,
      responsiveBaseElement: slider,
      responsive: {
        0: { items: 2 },
        640: { items: 3 },
        860: { items: 4 },
        1000: { items: 4 }
      }
    });
  }

};

/* ======================================================
 *   Filtering list items
   ====================================================== */
function filterListItems() {
  // declare params to be sent with the request
  var data = {
    search: $('[data-trigger="widget-search"]').val(),
    yours_only: $('.yours-only-widget-toggle :checkbox').is(':checked'),
    filter: $('.list-widget-filter-options > a').data('value'),
    sort: $('.list-widget-sort-options > a').data('value')
  };
  var targetUrl = document.querySelector('.widget-filter-nav').getAttribute('data-target');
  var busy = $('<div class="busy-container"><div class="busy"><div class="loader"><div class="circle"></div><div class="circle"></div><div class="circle"></div><div class="circle"></div></div></div></div>');
  var container = document.querySelector('.slider-container');

  container.append(busy);
  $.get(targetUrl, data, (response) => {
    $(container).empty();
    $(container).append(response);
    initWidgetSlider();
    initWidgetItemDrag();
  });
};

/**
* Bind Listeners
*/

function bindListeners() {
  $(document).on('click', '.report-popover-head', function() {
    if (!$(this).parent().hasClass('widget-edit-active')) {
      $('body').toggleClass('show-popover');

      if ($('body').hasClass('show-popover')) {
        $(this).find('.report-popover-toggle').text('expand_more');
      } else {
        $(this).find('.report-popover-toggle').text('expand_less');
      }
    }
  });

  $(document).on('click', '.report-widget-selector-item', function(e) {
    widgetGrid.add(this);
  });

  $(document).on('click', '.btn.submit-widget-grid', function(e) {
    e.preventDefault(e);
    widgetGrid.submit();
  });

  // Handle live searching within manage lists
  $('[data-trigger="widget-search"]').donetyping(function() {
    filterListItems();
  });

  $('.yours-only-widget-toggle :checkbox').change(function(e) {
    filterListItems();
  });

  $('.list-widget-sort-options .dropdown-menu [data-value], .list-widget-filter-options .dropdown-menu [data-value]').click(function(e) {
    e.preventDefault();

    // disable li element
    $(this).parent().siblings().removeClass('disabled');
    $(this).parent().addClass('disabled');

    // replace active sort option
    var $active = $(this).closest('.dropdown-menu').parent().find('>a');

    var replace_text = $(this).text();

    // For List-Items in combination with count-label
    if($(this).find('.filter-text').length > 0) {
      replace_text = $(this).find('.filter-text').text();
    }

    $active.contents().first().replaceWith(replace_text + ' ');
    $active.data('value', $(this).data('value'));

    filterListItems();
  });
}

/**
 * Make widget items in popover draggable
 */
function initWidgetItemDrag() {
  $('.report-popover .report-widget-selector-item').draggable({
    appendTo: '.report-widget-grid.grid-stack',
    revert: 'invalid',
    helper: function() {
      let element = document.createElement('div');
      element.className = 'grid-stack-item';
      let content = document.createElement('div');
      content.className = 'grid-stack-item-content';
      element.appendChild(content);
      element.setAttribute('data-gs-width', this.getAttribute('data-default-width'));
      element.setAttribute('data-gs-height', this.getAttribute('data-default-height'));
      return element;
    },
    start: function(e, ui) {
      let grid = document.querySelector('.report-widget-grid');
      grid.style.minHeight = "300px";
      let node = widgetGrid.gridstack.grid._prepareNode({
        width: parseInt(this.getAttribute('data-default-width'), 10),
        height: parseInt(this.getAttribute('data-default-height'), 10),
        _added: false,
        _temporary: true
      });

      // Mock _triggerRemoveEvent function
      node._grid = { _triggerRemoveEvent: () => {} };
      $(this).data('_gridstack_node', node);
      $('body').removeClass('show-popover');
    },
    stop: function(e, ui) {
      let grid = document.querySelector('.report-widget-grid');
      grid.style.minHeight = "0";
      $('body').addClass('show-popover');
    }
  });
}
