import type { GetterTree } from 'vuex';
import type { CustomBackgroundImageId, State } from './state';

export const Getters = {
  appState: 'appState',
  manageContributionsUrl: 'manageContributionsUrl',
  settings: 'settings',

  microphoneStream: 'microphoneStream',
  webcamStream: 'webcamStream',
  displayStream: 'displayStream',

  hasActiveVideoInput: 'hasActiveVideoInput',
  isHybridRecording: 'isHybridRecording',
  isHybridRecordingLoading: 'isHybridRecordingLoading',
  hybridStream: 'hybridStream',
  currentCountdown: 'currentCountdown',
  duration: 'duration',
  recording: 'recording',
  recordingUrl: 'recordingUrl',
  settingsDialogOpen: 'settingsDialogOpen',
  customBackgroundImage: 'customBackgroundImage',
  customBackgroundImageIds: 'customBackgroundImageIds',
  selfieSegmentationEnabled: 'selfieSegmentationEnabled',
  poseAnimatorEnabled: 'poseAnimatorEnabled',
  canEnableBlur: 'canEnableBlur',
  canEnablePoseAnimator: 'canEnablePoseAnimator',
};

export const getters: GetterTree<State, State> = {
  appState: (state) => state.appState,
  manageContributionsUrl: (state) => state.appData?.manageContributionsUrl,
  settings: (state) => state.settings,

  microphoneStream: (state) => state.microphoneStream,
  webcamStream: (state) => state.webcamStream,
  displayStream: (state) => state.displayStream,

  hasActiveVideoInput: (state) => state.webcamStream || state.displayStream,
  isHybridRecording: (state) => state.webcamStream && state.displayStream,
  isHybridRecordingLoading: (state) => state.webcamStream && state.displayStream && !state.hybridStream,
  hybridStream: (state) => state.hybridStream,
  currentCountdown: (state) => state.currentCountdown,
  duration: (state) => state.duration,
  recording: (state) => state.recording,
  recordingUrl: (state) => state.recording ? window.URL.createObjectURL(state.recording) : null,
  settingsDialogOpen: (state) => state.settingsDialogOpen,
  customBackgroundImage: (state) => (id: CustomBackgroundImageId) => state.customBackgroundImages.find((image) => image.id === id),
  customBackgroundImageIds: (state) => state.customBackgroundImages.map((background): CustomBackgroundImageId => background.id),
  selfieSegmentationEnabled: (state) => state.settings.backgroundReplacement !== undefined && state.settings.poseAnimator.view === undefined,
  poseAnimatorEnabled: (state) => state.settings.poseAnimator.view !== undefined,
  canEnableBlur: (state) => state.settings.poseAnimator.view === undefined,
  canEnablePoseAnimator: (state) => state.settings.backgroundReplacement !== 'BLUR' && state.settings.backgroundReplacement !== 'BLUR_SOFT',
};
