import { nanoid } from 'nanoid';
import type { IDBPDatabase } from 'idb';
import type BackupStoreDB from './types/backup_store_db';
import store from './store';

export default class Backup {
  private static readonly HEARTBEAT_INTERVAL = 3000;

  public readonly id: string;
  private readonly db: IDBPDatabase<BackupStoreDB>;
  private intervalHeartbeat?: number;
  private isStopped = false;

  public static async createInDB(db: IDBPDatabase<BackupStoreDB>): Promise<Backup> {
    const recordingId = nanoid();

    await db.add('recordings', {
      id: recordingId,
      heartbeat: Date.now(),
      userId: store.state.appData!.userId,
      siteId: store.state.appData!.siteId,
      duration: 0,
    });

    return new Backup(recordingId, db);
  }

  constructor(id: string, db: IDBPDatabase<BackupStoreDB>) {
    this.id = id;
    this.db = db;

    this.startHeartbeat();
  }

  private async update(changes: Partial<BackupStoreDB['recordings']['value']>) {
    const tx = this.db.transaction('recordings', 'readwrite');
    const recoding = await tx.store.get(this.id);
    await tx.store.put({ ...recoding, ...changes } as BackupStoreDB['recordings']['value']);
    await tx.done;
  }

  private startHeartbeat(): void {
    const updateHeartbeat = async () => this.update({ heartbeat: Date.now() });

    updateHeartbeat();

    this.intervalHeartbeat = window.setInterval(updateHeartbeat, Backup.HEARTBEAT_INTERVAL);
  }

  public async addChunk(data: Blob, currentDuration: number): Promise<void> {
    if (this.isStopped) {
      return;
    }

    try {
      await this.db.add('chunks', { recordingId: this.id, data });

      await this.update({
        duration: currentDuration,
      });
    } catch (e) {
      if (e instanceof Error) {
        if (e.name !== 'QuotaExceededError') {
          throw e;
        }

        console.warn('[CAPTURE] backup stopped: no free disc space');
        this.isStopped = true;
      }
    }
  }

  public getChunksCount(): Promise<number> {
    return this.db.countFromIndex('chunks', 'byRecordingId', this.id);
  }

  public async getChunks(): Promise<Blob[]> {
    const chunks = await this.db.getAllFromIndex('chunks', 'byRecordingId', this.id);

    return chunks.map((entry) => entry.data);
  }

  public async getDuration(): Promise<number> {
    const backup = await this.db.get('recordings', this.id);

    return backup?.duration ?? 0;
  }

  public async setUploadFileName(uploadFileName: string): Promise<void> {
    await this.update({ uploadFileName });
  }

  public async getUploadFileName(): Promise<string | null> {
    const backup = await this.db.get('recordings', this.id);

    return backup?.uploadFileName || null;
  }

  public async destroy(): Promise<void> {
    window.clearInterval(this.intervalHeartbeat);

    await this.db.delete('recordings', this.id);

    const { store } = this.db.transaction('chunks', 'readwrite');
    const index = store.index('byRecordingId');

    let cursor = await index.openCursor(this.id);
    while (cursor) {
      cursor.delete();
      cursor = await cursor.continue();
    }
  }
}
