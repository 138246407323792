import AudioPlayer from './audio_player';

export let wrapper, parameters, ssmlEditor, playerWrapper, audioPlayer, ttsAvailable;
let durationInput, durationSlider;

export function initModal (params) {
  parameters = params;
  wrapper = document.getElementById('scene_settings_modal');
  playerWrapper = wrapper.querySelector('.tts-preview-player');
  ttsAvailable = wrapper.querySelector('.slide-settings-modal-content').getAttribute('data-tts-available') === 'true';

  if (ttsAvailable) {
    audioPlayer = new AudioPlayer(playerWrapper, {
      onRequest: () => {
        wrapper.querySelector('.slide-settings-tts-error').classList.add('hide');
      },
      onError: (status, response) => {
        let errorWrapper = wrapper.querySelector('.slide-settings-tts-error');
        errorWrapper.innerText = response.error;
        errorWrapper.classList.remove('hide');
      }
    });

    let options = JSON.parse(wrapper.querySelector('.slide-settings-modal-content').getAttribute('data-synthify'));
    ssmlEditor = new Synthify(wrapper.querySelector('.ssml-editor'), options);
  }


  durationSlider = wrapper.querySelector('.slide-settings-duration');
  durationInput = wrapper.querySelector('.slide-settings-duration-input');

  noUiSlider.create(durationSlider, {
    tooltips: true,
    format: wNumb({ decimals: 0 }),
    start: [ parseInt(parameters.sceneDuration, 10) ],
    step: 1,
    range: {
      min: [ parseInt(durationInput.getAttribute('min'), 10) ],
      max: [ parseInt(durationInput.getAttribute('max'), 10) ]
    }
  });

  setParameters(parameters);
  bindListeners();
}

export function getParameters () {
  if (ttsAvailable) {
    parameters.ttsText = ssmlEditor.getSSML();
    let speakers = ssmlEditor.getSpeakers();

    if (speakers.length > 0) {
      parameters.speaker = speakers[0].name;
    }
  } else {
    parameters.ttsEnabled = false;
    parameters.speaker = '';
    parameters.ttsText = '';
  }

  return parameters;
}

function setParameters(parameters) {
  let typeTts = wrapper.querySelector('.slide-settings-type[value="tts"]'),
      typeStatic = wrapper.querySelector('.slide-settings-type[value="static"]'),
      settingsTts = wrapper.querySelectorAll('.slide-settings-tts'),
      settingsStatic = wrapper.querySelectorAll('.slide-settings-static'),
      sceneDuration = wrapper.querySelector('.slide-settings-duration-input'),
      slidePreview = wrapper.querySelector('.slide-settings-slide-preview img'),
      ttsActive = ttsAvailable && parameters.ttsEnabled;

  if (ssmlEditor) {
    ssmlEditor.setSSML(parameters.ttsText);
  } else {
    typeTts.parentNode.parentNode.style.display = 'none';

  }
  
  sceneDuration.value = parameters.sceneDuration;
  slidePreview.src = parameters.previewImage;

  // set radio group value
  ttsActive ? typeTts.checked = true : typeStatic.checked = true;

  wrapper.querySelector('.slide-settings-modal-content').setAttribute('data-setting', ttsActive ? 'tts' : 'static');

  for(let i = 0; i < settingsTts.length; i++) {
    ttsActive ? settingsTts[i].classList.remove('hide') : settingsTts[i].classList.add('hide');
  }

  for(let i = 0; i < settingsStatic.length; i++) {
    ttsActive ? settingsStatic[i].classList.add('hide') : settingsStatic[i].classList.remove('hide');
  }
}

function toggleHistory (show) {
  let toggleButton = wrapper.querySelector('.tts-history-toggle');
  let ttsHistory = wrapper.querySelector('.tts-history');
  show = typeof show === 'undefined' ? !toggleButton.classList.contains('active') : show;

  if (show) {
    toggleButton.classList.add('active');
    ttsHistory.classList.add('tts-history-open');
    refreshHistory();
  } else {
    toggleButton.classList.remove('active');
    ttsHistory.classList.remove('tts-history-open');
  }

  toggleButton.blur();
}

function refreshHistory () {
  let ttsHistory = wrapper.querySelector('.tts-history');
  ttsHistory.innerHTML = '';

  let xhr = new XMLHttpRequest();
  xhr.open('GET', ttsHistory.getAttribute('data-url'), true);
  xhr.responseType = 'json';

  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4 && xhr.status >= 200 && xhr.status < 300) {
      let source = document.getElementById('tts_history_item_template').innerText;
      let template = Handlebars.compile(source);

      for (let i = 0; i < xhr.response.results.length; i++) {
        let html = template(xhr.response.results[i]);
        ttsHistory.innerHTML += html;
      }
    }
  };

  xhr.send();
}

function bindListeners () {
  audioPlayer && audioPlayer.player.addEventListener('beforeplay', (e) => {
    e.preventDefault();
    let url = playerWrapper.getAttribute('data-url');
    let text = ssmlEditor.getSSML();
    let speakers = ssmlEditor.getSpeakers();

    if (speakers.length > 0) {
      url = `${url}?speaker=${speakers[0].name}&engine=${speakers[0].engine}&text=${encodeURIComponent(text)}`;
      audioPlayer.loadAndPlay(url);
    }
  });

  wrapper.querySelector('.tts-history-toggle').addEventListener('click', (e) => {
    toggleHistory();
  });

  let slideSettingsType = wrapper.querySelectorAll('.slide-settings-type');

  for (let i = 0; i < slideSettingsType.length; i++) {
    let element = slideSettingsType[i];

    element.addEventListener('click', (e) => {
      let params = getParameters();
      params.ttsEnabled = element.value === 'tts';
      setParameters(params);
    });
  }

  wrapper.addEventListener('click', e => {
    if (!e.target.classList.contains('tts-history-toggle') && !e.target.closest('.tts-history')) {
      toggleHistory(false);
    }
  });

  durationSlider.noUiSlider.on('update', (values, handle) => {
    parameters.sceneDuration = values[0];
    durationInput.value = values[0];
  });

  wrapper.querySelector('.tts-history').addEventListener('click', (e) => {
    let item = e.target.closest('.tts-history-item');
    let ssml = item.querySelector('.tts-history-item-text').getAttribute('data-ssml');

    ssmlEditor.setSSML(ssml);
  });
}
