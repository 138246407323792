import { Take, SearchResult, Slideshow, SelectionMode, Clip, ResultPagination, Settings, Image } from "../interfaces";
import { v4 as uuid } from 'uuid';

export interface LibraryState {
  brandUrl: string;
  apiBaseUrl: string;
  uploadUrl: string;
  mediaTypes: Array<'Take' | 'Document' | 'Clip' | 'Image'>;
  privateTakes: {
    results: Array<Take>,
    totalEntries: number,
    route: string,
    infinityId: string,
  };
  publicTakes: {
    results: Array<Take>,
    totalEntries: number,
    route: string,
    infinityId: string,
  };
  documents: {
    results: Array<Slideshow>,
    totalEntries: number,
    route: string,
    infinityId: string,
  };
  clips: {
    results: Array<Clip>,
    totalEntries: number,
    route: string,
    infinityId: string,
  };
  images: {
    results: Array<any>,
    totalEntries: number,
    route: string,
    infinityId: string,
  };
  uploadedTakes: Array<Take>;
  searchResults: Array<SearchResult>;
  activeTab: string;
  selectedTakes: Array<number>;
  selectedSlides: Array<number>;
  selectedImages: Array<number>;
  inspectItem: Take | Slideshow | Image;
  selectionMode: SelectionMode;
  settings: Settings,
  showShareAndDeleteButton: boolean,
}

export const state: LibraryState = {
  brandUrl: null,
  uploadUrl: null,
  apiBaseUrl: null,
  mediaTypes: ['Take', 'Document', 'Clip', 'Image'],
  privateTakes: {
    results: [],
    totalEntries: null,
    route: 'private_takes',
    infinityId: uuid()
  },
  publicTakes: {
    results: [],
    totalEntries: null,
    route: 'approved_takes',
    infinityId: uuid()
  },
  documents: {
    results: [],
    totalEntries: null,
    route: 'documents',
    infinityId: uuid()
  },
  clips: {
    results: [],
    totalEntries: null,
    route: 'clips',
    infinityId: uuid()
  },
  images: {
    results: [],
    totalEntries: null,
    route: 'images',
    infinityId: uuid()
  },
  uploadedTakes: [],
  activeTab: 'privateTakes',
  searchResults: [],
  selectedTakes: [],
  selectedSlides: [],
  selectedImages: [],
  inspectItem: null,
  selectionMode: SelectionMode.Multiple,
  showShareAndDeleteButton: false,
  settings: {
    releaseTakeRole: null,
    canCreateTopics: false,
    createClipUrl: null,
    reviewSharedTakes: false,
    shouldReviewSharedTakes: false
  }
}
