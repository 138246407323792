import { FilterColumn } from './filter_column';
import { onlyMyOwnFilter, onlyDeleted } from './preset_filters';
import { TopicAreaTree } from './filter/topic_area_tree.js';


export class Filter {
  constructor (grid) {
    this.grid = grid;
    this.wrapper = grid.gridElement.querySelector('.contribution-grid-filter');
    this.input = this.wrapper.querySelector('.contribution-grid-filter-control');
    this.filtersWrapper = this.wrapper.querySelector('.contribution-grid-filters-wrapper');
    this.filterableColumns = [];
    this.topicAreaTree = new TopicAreaTree("topic_area_filter_tree", "TopicArea");
    this.prepareFilterableColumns();
    this.initializeFieldSelect();
    this.initializeInput();
    this.parseGridFilter(grid.getGridOptions().filter || []);
    this.bindKeyListener();
    this.bindFilterButton();
    this.initFilterCallbacks();
  }

  /**
   * Transform the saved filters from cookie and set them
   * for the initial state
   * @param {Array} filters List of filters
   */
  parseGridFilter (filters) {
    filters.forEach(filter => {
      let filterColumn = this.getFilterColumn(filter.field);
      filterColumn.filters = filter.filters;
    });
  }

  /**
   * Called every time the kendo DataSource is bound
   * @param {Object} dataFilter DataSource filters
   */
  updateFilters (dataFilter) {
    if (dataFilter && dataFilter.filters.length > 0) {
      dataFilter = dataFilter.filters.reduce((map, filter) => {
        map[filter.field] = map[filter.field] || [];
        map[filter.field].push(filter);

        return map;
      }, {});

      this.filterableColumns.forEach(filterColumn => {
        filterColumn.filters = dataFilter[filterColumn.field];
      });

    } else {
      // Clear all filters
      this.filterableColumns.forEach(column => {
        column.filters = null;
      });
    }

    // Persist active filters in Cookie
    this.grid.setGridOptions({
      filter: this.activeFilters().map(column => {
        return {
          field: column.field,
          filters: column.filters.map(filter => {
            return {
              field: filter.field,
              operator: filter.operator,
              value: column.filterMenu.type === 'date' ? kendo.toString(filter.value, 'yyyy/MM/dd') : filter.value,
              readOnly: filter.readOnly
            };
          })
        };
      })
    });
  }

  /**
   * Called every time a filter is added or removed.
   * If e.filter is null, the filter has been removed
   * @param {Event} e Filter details
   */
  filterChange (e) {
    // Check if a callback was defined for filter change
    if ({}.hasOwnProperty.call(this.filterCallbacks, e.field)) {
      this.filterCallbacks[e.field](e);
    }

    // Clear filter input
    this.input.value = '';
  }

  initFilterCallbacks() {
    this.filterCallbacks = {
      is_deleted: (e) => {
        let column = this.grid.kendoGrid.columns.find(col => col.name === 'select');

        if (e.filter === null) {
          this.grid.kendoGrid.showColumn(column);
        } else {
          this.grid.kendoGrid.hideColumn(column);
        }

        this.grid.kendoGrid.clearSelection();
      }
    }
  }

  /**
   * Find the FilterColumn entity for the given field name
   * @param {String} field Field name
   */
  getFilterColumn (field) {
    return this.filterableColumns.find(column => column.field === field);
  }

  /**
   * Returns FilterColumns, which have an active filter
   */
  activeFilters () {
    return this.filterableColumns.filter(column => column.isActive());
  }

  /**
   * Returns FilterColumns, which don't have an active filter
   */
  inactiveFilters () {
    return this.filterableColumns.filter(column => !column.isActive());
  }

  /**
   * Intialize the dummy filter input
   */
  initializeInput () {
    this.input.addEventListener('focus', () => {
      this.showFieldSelect();
    });
  }

  /**
   * Initialize FilterColumn object, based of configured kendoUI grid columns.
   */
  prepareFilterableColumns () {
    this.grid.kendoGrid.columns.forEach(column => {
      if (column.filterable) {
        let filterColumn = new FilterColumn(column, this);
        filterColumn.attach(this.input);

        this.filterableColumns.push(filterColumn);
      }
    });
  }

  /**
   * Setup dropdown for filter field selection
   */
  initializeFieldSelect () {
    this.fieldSelectWrapper = document.createElement('div');
    this.fieldSelectWrapper.className = 'contribution-grid-filter-fields';
    
    this.fieldSelect = $(this.fieldSelectWrapper).kendoPopup({
      anchor: this.input,
      appendTo: this.wrapper
    }).data('kendoPopup');
  }

  /**
   * Update field select dropdown
   */
  updateFieldSelect () {
    let dropdown = document.createElement('ul');
    let inactiveFilters = this.inactiveFilters();

    if (this.input.value.length > 0) {
      // Scope filters to user query
      inactiveFilters = inactiveFilters.filter(column => column.title.toLowerCase().indexOf(this.input.value.toLowerCase()) !== -1);

      // Add quick filter at the top
      let filterColumn = this.filterableColumns[0];

      let suggestItem = this.buildFieldItem(filterColumn, this.input.value, () => {
        let filter = filterColumn.filterMenu._defaultFilter();
        filter.value = this.input.value;
        filterColumn.filterMenu.filter({ filters: [filter]});
        
        this.input.value = '';
        this.input.blur();
        this.fieldSelect.close();
      });

      dropdown.appendChild(suggestItem);

      if (inactiveFilters.length > 0) {
        dropdown.appendChild(document.createElement('hr'));
      }
    }
    
    inactiveFilters.forEach((filterColumn) => {
      // New option to set filterable only for preset filter
      let onlyPreset = filterColumn.kendoColumn.filterable.onlyPreset;
      if(!onlyPreset) {
        let fieldItem = this.buildFieldItem(filterColumn, null, () => {
          this.fieldSelect.close();
          filterColumn.open();
        });

        dropdown.appendChild(fieldItem);
      }
    });

    this.fieldSelectWrapper.innerHTML = '';
    this.fieldSelectWrapper.appendChild(dropdown);

    this.buildPresetFilters(dropdown);
  }

  /**
   * Append preset filters to field select dropdown
   * @param {DOMObject} dropdown Dropdown element
   */
  buildPresetFilters (dropdown) {
    if (this.inactiveFilters().length > 0 || this.input.value.length > 0) {
      dropdown.appendChild(document.createElement('hr'));
    }

    // Only contributions created by current user
    let onlyMyOwn = onlyMyOwnFilter(this);
    dropdown.appendChild(onlyMyOwn);
    dropdown.appendChild(onlyDeleted(this));
  }

  /**
   * Build a field item for field dropdown
   * @param {FilterColumn} filterColumn Column for the field item
   * @param {String} text Suggestion text
   * @param {Function} onclick Will be triggered when item is selected
   */
  buildFieldItem (filterColumn, text = null, onclick = null) {
    let fieldItem = document.createElement('li');
    let fieldItemLink = document.createElement('a');
    fieldItemLink.href = '#';
    fieldItemLink.innerText = filterColumn.title;

    if (text) {
      let operator = filterColumn.filterOperator(filterColumn.filterModel.filters[0].operator);
      fieldItemLink.innerText += ` ${operator} '${text}'`;
    }

    fieldItem.appendChild(fieldItemLink);

    if (onclick) {
      fieldItemLink.addEventListener('click', onclick);
    }

    return fieldItem;
  }

  /**
   * Open field select dropdown
   */
  showFieldSelect () {
    this.updateFieldSelect();
    this.fieldSelect.open();
  }

  /**
   * Bind key listeners to navigate field dropdown.
   * On Return, select the currently active field
   */
  bindKeyListener () {
    this.input.addEventListener('keyup', e => {
      let code = e.keyCode;

      if (code === 13) {
        // Return Key
        // Apply the first filter with value from input

        let field = this.fieldSelectWrapper.querySelector('li > a.active');

        if (!field) {
          field = this.fieldSelectWrapper.querySelector('li > a');
        }

        if (field) {
          field.click();
        }
      } else if (code === 38) {
        // Arrow up Key
        this.selectNextPrevField(-1);
        return;
      } else if (code === 40) {
        // Arrow down Key
        this.selectNextPrevField(1);
        return;
      }

      this.updateFieldSelect();
    });
  }

  bindFilterButton() {
    this.filterButton = document.querySelector('.contribution-grid-filter-icon');
    this.filterButton.addEventListener('click', (e) => {
      this.input.focus();
    });
  }

  /**
   * Set previous or next element in filter dropdown
   */
  selectNextPrevField (modifier) {
    let elements = this.fieldSelectWrapper.querySelectorAll('ul > li > a');

    if (this.fieldSelectWrapper.querySelector('li > a.active')) {
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].classList.contains('active')) {
          elements[i].classList.remove('active');

          let index = i + modifier;

          if (index < 0) {
            index = elements.length - 1;
          } else if (index > elements.length - 1) {
            index = 0;
          }

          elements[index].classList.add('active');
          break;
        }
      }
    } else {
      elements[modifier < 0 ? elements.length - 1 : 0].classList.add('active');
    }
  }
}
