export interface Take {
  id: number;
  amor_id: number;
  type: string;
  title: string;
  description: string;
  thumbnail_url: string;
  state: string;
  asset_state: string;
  asset_updated_at: string;
  created_at: string;
  updated_at: string;
  user: User;
  video: Video;
  added_at?: string;
  uploading?: boolean;
  converting?: boolean;
  upload_progress?: number;
  convert_progress?: number;
  can_manage?: boolean;
  is_locked?: boolean;
  last_locking_user_id?: number;
  last_locking_user?: string;
  is_cloning?: boolean;
  has_barcode?: boolean;
  retry_production_url?: string;
  original_url?: string;
}

export interface Clip {
  id: number;
  type: string;
  title: string;
  description: string;
  thumbnail_url: string;
  state: string;
  created_at: string;
  updated_at: string;
  user: User;
  video: Video;
}

export interface User {
  id: number;
  anyname: string;
  avatar: string;
}

export interface Video {
  audio_codec: string;
  bitrate: number;
  duration: number;
  frame_rate: number;
  resolution_height: number;
  resolution_width: number;
  size: number;
  url?: string;
  video_codec: string;
  type?: string;
  sources?: Array<VideoSource>;
}

export interface VideoSource {
  src: string;
  type: string;
  res: number;
  label: string;
}

export interface Slideshow {
  id: number;
  type: string;
  title: string;
  description: string;
  state: string;
  thumbnail_url: string;
  created_at: string;
  updated_at: string;
  slides_count: number;
  page_quantity: number;
  slides: Array<Slide>;
  user: User;
  can_manage?: boolean;
  picturize_job?: number;
}

export interface Slide {
  id: number;
  page: number;
  src: string;
  thumbnail_url: string;
  type: string;
  title?: string;
  added_at?: string;
}

export interface Image {
  id: number;
  type: string;
  title: string;
  description: string;
  state: string;
  thumbnail_url: string;
  user: User;
  src?: string;
  can_manage?: boolean;
  added_at?: string;
}

export interface SearchResult {
  query: string,
  results: Array<Take | Slideshow | Clip | Image>,
  filter: SearchFilter
}

export enum SearchFilter {
  ALL = 'all',
  PRIVATE_TAKES = 'private_takes',
  PUBLIC_TAKES = 'public_takes',
  DOCUMENTS = 'documents',
  CLIPS = 'clips',
  IMAGES = 'images'
}

export enum SelectionMode {
  None = 'none',
  Single = 'single',
  Multiple = 'multiple'
}

export interface ResultPagination {
  currentPage: number,
  perPage: number,
  totalEntries: number,
  totalPages: number
}

export interface Settings {
  releaseTakeRole: ReleaseTakeRoleSetting,
  canCreateTopics: boolean,
  createClipUrl: string,
  reviewSharedTakes: boolean,
  shouldReviewSharedTakes: boolean
}

export enum ReleaseTakeRoleSetting {
  AUTHOR = 'author',
  TRAINER = 'trainer',
  REVIEWER = 'reviewer',
  EDITOR = 'editor',
  MASTER_EDITOR = 'master_editor',
  PUBLISHER = 'publisher'
}
