import type { MutationTree } from 'vuex';
import type { Settings, State, CustomBackgroundImage } from './state';

export const Mutations = {
  SET_APP_DATA: 'SET_APP_DATA',
  SET_SETTINGS: 'SET_SETTINGS',
  SET_APP_STATE: 'SET_APP_STATE',
  SET_MICROPHONE_STREAM: 'SET_MICROPHONE_STREAM',
  SET_WEBCAM_STREAM: 'SET_WEBCAM_STREAM',
  SET_DISPLAY_STREAM: 'SET_DISPLAY_STREAM',
  SET_HYBRID_STREAM: 'SET_HYBRID_STREAM',
  SET_CURRENT_COUNTDOWN: 'SET_CURRENT_COUNTDOWN',
  SET_DURATION: 'SET_DURATION',
  SET_RECORDING: 'SET_RECORDING',
  SET_CUSTOM_BACKGROUND_IMAGES: 'SET_CUSTOM_BACKGROUND_IMAGES',
  ADD_CUSTOM_BACKGROUND_IMAGE: 'ADD_CUSTOM_BACKGROUND_IMAGE',
  REMOVE_CUSTOM_BACKGROUND_IMAGE: 'REMOVE_CUSTOM_BACKGROUND_IMAGE',
  SET_SETTINGS_DIALOG_OPEN: 'SET_SETTINGS_DIALOG_OPEN',
  SET_SETTING_COUNTDOWN_DURATION: 'SET_SETTING_COUNTDOWN_DURATION',
  SET_SETTING_BACKUP_ACTIVATED: 'SET_SETTING_BACKUP_ACTIVATED',
  SET_SETTING_WEBCAM_RATIO: 'SET_SETTING_WEBCAM_RATIO',
  SET_SETTING_MIRROR_WEBCAM: 'SET_SETTING_MIRROR_WEBCAM',
  SET_SETTING_BACKGROUND_REPLACEMENT: 'SET_SETTING_BACKGROUND_REPLACEMENT',
  SET_SETTING_POSE_ANIMATOR_VIEW: 'SET_SETTING_POSE_ANIMATOR_VIEW',
  SET_SETTING_POSE_ANIMATOR_AVATAR: 'SET_SETTING_POSE_ANIMATOR_AVATAR',
  SET_SETTING_POSE_ANIMATOR_OUTFIT: 'SET_SETTING_POSE_ANIMATOR_OUTFIT',
};

export const mutations: MutationTree<State> = {
  [Mutations.SET_APP_DATA](state, appData: State['appData']) {
    state.appData = appData;
  },
  [Mutations.SET_SETTINGS](state, settings: State['settings']) {
    state.settings = settings;
  },
  [Mutations.SET_APP_STATE](state, appState: State['appState']) {
    state.appState = appState;
  },
  [Mutations.SET_MICROPHONE_STREAM](state, stream: State['microphoneStream']) {
    state.microphoneStream = stream;
  },
  [Mutations.SET_WEBCAM_STREAM](state, stream: State['webcamStream']) {
    state.webcamStream = stream;
  },
  [Mutations.SET_DISPLAY_STREAM](state, stream: State['displayStream']) {
    state.displayStream = stream;
  },
  [Mutations.SET_HYBRID_STREAM](state, hybridStream: State['hybridStream']) {
    state.hybridStream = hybridStream;
  },
  [Mutations.SET_CURRENT_COUNTDOWN](state, countdown: State['currentCountdown']) {
    state.currentCountdown = countdown;
  },
  [Mutations.SET_DURATION](state, duration: State['duration']) {
    state.duration = duration;
  },
  [Mutations.SET_RECORDING](state, recording: State['recording']) {
    state.recording = recording;
  },
  [Mutations.SET_CUSTOM_BACKGROUND_IMAGES](state, backgroundImages: CustomBackgroundImage[]) {
    state.customBackgroundImages = backgroundImages;
  },
  [Mutations.ADD_CUSTOM_BACKGROUND_IMAGE](state, backgroundImage: CustomBackgroundImage) {
    state.customBackgroundImages = [...state.customBackgroundImages, backgroundImage];
  },
  [Mutations.REMOVE_CUSTOM_BACKGROUND_IMAGE](state, backgroundImageId: CustomBackgroundImage['id']) {
    state.customBackgroundImages = state.customBackgroundImages.filter((backgroundImage) => backgroundImage.id !== backgroundImageId);
  },
  [Mutations.SET_SETTINGS_DIALOG_OPEN](state, open: State['settingsDialogOpen']) {
    state.settingsDialogOpen = open;
  },
  [Mutations.SET_SETTING_COUNTDOWN_DURATION](state, duration: Settings['countdownDuration']) {
    state.settings = {
      ...state.settings,
      countdownDuration: duration,
    };
  },
  [Mutations.SET_SETTING_BACKUP_ACTIVATED](state, activated: Settings['backupActivated']) {
    state.settings = {
      ...state.settings,
      backupActivated: activated,
    };
  },
  [Mutations.SET_SETTING_WEBCAM_RATIO](state, ratio: Settings['webcamRatio']) {
    state.settings = {
      ...state.settings,
      webcamRatio: ratio,
    };
  },
  [Mutations.SET_SETTING_MIRROR_WEBCAM](state, mirror: Settings['mirrorWebcam']) {
    state.settings = {
      ...state.settings,
      mirrorWebcam: mirror,
    };
  },
  [Mutations.SET_SETTING_BACKGROUND_REPLACEMENT](state, backgroundReplacement: Settings['backgroundReplacement']) {
    state.settings = {
      ...state.settings,
      backgroundReplacement,
    };
  },
  [Mutations.SET_SETTING_POSE_ANIMATOR_VIEW](state, view: Settings['poseAnimator']['view']) {
    state.settings = {
      ...state.settings,
      poseAnimator: {
        ...state.settings.poseAnimator,
        view,
      },
    };
  },
  [Mutations.SET_SETTING_POSE_ANIMATOR_AVATAR](state, avatar: Settings['poseAnimator']['avatar']) {
    state.settings = {
      ...state.settings,
      poseAnimator: {
        ...state.settings.poseAnimator,
        avatar,
      },
    };
  },
  [Mutations.SET_SETTING_POSE_ANIMATOR_OUTFIT](state, outfit: Settings['poseAnimator']['outfit']) {
    state.settings = {
      ...state.settings,
      poseAnimator: {
        ...state.settings.poseAnimator,
        outfit,
      },
    };
  },
};
