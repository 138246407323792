import Vue from 'vue';
import { MutationTree } from 'vuex';
import { Notification } from '../entities/notification';
import { UserSettings, WebConferenceState } from './state';
import { saveUserSettingsToLS } from '../user_settings_store';

export const Mutations = {
  SET_STATE: 'SET_STATE',
  SET_URLS: 'SET_URLS',
  SET_ROOM: 'SET_ROOM',
  SET_DETAILS: 'SET_DETAILS',
  SET_SETTINGS: 'SET_SETTINGS',
  SET_USER_DETAILS: 'webConference/setUserDetails',
  SET_PARTICIPANTS: 'SET_PARTICIPANTS',
  ADD_PARTICIPANT: 'ADD_PARTICIPANT',
  REMOVE_PARTICIPANT: 'REMOVE_PARTICIPANT',
  SET_LOCAL_PARTICIPANT: 'SET_LOCAL_PARTICIPANT',
  ADD_CHAT_MESSAGES: 'ADD_CHAT_MESSAGES',
  SET_ROOM_NAME: 'SET_ROOM_NAME',
  SET_SHARED_SCREEN_PARTICIPANT: 'SET_SHARED_SCREEN_PARTICIPANT',
  SET_LOCAL_VIDEO_TRACK: 'SET_LOCAL_VIDEOTRACK',
  SET_LOCAL_AUDIO_TRACK: 'SET_LOCAL_AUDIO_TRACK',
  ADD_RAISE_HAND_NOTIFICATIONS: 'ADD_RAISE_HAND_NOTIFICATIONS',
  REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
  SET_IS_GUEST: 'SET_IS_GUEST',
  SET_MESSAGE_SEEN: 'SET_MESSAGE_SEEN',
  SET_STARTING_TIME: 'SET_STARTING_TIME',
  SET_TEXT_MESSAGES: 'SET_TEXT_MESSAGES',
  SET_IS_RECORDING: 'SET_IS_RECORDING',
  ADD_MODAL: 'ADD_MODAL',
  REMOVE_MODAL: 'REMOVE_MODAL',
  REMOVE_ALL_MODAL_EXCEPT: 'REMOVE_ALL_MODAL_EXCEPT',
  SET_SETTINGS_OPENED: 'SET_SETTINGS_OPENED',
  SET_MIC_ACTIVATED: 'SET_MIC_ACTIVATED',
  SET_CAM_ACTIVATED: 'SET_CAM_ACTIVATED',
  SET_BREAKOUT_STARTED: 'SET_BREAKOUT_STARTED',
  SET_IS_BREAKOUT: 'SET_IS_BREAKOUT',
  SET_LIVEKIT_URL: 'SET_LIVEKIT_URL',
  SET_ON_JOIN_PAGE: 'SET_ON_JOIN_PAGE',
  SET_USER_SETTINGS: 'SET_USER_SETTINGS',
  SET_USER_SETTING_SELECTED_MIC: 'SET_USER_SETTING_SELECTED_MIC',
  SET_USER_SETTING_SELECTED_CAM: 'SET_USER_SETTING_SELECTED_CAM',
  SET_USER_SETTING_SELECTED_AUDIO: 'SET_USER_SETTING_SELECTED_AUDIO',
  SET_USER_SETTING_CONFERENCE_LAYOUT: 'SET_USER_SETTING_CONFERENCE_LAYOUT',
};

export const mutations: MutationTree<WebConferenceState> = {
  [Mutations.SET_STATE] (state, payload: WebConferenceState) {
    Object.assign(state, payload);
  },
  [Mutations.SET_URLS] (state, payload) {
    state.urls = payload;
  },
  [Mutations.SET_SHARED_SCREEN_PARTICIPANT] (state,payload) {
    state.sharedScreenParticipant = payload;
  },
  [Mutations.SET_DETAILS] (state, payload){
    state.details = payload;
  },
  [Mutations.SET_ROOM] (state, payload) {
    state.room = payload;
  },
  [Mutations.SET_SETTINGS] (state, payload) {
    state.settings = payload;
  },
  [Mutations.SET_USER_DETAILS] (state,payload) {
    state.userDetails = payload;
  },
  [Mutations.SET_PARTICIPANTS] (state, payload) {
    state.participants = payload;
  },
  [Mutations.ADD_PARTICIPANT] (state, payload){
    state.participants.push(payload);
  },
  [Mutations.REMOVE_PARTICIPANT] (state, payload){
    const removeIndex = state.participants.findIndex(participant => participant.sid === payload.sid);
    state.participants.splice(removeIndex, 1);
  },
  [Mutations.ADD_CHAT_MESSAGES] (state, payload) {
    state.chatMessages.push(payload);
  },
  [Mutations.SET_TEXT_MESSAGES] (state, payload) {
    state.chatMessages = payload;
  },
  [Mutations.SET_LOCAL_AUDIO_TRACK] (state, payload) {
    state.localAudioTrack = payload;
  },
  [Mutations.SET_LOCAL_VIDEO_TRACK] (state, payload) {
    state.localVideoTrack = payload;
  },
  [Mutations.SET_IS_GUEST] (state, payload) {
    state.isGuest = payload;
  },
  [Mutations.SET_MESSAGE_SEEN] (state, payload) {
    state.chatMessages.find(message => message.id == payload).seen = true;
  },
  [Mutations.SET_STARTING_TIME] (state, payload) {
    state.startingTime = payload;
  },
  [Mutations.SET_IS_RECORDING] (state, payload) {
    state.isRecording = payload;
  },
  [Mutations.ADD_MODAL] (state, payload) {
    state.modals.push(payload);
  },
  [Mutations.REMOVE_MODAL] (state, payload) {
    state.modals.splice(payload, 1);
  },
  [Mutations.REMOVE_ALL_MODAL_EXCEPT] (state, payload) {
    for(let i = 0 ; i < state.modals.length; i++) {
      if(!(i == payload)) {
        state.modals.splice(i, 1);
      }
    }
  },
  [Mutations.SET_SETTINGS_OPENED] (state, payload) {
    state.settingsOpened = payload;
  },
  [Mutations.SET_MIC_ACTIVATED] (state, payload) {
    state.micActivated = payload;
  },
  [Mutations.SET_CAM_ACTIVATED] (state, payload) {
    state.camActivated = payload;
  },
  [Mutations.SET_BREAKOUT_STARTED] (state, payload) {
    state.breakoutStarted = payload;
  },
  [Mutations.SET_IS_BREAKOUT] (state, payload) {
    state.isBreakout = payload;
  },
  [Mutations.SET_LIVEKIT_URL] (state, payload) {
    state.livekitUrl = payload;
  },
  [Mutations.REMOVE_NOTIFICATION] (state, payload : Notification) {
    state.notifications = state.notifications.filter(notification => notification.id != payload.id)
  },
  [Mutations.SET_ON_JOIN_PAGE] (state, payload) {
    state.onJoinPage = payload;
  },
  [Mutations.SET_USER_SETTINGS](state, settings: Partial<UserSettings>) {
    state.userSettings = {
      ...state.userSettings,
      ...settings,
    };
  },
  [Mutations.SET_USER_SETTING_SELECTED_MIC](state, mic: UserSettings['selectedMic']) {
    state.userSettings = {
      ...state.userSettings,
      selectedMic: mic,
    }
    saveUserSettingsToLS(state.userSettings);
  },
  [Mutations.SET_USER_SETTING_SELECTED_CAM](state, cam: UserSettings['selectedCam']) {
    state.userSettings = {
      ...state.userSettings,
      selectedCam: cam,
    }
    saveUserSettingsToLS(state.userSettings);
  },
  [Mutations.SET_USER_SETTING_SELECTED_AUDIO](state, audio: UserSettings['selectedAudio']) {
    state.userSettings = {
      ...state.userSettings,
      selectedAudio: audio,
    }
    saveUserSettingsToLS(state.userSettings);
  },
  [Mutations.SET_USER_SETTING_CONFERENCE_LAYOUT](state, layout: UserSettings['conferenceLayout']) {
    state.userSettings = {
      ...state.userSettings,
      conferenceLayout: layout,
    }
    saveUserSettingsToLS(state.userSettings);
  },
};
