import { Question, Answer } from '../entities/';
import { DragAndDropOption } from '../entities/drag_and_drop_option';
import { MatchingOption } from '../entities/matching_option';

export interface KeyValue<T> {
  [key: number]: T,
  [key: string]: T
}

export interface Entities {
  questions?: KeyValue<Question>,
  answers?: KeyValue<Answer>,
  matchingAnswers?: KeyValue<Answer>,
  matchingOptions: KeyValue<MatchingOption>,
  dragAndDropOptions: KeyValue<DragAndDropOption>
}

export interface QuizGeneralState {
  title: string;
  description: string;
  image: string;
  imageUpload: boolean;
  imageFileName: string;
  successRequirement: boolean;
  timeLimit: number;
  randomQuestionOrder: boolean;
  maxTries: number;
  blockTime: number;
  privateFeedback: boolean;
}

export interface QuizManageState {
  quiz: EntityId;
  questions: EntityId[];
  currentQuestion: EntityId;
  entities: Entities;
  mode: 'standalone' | 'course';
  isNew: boolean;
  general: QuizGeneralState;
  urls: {
    imagePlaceholder: string;
    createUrl: string;
    updateUrl?: string;
  },
  questionEditors: { [id: number]: EntityId }
}

export const state: QuizManageState = {
  quiz: null,
  questions: [],
  currentQuestion: null,
  isNew: true,
  mode: null,
  entities: {
    questions: {},
    answers: {},
    matchingAnswers: {},
    matchingOptions: {},
    dragAndDropOptions: {}
  },
  general: {
    title: null,
    description: null,
    image: null,
    imageUpload: false,
    imageFileName: null,
    successRequirement: null,
    timeLimit: null,
    randomQuestionOrder: null,
    maxTries: null,
    blockTime: null,
    privateFeedback: null
  },
  urls: {
    imagePlaceholder: null,
    createUrl: null,
    updateUrl: null
  },
  questionEditors: {}
}
