import { MediaLibrary } from './media_library'; 
import { SelectionMode } from './interfaces';

export function init() {
  this.library = new MediaLibrary();
  listenLocationHashCange();
  processLocationHash();
}

function listenLocationHashCange() {
  window.addEventListener('hashchange', (e) => {
    processLocationHash();
  });
}

function processLocationHash() {
  if (window.location.hash === '#private_takes') {
    VERSTEHE.MediaLibrary.library.openLibrary(SelectionMode.Multiple, 'privateTakes');
  }
  if (window.location.hash === '#public_takes') {
    VERSTEHE.MediaLibrary.library.openLibrary(SelectionMode.Multiple, 'publicTakes');
  }
  if (window.location.hash === '#documents') {
    VERSTEHE.MediaLibrary.library.openLibrary(SelectionMode.Multiple, 'documents');
  }
}
