import { GetterTree } from "vuex";
import { QuizManageState } from "./state";
import { RootState } from "../../../store/types";
import { Answer, Question } from "../entities";
import { AnswerType } from "../entities/answer";
import { QuestionType } from "../entities/question";

export const Getters = {
  QUESTION_IDS: 'quizManage/questionIds',
  QUESTION: 'quizManage/question',
  QUESTIONS: 'quizManage/questions',
  CURRENT_QUESTION: 'quizManage/currentQuestion',
  CURRENT_QUESTION_ID: 'quizManage/currentQuestionId',
  ANSWER: 'quizManage/answer',
  ANSWER_IDS: 'quizManage/answerIds',
  ANSWERS: 'quizManage/answers',
  SINGLE_CHOICE_SOLUTION: 'quizManage/singleChoiceSolution',
  TOTAL_POINTS: 'quizManage/totalPoints',
  QUIZ_INVALID: 'quizManage/quizInvalid',
  GENERAL_FIELDS: 'quizManage/generalFields',
  URLS: 'quizManage/urls',
  MATCHING_OPTION: 'quizManage/matchingOption',
  QUESTION_EDITORS: 'quizManage/questionEditors',
  DRAG_AND_DROP_OPTIONS: 'quizManage/dragAndDropOptions',
  DRAG_AND_DROP_OPTION: 'quizManage/dragAndDropOption',
}

export const getters: GetterTree<QuizManageState, RootState> = {
  questionIds: (state) => state.questions.filter(id => !state.entities.questions[id].deleted),
  questions: (state, getters) => getters.questionIds.map(id => state.entities.questions[id]),
  question: (state) => (id: EntityId) => state.entities.questions[id],
  currentQuestionId: (state) => state.currentQuestion,
  currentQuestion: (state) => state.currentQuestion ? state.entities.questions[state.currentQuestion] : null,
  answer: (state) => (id: EntityId, schema = 'answers') => state.entities[schema][id],
  answerIds: (state) => (questionId: EntityId) => state.entities.questions[questionId].answers.filter(answerObject => {
    return state.entities[answerObject.schema][answerObject.id].deleted === false;
  }).map((answerObject) => answerObject.id),
  answers: (state) => (questionId: EntityId) => state.entities.questions[questionId].answers
    .map(answerObject => state.entities[answerObject.schema][answerObject.id])
    .filter(answer => !answer.deleted),
  singleChoiceSolution: (state, getters) => (questionId: EntityId) => {
    const answer = getters.answers(questionId).find(answer => answer.isSolution === true)
    return answer ? answer.id : null;
  },
  totalPoints: (state, getters) => {
      // Sum up all points from answers and single choice questions
      const questions = state.questions
        .map((questionId) => state.entities.questions[questionId])
        .filter((question: Question) => !question.deleted);

      if (questions.length > 0) {
        return questions.map(question => {
          if (question.type === QuestionType.single) {
            return question.points;
          } else if (question.type === QuestionType.drag_and_drop) {
            return getters.dragAndDropPoints(question.id);
          } else {
            const points = question.answers
              .map((answerObject) => state.entities[answerObject.schema][answerObject.id])
              .filter((answer: Answer) => !answer.deleted)
              .map(answer => answer.points)
              .reduce((acc, curr) => { return acc + curr }, 0);
            return points;
          }
        })
        .reduce((acc, curr) => { return acc + curr }, 0);
      } else {
        return 0;
      }
  },
  dragAndDropPoints: ({ entities: { questions, answers, dragAndDropOptions }}) => (questionId: EntityId) => {
    const answer = questions[questionId].answers.map(answer => answers[answer.id])[0];
    const options = Object.values(dragAndDropOptions).filter(option => answer.dragAndDropOptions.indexOf(option.id) >= 0);
    return options.map(option => option.points).reduce((acc, curr) => acc + curr, 0);
  },
  quizInvalid: (state, getters) => {
    return getters.questionIds.length === 0 || getters.questions.some((question: Question) => !question.valid);
  },
  generalFields: (state) => state.general,
  urls: (state) => state.urls,
  matchingOption: (state) => (id: EntityId) => state.entities.matchingOptions[id],
  questionEditors: (state) => (id: EntityId) => {
    return Object.keys(state.questionEditors).filter(key => state.questionEditors[key] === id).map(editorId => parseInt(editorId, 10));
  },
  dragAndDropOptions: (state) => (answerId: EntityId) => state.entities.answers[answerId].dragAndDropOptions.map(id => state.entities.dragAndDropOptions[id]),
  dragAndDropOption: (state) => (optionId: EntityId) => state.entities.dragAndDropOptions[optionId],
};
