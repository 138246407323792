import Vue from 'vue';
import LibraryApp from './components/library_app.vue';
import { LibraryModule } from './store';
import store from '../store';
import { Actions } from './store/actions';
import RecordingService from '../recording/recorder';
import { SelectionMode } from './interfaces';
import { Getters } from './store/getters';
import { VideoUploader } from '../uppy_uploader';
import axios from 'axios';
import UploaderService from '../uppy_uploader/uploader_service';
import EventEmitter from '../common/event_emitter';
import { download } from '../capture/service_worker_controller';
import BackupStore from '../capture/backup_store';
import { flashMessage } from '../mixins/flash_message';

export class MediaLibrary {
  private libraryWrapper: HTMLDivElement;
  public libraryApp: Vue;
  public eventBus: EventEmitter;
  private recorder: any;
  private videoUploader: VideoUploader;

  constructor() {
    // EventBus for external Components so that events can be subscribed if the library has not yet been initialized
    this.eventBus = new EventEmitter();
    this.initializeWrapper();
    store.registerModule('library', LibraryModule);
    this.initLocationHash();
  }

  private initLocationHash() {
    if(window.location.hash === '#library') {
      this.openLibrary(SelectionMode.Multiple, 'privateTakes');

      if(VERSTEHE.ManageSidebar.component.panelBar) {
        VERSTEHE.ManageSidebar.component.expand('#sidebar-media');
      }
    }
  }

  private initializeWrapper() {
    this.libraryWrapper = document.createElement('div');
    this.libraryWrapper.classList.add('media-library-wrapper');
    const vueContainer = document.createElement('div');
    vueContainer.id = "v-media-library";
    this.libraryWrapper.appendChild(vueContainer);
    document.body.appendChild(this.libraryWrapper);
  }

  private initLibraryApp() {
    const baseElement = document.getElementById('library-url');
    const baseUrl = baseElement.getAttribute('data-baseurl');
    const brandUrl = baseElement.getAttribute('data-brand');
    const uploadUrl = baseElement.getAttribute('data-uploadurl');
    const isAuthor = baseElement.getAttribute('data-author') === 'true';
    const settings = JSON.parse(baseElement.getAttribute('data-settings'));
    store.dispatch(Actions.SET_BASE_STATE, { apiUrl: baseUrl, brandUrl: brandUrl, uploadUrl: uploadUrl, settings: settings });


    this.videoUploader = UploaderService.videoUploaderInstance;
    this.bindUploaderListeners();

    this.libraryApp = new Vue ({
      name: 'MediaLibrary',
      el: '#v-media-library',
      store: store,
      i18n: VERSTEHE.vueI18n,
      provide: {
        isAuthor: isAuthor,
        recorder: this.recorder,
        videoUploader: this.videoUploader
      },
      render: h => h(LibraryApp)
    });

    if (_app.info.user.roles.indexOf('master_editor') !== -1) {
      store.dispatch(Actions.FETCH_REQUESTED_TAKES);
    }

    this.libraryApp.$on('close-library', () => this.onClose());

    this.uploadCaptureRecording();
  }

  public openLibrary(
    selectionMode: SelectionMode = SelectionMode.Multiple,
    tab = 'privateTakes',
    mediaTypes = ['Take', 'Document', 'Clip', 'Image'],
    previewElement: { type: string, id: number } = null
  ) {
    if (this.libraryApp && previewElement) {
      this.refreshTakes();
    }

    if (!this.libraryApp) {
      this.recorder = RecordingService.recorderInstance;
      this.initRecorderEvents();
      this.initLibraryApp();
    }

    if (previewElement && previewElement.type === 'take') {
      this.libraryApp.$root.$once('privateTakes-loaded', () => {
        store.dispatch(Actions.INSPECT_TAKE_BY_ID, previewElement.id);
        store.dispatch(Actions.SET_SELECTED_TAKES, [...store.getters[Getters.SELECTED_TAKES], previewElement.id]);
      });
    }

    store.dispatch(Actions.SET_MEDIA_TYPES, mediaTypes);
    store.dispatch(Actions.SET_SELECTION_MODE, selectionMode);

    if(tab) {
      store.dispatch(Actions.ACTIVATE_TAB, tab);
    }

    document.body.classList.add('media-library-visible');
  }

  public closeLibrary() {
    this.libraryApp.$emit('close-library');
  }

  private onClose() {
    if (store.getters[Getters.SELECTION_MODE] === SelectionMode.None) {
      window.location.hash = '';
    }
    document.body.classList.remove('media-library-visible');
  }

  public refreshTakes() {
    store.dispatch(Actions.RESET_MEDIA, 'privateTakes');
  }

  public async uploadCaptureRecording(): Promise<void> {
    const urlParams = new URLSearchParams(window.location.search);
    const recordingId = urlParams.get('recording');

    if (!recordingId) {
      return;
    }

    const recording = await download(recordingId);

    if (!recording) {
      console.log(`[MEDIA LIBRARY] No recording could be found for recordingId "${recordingId}"`);
      return;
    }

    if (recording.backupId) {
      await BackupStore.init();
      await BackupStore.loadBackupById(recording.backupId);
    }

    const backupFileName = await BackupStore.activeBackup?.getUploadFileName();

    const uploadFile = {
      name: backupFileName || `capture_${Date.now()}.webm`,
      type: recording.type,
      data: recording.data,
    };

    if (BackupStore.activeBackup) {
      if (!backupFileName) {
        await BackupStore.activeBackup.setUploadFileName(uploadFile.name);
      }

      const onUploadSuccess = (file) => {
        if (uploadFile.name === file.name) {
          this.videoUploader.uppy.off('upload-success', onUploadSuccess);
          this.videoUploader.uppy.off('file-removed', onUploadSuccess);

          BackupStore.activeBackup.destroy();
        }
      };

      this.videoUploader.uppy.on('upload-success', onUploadSuccess);
      this.videoUploader.uppy.on('file-removed', onUploadSuccess);
    }

    this.videoUploader.uppy.addFile(uploadFile);
  }

  /*
  * Recorder Events
  */
  private initRecorderEvents() {
    this.recorder.on('refresh-amor', this.onRefreshAmor);
    this.recorder.on('convert-status', this.onConvertStatus);
    this.recorder.on('upload-status', this.onUploadStatus);
    this.recorder.on('upload-finished', this.onUploadFinished);
    this.recorder.on('slide-audio-record-success', this.onSlideAudioRecorderSuccess);
    this.recorder.on('split-amor-success', this.onSplitAmorSuccess);
  }

  private onSplitAmorSuccess(message) {
    store.dispatch(Actions.ADD_TAKE, message.take.id);
  }

  private onRefreshAmor(message) {
    const payload =  {
      id: message.take.id,
      thumbnail_url: message.amor.thumbnail_url,
      duration: message.take.duration,
      height: message.amor.height,
      width: message.amor.width,
      state: message.amor.state,
      source: message.amor.source
    }

    if (message.asset?.state) {
      payload['asset_state'] = message.asset.state;
    }

    store.dispatch(Actions.REFRESH_AMOR_ACTION, payload);
  }

  private onConvertStatus(amor_id, progress) {
    const payload = {
      amor_id: amor_id,
      values: {
        uploading: false,
        converting: true,
        convert_progress: progress
      }
    }
    store.dispatch(Actions.UPDATE_TAKE, payload);
  }

  private onUploadStatus(amor_id, progress) {
    const payload = {
      amor_id: amor_id,
      values: {
        uploading: true,
        converting: false,
        upload_progress: progress
      }
    }
    store.dispatch(Actions.UPDATE_TAKE, payload);
  }

  private onUploadFinished(amor_id) {
    const payload = {
      amor_id: amor_id,
      values: {
        uploading: false,
        converting: false,
        state: 'uploaded'
      }
    }
    store.dispatch(Actions.UPDATE_TAKE, payload);
  }

  private onSlideAudioRecorderSuccess(result) {
    store.dispatch(Actions.RESET_MEDIA, 'privateTakes');
  }

  public subscribeProductionJob(jobId: number) {
    const identifier = { channel: 'ProductionChannel', job_id: jobId };
    const idString = JSON.stringify(identifier);
    const alreadySubscribed = VERSTEHE.cable.subscriptions.subscriptions.some((s) => s.identifier === idString);

    if(!alreadySubscribed) {
      VERSTEHE.cable.subscriptions.create({
        channel: 'ProductionChannel',
        job_id: jobId
      },
      {
        received: function(data) {
          if (data.finished === true) {
            switch(data.medium_type) {
              case 'Upload::Audio':
              case 'Upload::BigBlueButton':
              case 'Upload::Video':
                store.dispatch(Actions.REFRESH_TAKE, data.take_id);
                break;
              case 'Media::Clip':
                store.dispatch(Actions.REFRESH_CLIP, data.medium_id);
                break;
              default:
                if (data.slides && data.slides.length > 0) {
                  store.dispatch(Actions.UPDATE_DOCUMENT_PROGRESS, {
                    document_id: data.slides[0].slideshow_id,
                    state: data.state,
                    page_quantity: data.page_quantity,
                    slides: data.slides.map((slide) => {
                      return {
                        id: slide.id,
                        page: slide.page_num,
                        src: slide.images.original,
                        thumbnail_url: slide.images.small
                      }
                    })
                  })
                } else {
                  store.dispatch(Actions.UPDATE_DOCUMENT, { id: data.medium_id, values: { state: data.state }});
                }
                break;
            }
          }
        }
      });
    }
  }

  private bindUploaderListeners() {
    const url = store.getters[Getters.BASE_URL];
    this.videoUploader.uppy.on('complete', (result) => {
      if(result.failed.length > 0){
        flashMessage(VERSTEHE.vueI18n.t("media_library.upload_failed"));
      }
    });
    this.videoUploader.uppy.on('upload-success', (file, response) => {
      const identifier = response.uploadURL.substring(response.uploadURL.lastIndexOf('/') + 1);
      axios.get(`${url}/take_by_upload?identifier=${identifier}`).then((response) => {
        this.videoUploader.uppy.removeFile(file.id);
        store.dispatch(Actions.ADD_UPLOADED_TAKE, response.data);
        this.subscribeProductionJob(response.data.progress_params.job_id);
      });
    });
  }
}
