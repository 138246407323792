import { schema } from 'normalizr';
import { v4 as uuid } from 'uuid';
import { matchingOptionSchema } from './matching_option';
import { dragAndDropOptionSchema } from './drag_and_drop_option';

export enum AnswerType {
  single = 'Quiz::Answer::SingleChoice',
  statement = 'Quiz::Answer::Statement',
  numerical = 'Quiz::Answer::Numerical',
  imagemap = 'Quiz::Answer::Imagemap',
  open = 'Quiz::Answer::Open',
  arrangement = 'Quiz::Answer::Arrangement',
  matching = 'Quiz::Answer::Matching',
  drag_and_drop = 'Quiz::Answer::DragAndDrop'
}

export class Answer {
  public id?: EntityId = null;
  public image?: string = null;
  public imageFileName?: string = null;
  public imageUpload?: Boolean = false;
  public type: AnswerType = null;
  public points: number = 0;
  public isSolution?: boolean = null;
  public solution?: number = null;
  public text?: string = null;
  public questionId: EntityId = null;
  public createdAt?: string = null;
  public updatedAt?: string = null;
  public deleted?: boolean = false;
  public matchingOption?: EntityId;
  public dragAndDropOptions?: Array<EntityId> = [];

  constructor(attributes: Answer = null) {
    this.id = uuid();
    if (attributes) {
      Object.assign(this, attributes);
    }
  }
}

export const answerSchema = new schema.Entity('answers', {
  matchingOption: matchingOptionSchema,
  dragAndDropOptions: [dragAndDropOptionSchema]
}, {
  processStrategy: (entity) => Object.assign(entity, { imageUpload: null, deleted: false })
});
