import type { Store } from 'vuex';
import { Mutations } from './mutations';
import ImageStore from '../image_store';
import type { CustomBackgroundImage, State } from './state';

export default (store: Store<State>): void => {
  store.subscribe((mutation, state) => {
    if (mutation.type === Mutations.ADD_CUSTOM_BACKGROUND_IMAGE) {
      const { id, image, thumbnail } = mutation.payload as CustomBackgroundImage;
      ImageStore.add(id, image, thumbnail);
    } else if (mutation.type === Mutations.REMOVE_CUSTOM_BACKGROUND_IMAGE) {
      ImageStore.remove(mutation.payload as CustomBackgroundImage['id']);
    }
  });
};
