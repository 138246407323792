import ChatMessage from './chat_message';

/** Class representing a chat room */
export class ChatRoom {
  /**
   * @constructor
   * @param {string} roomId  - Unique identifier for chat room
   * @param {number} [owner] - UserId of the chat room owner
   * @param {object} [options]
   */
  constructor(roomId, owner = null, options = {}) {
    const defaultOpts = {
      colors: [
        '#E91E63', '#9C27B0', '#673AB7', '#3F51B5',
        '#2196F3', '#03A9F4', '#00BCD4', '#00BCD4',
        '#009688', '#4CAF50', '#8BC34A', '#CDDC39',
        '#FFEB3B', '#FFC107', '#FF9800', '#FF5722',
        '#795548', '#9E9E9E', '#607D8B', '#000000'
      ],
      ownerColor: '#F44336'
    };
    this.roomId = roomId;
    this.owner = owner;
    this.options = Object.assign({}, defaultOpts, options);
    this._messages = [];
    this._participants = [];
    this.channel = null;
    this.convertEmoticons = true;

    // Find template for chat message rendering
    let wrapperQuery = `.chat[data-chatroom="${roomId}"]`;
    this.wrapper = document.querySelector(wrapperQuery);

    if (!this.wrapper) {
      throw new Error('Can not find chat with chatroom "' + roomId + '" within DOM.');
    }
    Handlebars.registerHelper('escapeChatChars', function(text) {
      text = Handlebars.Utils.escapeExpression(text);
      text = text.replace(/(\r\n|\n|\r)/gm, '<br>');
      text = text.replace(/&amp;/g, '&');
      return new Handlebars.SafeString(text);
    });

    this.messageTemplate = Handlebars.compile(this.wrapper.querySelector('.chat-message-template').value);
    this.messagesWrapper = document.querySelector('.chat-messages');

    // Setup submit listener for chat message
    this.wrapper.querySelector('.chat-post-submit').addEventListener('click', () => {
      this.sendMessage();
    });

    this.wrapper.querySelector('.chat-post-input').addEventListener('keydown', (e) => {
      let input = this.wrapper.querySelector('.chat-post-input');
      if (e.keyCode === 13) {
        e.preventDefault();
        if (e.shiftKey) {
          input.value = input.value + "\n";
        } else {
          this.sendMessage();
        }
      }
    });

    this.wrapper.querySelector('.emoticon-toggle').addEventListener('click', (e) => {
      e.target.classList.toggle('active');
      this.convertEmoticons = e.target.classList.contains('active');
    });
  }

  /**
   * Set the chat messages
   * @param {ChatMessage[]} messages - The chat messages
   */
  set messages(messages) {
    this._messages = messages;
  }

  /**
   * Get the chat messages
   * @returns {Map} Grouped chat messages by date
   */
  get messages() {
    return this._messages.reduce((map, message) => {
      let day = message._time.format('LL');
      if (!map.get(day)) {
        map.set(day, []);
      }

      map.get(day).push(message);
      return map;
    }, new Map());
  }

  /**
   * Set the chat participants
   * @param {Array} participants - The chat participants
   */
  set participants(participants) {
    this._participants = participants;
  }

  /**
   * Get the chat participants
   * @returns {Array} Chat participants
   */
  get participants() {
    return this._participants;
  }

  /**
   * Send a new chat message on its way
   */
  sendMessage() {
    if (this.channel) {
      let input = this.wrapper.querySelector('.chat-post-input');
      if (!(input.value.trim() === "")) {
        this.channel.sendMessage(input.value.substring(0, 1000));
        input.value = "";
        var event = new CustomEvent('input');
        input.dispatchEvent(event);
      }
    } else {
      throw new Error('Can not send message, since ActionCable is not connected.');
    }
  }

  /**
   * Build a new ChatMessage instance
   * @param {object} data - serialized chat message from server (JSON)
   * @returns {ChatMessage} the constructed ChatMessage
   */
  buildChatMessage(data) {
    // highlight chat participants
    // chat owners always have a fixed fixed color
    let color = data.user.id === this.owner ? this.options.ownerColor : this.options.colors[data.user.id % 20];
    return new VERSTEHE.common.ChatMessage(data.user, data.body, data.time, data.user.id === this.owner, color, data.convert_emoticons);
  }

  /**
   * Connects with the ActionCable channel
   * @returns {Subscription} The ActionCable subscription
   */
  connect() {
    this.channel = VERSTEHE.cable.subscriptions.create({
      channel: 'ChatChannel',
      room: this.roomId
    }, {
      received: (payload) => {
        switch (payload['type']) {
          case 'init_chat':
            // first message we from ActionCable channel will feed the chat
            // with existing messages
            let messages = [];

            for (let i = 0; i < payload['data']['messages'].length; i++) {
              let data = payload['data']['messages'][i];
              let msg = this.buildChatMessage(data);
              messages.push(msg);
            }

            this.messages = messages;
            this.participants = payload['data']['participants'];
            this.participantChange(this.participants);
            this.renderMessages();

            break;

          case 'message':
            // we got a new message from another participant
            let data = payload['data'];
            let msg = this.buildChatMessage(data);
            let messageEvent = new CustomEvent('chat:message', { message: msg });
            this.wrapper.dispatchEvent(messageEvent);
            this.appendMessage(msg);
            break;

          case 'participant_change':
            this.participants = payload['data'];
            // dispatch event on DOM node
            this.participantChange(this.participants);
            break;
        }

        // we scroll to the bottom when we receive new message/s
        this.messagesWrapper.scrollTop = this.messagesWrapper.scrollHeight;
      },
      sendMessage: (message) => {
        // create a new ChatMessage server side and broadcast to other chat participants
        this.channel.perform('send_message', { data: { message: message, convert_emoticons: this.convertEmoticons } });
      }
    });

    return this.channel;
  }

  /**
   * Call participant_change event, render active participants
   */
  participantChange(participants) {
    let event = new CustomEvent('chat:participant_change', { participants });
    this.wrapper.dispatchEvent(event);
  }

  /**
   * Returns the number of online participants
   * @returns {number} count
   */
  onlineUserCount() {
    return this.participants.filter(p => p.online).length;
  }

  /**
   * Returns the total count of participants (all time)
   * @returns {number} count
   */
  totalUserCount() {
    return this.participants.length;
  }

  /**
   * Clear all chat messages
   */
  clearMessages() {
    this._messages = [];
    this.messagesWrapper.innerHTML = '';
  }

  /**
   * Append a new chat message
   * @param {ChatMessage} message - The chat message to append
   */
  appendMessage(message) {
    this._messages.push(message);

    let date = message._time.format('LL');
    let messageHtml = this.messageTemplate(message);
    let msgGroup = this.messagesWrapper.querySelector(`.chat-message-group[data-date="${date}"]`);

    if (!msgGroup) {
      msgGroup = this.buildChatMessageGroup(date);
      this.messagesWrapper.appendChild(msgGroup);
    }

    // insert message to DOM
    msgGroup.insertAdjacentHTML('beforeend', messageHtml);
  }

  /**
   * Returns an empty chat message group node
   * @param {string} date - Localized date
   * @returns {Node} DOM Node of message group
   */
  buildChatMessageGroup(date) {
    let messageGroup = document.createElement('div');
    messageGroup.className = 'chat-message-group';
    messageGroup.setAttribute('data-date', date);

    let messageGroupDate = document.createElement('div');
    messageGroupDate.className = 'chat-message-group-date';
    messageGroupDate.innerHTML = date;

    messageGroup.appendChild(messageGroupDate);
    return messageGroup;
  }

  /**
   * Render current chat messages into DOM
   */
  renderMessages() {
    this.messagesWrapper.innerHTML = '';

    this.messages.forEach((messages, date) => {
      let msgGroup = this.buildChatMessageGroup(date);

      messages.forEach((message) => {
        let messageHtml = this.messageTemplate(message);
        msgGroup.insertAdjacentHTML('beforeend', messageHtml);
      });

      this.messagesWrapper.appendChild(msgGroup);
    });
  }
}
