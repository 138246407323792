import { UAParser } from 'ua-parser-js';

export default function isBrowserSupported(): boolean {
  const ua = UAParser(navigator.userAgent);

  const { name } = ua.browser;
  // @ts-expect-error -> parseInt type doesn't allows undefined
  const major = parseInt(ua.browser.major, 10);

  return (
    (name === 'Chrome' && major >= 85)
    || (name === 'Edge' && major >= 85)
    || (name === 'Firefox' && major >= 80)
  );
}
