import { openDB } from 'idb';
import type { IDBPDatabase } from 'idb';
import store from './store';
import type ImageStoreDB from './types/image_store_db';

export default class ImageStore {
  private static DATABASE_NAME = 'CooviCaptureImage';
  private static DATABASE_VERSION = 1;

  private static db: IDBPDatabase<ImageStoreDB>;

  public static async init(): Promise<void> {
    ImageStore.db = await openDB<ImageStoreDB>(ImageStore.DATABASE_NAME, ImageStore.DATABASE_VERSION, {
      blocked() {
        // implement notification and/or other actions for blocked state if database goes to version 2 (see backup_store.ts)
      },
      async upgrade(db, oldVersion, newVersion, transaction) {
        if (oldVersion < 1) {
          const imagesStore = db.createObjectStore('images', {
            keyPath: 'id',
          });

          imagesStore.createIndex('byUserId', 'userId');
        }
      },
    });
  }

  public static async getAll(): Promise<ImageStoreDB['images']['value'][]> {
    return ImageStore.db.getAllFromIndex('images', 'byUserId', store.state.appData!.userId);
  }

  public static async add(id: string, image: Blob, thumbnail: Blob): Promise<void> {
    await ImageStore.db.add('images', {
      id,
      userId: store.state.appData!.userId,
      image,
      thumbnail,
      timestamp: Date.now(),
    });
  }

  public static async remove(id: string): Promise<void> {
    await ImageStore.db.delete('images', id);
  }
}