<template>
  <div>
    <workflow-navigation/>
    <slide-x-right-transition mode="out-in" :duration="200">
      <upload v-if="step === 'upload'"/>
      <settings v-if="step === 'settings'"/>
      <mappings v-if="step === 'mappings'"/>
      <import-progress v-if="step === 'importing'"/>
    </slide-x-right-transition>
  </div>
</template>

<script>
import { SlideXRightTransition } from 'vue2-transitions';
import WorkflowNavigation from './workflow_navigation.vue';
import Upload from './upload.vue';
import Settings from './settings.vue';
import Mappings from './mappings.vue';
import ImportProgress from './import_progress';
import { mapGetters, mapActions } from 'vuex';
import { Actions } from '../store';

export default {
  components: {
    SlideXRightTransition,
    WorkflowNavigation,
    Upload,
    Settings,
    Mappings,
    ImportProgress
  },
  computed: {
    ...mapGetters({
      step: 'userImport/step'
    })
  },
  methods: {
    ...mapActions({
      uploadFile: Actions.UPLOAD_FILE
    })
  }
}
</script>
