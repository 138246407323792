import { GetterTree } from "vuex";
import { WebConferenceState } from "./state";
import { RootState } from "../../store/types";
import { Participant } from "livekit-client";

export const Getters = {
    ROOM: 'webConference/room',
    PARTICIPANTS: 'webConference/participants', 
    LOCAL_PARTICIPANT: 'webConference/localParticipant',
    CHAT_MESSAGES: 'webConference/chatMessages',
    ROOM_NAME: 'webConference/roomName',
    SHARED_SCREEN_PARTICIPANT: 'webConference/sharedScreenParticipant',
    LOCAL_VIDEO_TRACK: 'webConference/localVideoTrack',
    LOCAL_AUDIO_TRACK: 'webConference/localAudioTrack',
    RAISE_HAND_NOTIFICATIONS: 'webConference/raiseHandNotifications',
    NOTIFICATIONS: 'webConference/notification',
    USER_DETAILS: 'webConference/userDetails',
    DETAILS: 'webConference/details',
    SETTINGS: 'webConference/settings',
    IS_GUEST: 'webConference/isGuest',
    STARTING_TIME: 'webConference/startingTime',
    IS_RECORDING: 'webConference/isRecording',
    MODALS: 'webConference/modals',
    SETTINGS_OPENED: 'webConference/settingsOpened',
    CONFERENCE_LAYOUT: 'webConference/conferenceLayout',
    SELECTED_AUDIO: 'webConference/selectedAudio',
    SELECTED_MIC: 'webConference/selectedMic',
    MIC_ACTIVATED: 'webConference/micActivated',
    SELECTED_CAM: 'webConference/selectedCam',
    CAM_ACTIVATED: 'webConference/camActivated',
    SHOW_CAMERA_ERROR: 'webConference/showCameraError',
    BREAKOUT_STARTED: 'webConference/breakoutStarted',
    IS_BREAKOUT: 'webConference/isBreakout',
    LIVEKIT_URL: 'webConference/livekitUrl',
    ON_JOIN_PAGE: 'webConference/onJoinPage',
    

};

export const getters: GetterTree<WebConferenceState, RootState> = {
    room: (state) => state.room, 
    participants: (state): Participant[] => state.participants,
    localParticipant: (state) => state.localParticipant,
    chatMessages: (state) => state.chatMessages,
    roomName: (state) => state.roomName,
    sharedScreenParticipant: (state) => state.sharedScreenParticipant,
    localVideoTrack: (state) => state.localVideoTrack,
    localAudioTrack: (state) => state.localAudioTrack,
    raiseHandNotifications: (state) => state.raiseHandNotifications,
    notifications: (state) => state.notifications,
    userDetails: (state) => state.userDetails,
    details: (state) => state.details,
    settings: (state) => state.settings, 
    isGuest: (state) => state.isGuest,
    startingTime: (state) => state.startingTime,
    isRecording: (state) => state.isRecording,
    modals: (state) => state.modals,
    settingsOpened: (state) => state.settingsOpened,
    conferenceLayout: (state) => state.userSettings.conferenceLayout,
    selectedAudio: (state) => state.userSettings.selectedAudio,
    selectedMic: (state) => state.userSettings.selectedMic,
    micActivated: (state) => state.micActivated,
    selectedCam: (state) => state.userSettings.selectedCam,
    camActivated: (state) => state.camActivated,
    showCameraError: (state) => state.showCameraError,
    breakoutStarted: (state) => state.breakoutStarted,
    isBreakout: (state) => state.isBreakout,
    livekitUrl: (state) => state.livekitUrl,
    onJoinPage: (state) => state.onJoinPage,
};
