import { t } from './localization';
import { simpleContainsFilterUi, datepickerFilterUi, dateColumnTemplate } from '../../grid/configurations/base_column_configuration';

function userAvatarTemplate(dataItem) {
  let thumbnailUrl = dataItem.thumbnail;
  thumbnailUrl = thumbnailUrl.replace(/.*\/resources/, `/${_app.info.site}/resources`);

  return `<a href="${dataItem.actions.title_action}" class="grid-thumbnail-container" >` +
    `<div class="grid-thumbnail-image-wrapper rounded">` +
      `<div class="grid-thumbnail-image" style="background-image: url('${thumbnailUrl}');"></div>` +
    `</div>` +
  `</a>`;
}

export let columnConfiguration = [
  {
    attributes: {
      'class': 'grid-checkbox'
    },
    selectable: true,
    hideable: false,
    hidden: false,
    width: 50
  },
  {
    field: 'thumbnail',
    title: ' ',
    sortable: false,
    filterable: false,
    hideable: false,
    attributes: {
      'class': 'grid-thumbnail'
    },
    template: userAvatarTemplate
  },
  {
    field: 'has_access',
    title: t('has_access'),
    width: 130,
    sortable: false,
    attributes: {
      'class': 'grid-has-access'
    },
    hideable: false,
    filterable: {
      onlyPreset: true
    },
    template:
      `# if(has_access === 'true') {#` +
          `<div title="${t('user_has_access_tooltip')}"><span class="material-icons">vpn_key</span> ${t('user_has_access')}</div>` +
      `# } else { #` +
          `<div title="${t('user_has_no_access_tooltip')}">${t('user_has_no_access')}</div>` +
      `# } #`
  },
  {
    field: 'any_name',
    title: t('username'),
    sortable: true,
    attributes: {
      'class': 'grid-title'
    },
    template:
      `<a href="#: actions.title_action #">` +
      `# if(!confirmed_at) {# <span class="unconfirmed-icon material-icons" data-toggle="tooltip" title="${t('not_confirmed_yet')}">warning</span> #} #` +
      `#: name #` +
      `</a>`,
    filterable: {
      operators: {
        string: {
          contains: t('contains')
        }
      },
      ui: simpleContainsFilterUi
    },
    hideable: false
  },
  {
    field: 'role',
    title: t('role'),
    sortable: true,
    attributes: {
      'class': 'grid-role'
    },
    template:
      `#: role #`,
    filterable: {
      operators: {
        string: {
          contains: t('contains')
        }
      },
      messages: {
        value: ''
      },
      ui: uiRoleFilter
    },
    hideable: false,
    media: '(min-width: 800px)'
  },
  {
    field: 'created_at',
    title: t('created_at'),
    sortable: true,
    width: 250,
    attributes: {
      'class': 'grid-updated'
    },
    format: '{0:D}',
    filterable: false,
    hidden: false,
    hideable: false,
    template: dateColumnTemplate('created_at'),
    media: '(min-width: 1200px)'
  },
  {
    field: 'is_deleted',
    title: t('is_deleted'),
    labelText: t('filter_user_is_deleted'),
    hidden: true,
    filterable: {
      onlyPreset: true
    },
    attributes: {
      'class': 'grid-is-deleted'
    }
  },
  {
    field: 'confirmed_at',
    labelText: t('filter_unconfirmed_users'),
    hidden: true,
    filterable: {
      onlyPreset: true
    }
  },
  {
    field: 'actions',
    title: ' ',
    attributes: {
      'class': 'grid-actions'
    },
    sortable: false,
    filterable: false,
    hideable: false,
    template:
      `# if (can.edit) {# <a class="grid-action-edit" href="#: actions.edit #"><i class="material-icons">edit</i> ${t('action_edit')}</a> #}#` +
      `# if (can.acl && actions.grant_access && has_access === 'false') {# <a class="grid-action-grant-access" title="${t('action_grant_access_tooltip')}" href="#: actions.grant_access #" data-remote="true" data-method="patch"><i class="material-icons">vpn_key</i> ${t('action_grant_access')}</a> #}#` +
      `# if (can.acl && actions.revoke_access && has_access === 'true') {# <a class="grid-action-revoke-access" title="${t('action_revoke_access_tooltip')}" href="#: actions.revoke_access #" data-remote="true" data-method="patch"><i class="material-icons">vpn_key</i> ${t('action_revoke_access')}</a> #}#` +
      `# if (can.confirm && !confirmed_at) {# <a class="grid-action-confirm" href="#: actions.confirm #" data-remote="true" data-method="patch"><i class="material-icons">check</i> ${t('action_confirm')}</a> #}#` +
      `# if (can.transit_content && confirmed_at) {# <a href="" data-url="#: actions.transit_content#" data-remote="true" class="grid-action-transit-content"><i class="material-icons">transfer_within_a_station</i>${t('action_transit_content')}</a> #} #` +
      `# if (can.edit_editors) {# <a href="#: actions.assign_role#" class="grid-action-assign-role" data-remote="true"><i class="material-icons">label</i>${t('action_assign_role')}</a> #} #` +
      `# if (can.delete && !is_deleted) {#
        <a class="grid-action-delete" href="#: actions.delete #" title="${t('action_delete_tooltip')}" data-method="delete"><i class="material-icons">delete</i> ${t('action_delete')}</a>
        <a class="grid-action-restore hidden" href="#: actions.restore #" data-method="post"><i class="material-icons">history</i> ${t('action_restore')}</a>
      #}#` +
      `# if (can.delete && is_deleted && can.restore) {#
        <a class="grid-action-delete hidden" href="#: actions.delete #" data-method="delete"><i class="material-icons">delete</i> ${t('action_delete')}</a>
        <a class="grid-action-restore" href="#: actions.restore #" data-method="post"><i class="material-icons">history</i> ${t('action_restore')}</a>
      #}#` +
      `# if (can.delete && is_deleted && !can.restore) {#
        <a class="grid-action-delete hidden" href="#: actions.delete #" data-method="delete"><i class="material-icons">delete</i> ${t('action_delete')}</a>
        <span class="disabled-wrapper" title="${t('user_limit_reached')}"><a class="grid-action-restore disabled" href="" data-method="post"><i class="material-icons">history</i> ${t('action_restore')}</a></span>
      #}#` +
      `# if (is_deleted) {#
        <a href="#: actions.delete_permanent#" class="grid-action-delete_permanent" data-remote="true"><i class="material-icons">delete</i>${t('action_delete_permanent')}</a>
      #}#`
  }
];

function uiRoleFilter (element) {
  element.closest('.k-filter-menu').addClass('role-multiselect');
  let operatorSelect = Array.prototype.slice.call(element[0].parentNode.childNodes, 0, 2);

  for (let i = 0; i < operatorSelect.length; i++) {
    operatorSelect[i].parentNode.removeChild(operatorSelect[i]);
  }

  let select = element.kendoMultiSelect({
    dataTextField: "name",
    dataValueField: "id",
    dataSource: [],
    valuePrimitive: true,
    autoBind: true,
    clearButton: false
  }).data('kendoMultiSelect');

  let datasoure = new kendo.data.DataSource({
    transport: {
      read: {
        url: document.querySelector('.grid-filter').dataset.roleEndpoint,
        dataType: "json"
      }
    }
  });

  let filterPopup = element.closest('.k-filter-menu').data('kendoPopup');
  filterPopup.one('open', function() {
    select.setDataSource(datasoure);
  });

  filterPopup.bind('open', function() {
    let grid = VERSTEHE.UserGrid.grid;
    let gridFilter = grid.grid.dataSource.filter();

    if (gridFilter) {
      let filters = gridFilter.filters;
      let roleFilter = filters.find(function (filter) {
        return filter.field === "role";
      });
      select.value((roleFilter ? roleFilter.value.split(',') : ''));
    }
  });
}
