import { t } from './localization';
import { simpleContainsFilterUi, datepickerFilterUi, booleanColumnTemplate, dateColumnTemplate } from '../../grid/configurations/base_column_configuration';

export const columnConfiguration = [
  {
    attributes: {
      'class': 'grid-checkbox'
    },
    selectable: true,
    hideable: false,
    hidden: true,
    width: 50
  },
  {
    field: 'thumbnail',
    title: ' ',
    sortable: false,
    filterable: false,
    hideable: false,
    attributes: {
      'class': 'grid-thumbnail'
    },
    template:
      `<div class="grid-thumbnail-image-wrapper">` +
        `# if (topic.thumbnail) {# <div class="grid-thumbnail-image" style="background-image: url('#: topic.thumbnail #');"></div> #} #` +
      `</div>` 
  },
  {
    field: 'title',
    title: t('contribution'),
    attributes: {
      'class': 'grid-title'
    },
    template:
      `#: topic.title #` +
      `<p>#: topic.description #</p>`,
    filterable: {
      operators: {
        string: {
          contains: t('contains')
        }
      },
      ui: simpleContainsFilterUi
    },
    hideable: false
  },
  {
    field: 'author',
    title: t('author'),
    attributes: {
      'class': 'grid-author'
    },
    template:
      `<div class="grid-author-wrapper">` +
        `<div class="user-profile-picture" style="background-image: url(#: topic.user.avatar #)"></div>` +
        `<div class="user-info">` +
          `<a title="#: topic.user.username #" href="#: topic.user.show_url #">#: topic.user.username #</a>` +
        `</div>` +
      `</div>`,
    filterable: {  
      operators: {
        string: {
          contains: t('contains')
        }
      },
      ui: simpleContainsFilterUi
    },
    hideable: true,
    minScreenWidth: 800
  },
  {
    field: 'created_at',
    title: t('created_at'),
    width: 150,
    attributes: {
      'class': 'grid-updated'
    },
    format: '{0:D}',
    filterable: {
      extra: true,
      operators: {
        date: {
          eq: t('is_equal_to'),
          gte: t('is_after_or_equal_to'),
          lte: t('is_before_or_equal_to')
        }
      },
      ui: datepickerFilterUi
    },
    hidden: false,
    hideable: true,
    template: dateColumnTemplate('created_at'),
    minScreenWidth: 1600
  },
  {
    field: 'updated_at',
    title: t('updated_at'),
    width: 150,
    attributes: {
      'class': 'grid-updated'
    },
    format: '{0:D}',
    filterable: {
      extra: true,
      operators: {
        date: {
          eq: t('is_equal_to'),
          gte: t('is_after_or_equal_to'),
          lte: t('is_before_or_equal_to')
        }
      },
      ui: datepickerFilterUi
    },
    hidden: false,
    hideable: true,
    template: dateColumnTemplate('updated_at'),
    minScreenWidth: 1200
  },
  {
    field: 'primary_topicarea',
    title: t('primary_topicarea'),
    width: 250,
    attributes: {
      'class': 'grid-primary-topicarea'
    },
    template: `<span title="#: topic.primary_topic_area_path #">#: topic.primary_topic_area #</span>`,
    hideable: true,
    hidden: false,
    minScreenWidth: 1600
  },
  {
    field: 'evaluation_state',
    title: t('evaluation_state'),
    width: 250,
    attributes: {
      'class': 'grid-state'
    },
    filterable: {
      operators: {
        string: {
          eq: t('eq'),
          neq: t('neq')
        }
      },
      messages: {
        info: ''
      }
    },
    template: 
    `# if (evaluation) {#` +
      `# if(evaluation.state == 'done') {#` +       
        `<i class="material-icons">done</i> ${t('state_done')}` +
      `# } else { #` +
        `<i class="material-icons">settings</i> ${t('state_in_progress')}` +
      `# } #` +
    `# } else { #` +
      `<i class="material-icons">work</i> ${t('state_submitted')}` +
    `# } #`,
    hideable: false,
    hidden: false,
    minScreenWidth: 1200
  },
  {
    field: 'evaluation_approved',
    title: t('evaluation_approved'),
    width: 250,
    attributes: {
      'class': 'grid-approved'
    },
    filterable: {
      operators: {
        string: {
          eq: t('eq')
        }
      },
      messages: {
        info: ''
      }
    },
    template: 
    `# if (evaluation) {#` +
      `# if(evaluation.approved && evaluation.state === 'done') {#` +       
        `<i class="material-icons">done</i> ${t('state_approved')}` +
      `# } else if (evaluation.state === 'done') { #` +
        `<i class="material-icons">clear</i> ${t('state_denied')}` +
      `# } else { #` +
        `-` +
      `# } #` +
    `# } else { #` +
      `-` +
    `# } #` ,
    hideable: true,
    hidden: true,
    minScreenWidth: 1200
  },
  {
    field: 'topic_areas',
    title: t('topic_areas'),
    hidden: true,
    filterable: {
      operators: {
        string: {
          contains: t('contains')
        }
      }
    }
  },
  {
    field: 'actions',
    title: ' ',
    attributes: {
      'class': 'grid-actions'
    },
    sortable: false,
    filterable: false,
    hideable: false,
    template:
      `# if (evaluation) {#` +
        `# if (evaluation.state == 'done') { #` +
          `<a class="grid-action-edit" href="#: actions.show #"><i class="material-icons">open_in_new</i> ${t('action_show')}</a>` +
        `# } else { #` +
          `<a class="grid-action-edit" href="#: actions.edit #"><i class="material-icons">edit</i> ${t('action_edit')}</a>` +
        `# } #` +
      `# } else {#` +
        `<a class="grid-action-adopt" href="#: actions.adopt #" data-method="post"><i class="material-icons">done</i> ${t('action_adopt')}</a>` +
        `<a class="grid-action-reject" href="#: actions.reject #" data-method="post"><i class="material-icons">block</i> ${t('action_reject')}</a>` +
      `# } #` +
      `# if ( actions.history) { #` +
        `<a class="grid-action-history" href="#: actions.history #" data-target="\\#review_modal" data-toggle="modal"><i class="material-icons">schedule</i> ${t('action_history')}</a>` +
      `# } #`
  }
];
