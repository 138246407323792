import { GetterTree } from "vuex";
import { FilmTranscriptState } from "./state";
import { RootState } from "../../store/types";
import { SaveStatus } from "../interfaces";
import film_transcript from "../film_transcript";


export const Getters = {
  PHRASES: 'filmTranscript/phrases',
  VIDEO_SRC: 'filmTranscript/videoSrc',
  FILTER_VALUE: 'filmTranscript/filterValue',
  FILTERED_PHRASES: 'filmTranscript/filteredPhrases',
  AUTOSCROLL_VALUE: 'filmTranscript/autoscrollValue',
  AUTOSCROLL_ENABLED: 'filmTranscript/autoscrollEnabled',
  SAVE_STATUS: 'filmTranscript/saveStatus',
  SUBTITLE_CUES: 'filmTranscript/subtitleCues',
  CURRENT_TIME: 'filmTranscript/currentTime'
}

export const getters: GetterTree<FilmTranscriptState, RootState> = {
  phrases: (state) => state.phrases,
  filteredPhrases: (state) => {
    if (state.filterValue.length > 0) {
      return state.phrases.filter(phrase => phrase.text.match(new RegExp(state.filterValue, 'i')));
    } else {
      return state.phrases;
    }
  },
  videoSrc: (state) => state.urls.videoSrc,
  filterValue: (state) => state.filterValue,
  autoscrollValue: (state) => state.autoscrollValue,
  autoscrollEnabled: (state) => state.autoscrollValue === true && state.filterValue.length === 0,
  saveStatus: (state) => {
    if (state.phrasesSaving.length > 0) {
      return SaveStatus.SAVING;
    } else {
      return state.savingErrors.length > 0 ? SaveStatus.FAILED : SaveStatus.SUCCESS;
    }
  },
  subtitleCues: (state) => state.phrases.map(phrase => new VTTCue(phrase.startTime, phrase.endTime, phrase.text)),
  currentTime: (state) => state.currentTime
};
