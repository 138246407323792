import { ActionTree } from 'vuex';
import type { UserSettings, WebConferenceState } from './state';
import type { RootState } from '../../store/types';
import { Mutations } from './mutations';

export const Actions = {
  SET_STATE: 'webConference/setState',
  SET_URLS: 'webConference/setUrls',
  SET_ROOM: 'webConference/setRoom',
  SET_DETAILS: 'webConference/setDetails',
  SET_SETTINGS: 'webConference/setSettings',
  SET_USER_DETAILS: 'webConference/setUserDetails',
  SET_PARTICIPANTS: 'webConference/setParticipants',
  ADD_PARTICIPANT: 'webConference/addParticipant',
  REMOVE_PARTICIPANT: 'webConference/removeParticipant',
  SET_LOCAL_PARTICIPANT: 'webConference/setLocalParticipant',
  ADD_CHAT_MESSAGES: 'webConference/addChatMessages',
  SET_ROOM_NAME: 'webConference/setRoomName',
  SET_SHARED_SCREEN_PARTICIPANT: 'webConference/setSharedScreenParticipant',
  SER_LOCAL_VIDEO_TRACK: 'webConference/setLocalVideoTrack',
  SET_LOCAL_AUDIO_TRACK: 'webConference/setLocalAudioTrack',
  ADD_RAISE_HAND_NOTIFICATIONS: 'webConference/addRaiseHandNotifications',
  REMOVE_NOTIFICATION: 'webConference/removeNotification',
  SET_IS_GUEST: 'webConference/setIsGuest',
  SET_MESSAGE_SEEN: 'webConference/setMessageSeen',
  SET_STARTING_TIME: 'webConference/setStartingTime',
  SET_CHAT_MESSAGES: 'webConference/setChatMessages',
  ADD_MODAL: 'webConference/addModal',
  REMOVE_MODAL: 'webConference/removeModal',
  REMOVE_ALL_MODAL_EXCEPT: 'webConference/removeAllModalExcept',
  SET_SETTINGS_OPENED: 'webConference/setSettingsOpened',
  SET_MIC_ACTIVATED: 'webConference/setMicActivated',
  SET_CAM_ACTIVATED: 'webConference/setCamActivated',
  SET_BREAKOUT_STARTED: 'webConference/setBreakoutStarted',
  SET_IS_BREAKOUT: 'webConference/setIsBreakout',
  SET_LIVEKIT_URL: 'webConference/setLivekitUrl',
  SET_ON_JOIN_PAGE: 'webConference/setOnJoinPage',
  SET_USER_SETTINGS: 'webConference/setUserSettings',
  SET_USER_SETTING_SELECTED_MIC: 'webConference/setUserSettingSelectedMic',
  SET_USER_SETTING_SELECTED_CAM: 'webConference/setUserSettingSelectedCam',
  SET_USER_SETTING_SELECTED_AUDIO: 'webConference/setUserSettingSelectedAudio',
  SET_USER_SETTING_CONFERENCE_LAYOUT: 'webConference/setUserSettingConferenceLayout',
};

export const actions: ActionTree<WebConferenceState, RootState> = {
  setState({ commit }, payload: WebConferenceState) {
    commit(Mutations.SET_STATE, payload);
  },
  setUrls({ commit }, payload) {
    commit(Mutations.SET_URLS, payload);
  },
  setRoom({ commit }, payload) {
    commit(Mutations.SET_ROOM, payload);
  },
  setDetails({ commit }, payload) {
    commit(Mutations.SET_DETAILS, payload);
  },
  setSettings({ commit }, payload) {
    commit(Mutations.SET_SETTINGS, payload);
  },
  setUserDetails({ commit }, payload) {
    commit(Mutations.SET_USER_DETAILS, payload);
  },
  setParticipants({ commit }, payload) {
    commit(Mutations.SET_PARTICIPANTS, payload);
  },
  addParticipant({ commit }, payload) {
    commit(Mutations.ADD_PARTICIPANT, payload);
  },
  removeParticipant({ commit }, payload) {
    commit(Mutations.REMOVE_PARTICIPANT, payload);
  },
  setLocalParticipant({ commit }, payload) {
    commit(Mutations.SET_LOCAL_PARTICIPANT, payload);
  },
  addChatMessages({ commit }, payload) {
    commit(Mutations.ADD_CHAT_MESSAGES, payload);
  },
  setChatMessages({ commit }, payload) {
    commit(Mutations.SET_TEXT_MESSAGES, payload);
  },
  setRoomName({ commit }, payload) {
    commit(Mutations.SET_ROOM_NAME, payload);
  },
  setSharedScreenParticipant({ commit }, payload) {
    commit(Mutations.SET_SHARED_SCREEN_PARTICIPANT, payload);
  },
  setLocalVideoTrack({ commit }, payload) {
    commit(Mutations.SET_LOCAL_VIDEO_TRACK, payload);
  },
  setLocalAudioTrack({ commit }, payload) {
    commit(Mutations.SET_LOCAL_AUDIO_TRACK, payload);
  },
  addRaiseHandNotifications({ commit }, payload) {
    commit(Mutations.ADD_RAISE_HAND_NOTIFICATIONS, payload);
  },
  setIsGuest({ commit }, payload) {
    commit(Mutations.SET_IS_GUEST, payload);
  },
  setMessageSeen({ commit }, payload) {
    commit(Mutations.SET_MESSAGE_SEEN, payload);
  },
  setStartingTime({ commit }, payload) {
    commit(Mutations.SET_STARTING_TIME, payload);
  },
  setIsRecording({ commit }, payload) {
    commit(Mutations.SET_IS_RECORDING, payload);
  },
  addModal({ commit }, payload) {
    commit(Mutations.ADD_MODAL, payload);
  },
  removeModal({ commit }, payload) {
    commit(Mutations.REMOVE_MODAL, payload);
  },
  removeAllModalExcept({ commit }, payload) {
    commit(Mutations.REMOVE_ALL_MODAL_EXCEPT, payload);
  },
  setSettingsOpened({ commit }, payload) {
    commit(Mutations.SET_SETTINGS_OPENED, payload);
  },
  setMicActivated({ commit }, payload) {
    commit(Mutations.SET_MIC_ACTIVATED, payload);
  },
  setCamActivated({ commit }, payload) {
    commit(Mutations.SET_CAM_ACTIVATED, payload);
  },
  setBreakoutStarted({ commit }, payload) {
    commit(Mutations.SET_BREAKOUT_STARTED, payload);
  },
  setIsBreakout({ commit }, payload) {
    commit(Mutations.SET_IS_BREAKOUT, payload);
  },
  setLivekitUrl({ commit }, payload) {
    commit(Mutations.SET_LIVEKIT_URL, payload);
  },
  removeNotification({ commit }, payload) {
    commit(Mutations.REMOVE_NOTIFICATION, payload);
  },
  setOnJoinPage({ commit }, payload) {
    commit(Mutations.SET_ON_JOIN_PAGE, payload);
  },
  setUserSettings({ commit }, settings: UserSettings) {
    commit(Mutations.SET_USER_SETTINGS, settings);
  },
  setUserSettingSelectedMic({ commit }, mic: UserSettings['selectedMic']) {
    commit(Mutations.SET_USER_SETTING_SELECTED_MIC, mic);
  },
  setUserSettingSelectedCam({ commit }, cam: UserSettings['selectedCam']) {
    commit(Mutations.SET_USER_SETTING_SELECTED_CAM, cam);
  },
  setUserSettingSelectedAudio({ commit }, audio: UserSettings['selectedAudio']) {
    commit(Mutations.SET_USER_SETTING_SELECTED_AUDIO, audio);
  },
  setUserSettingConferenceLayout({ commit }, layout: UserSettings['conferenceLayout']) {
    commit(Mutations.SET_USER_SETTING_CONFERENCE_LAYOUT, layout);
  },
};
