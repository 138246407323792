export const locals = {
  de: {
    action_edit: 'Bearbeiten',
    and: 'und',
    bulk_change_message: '#= affected # # if (affected == 1) {#Eintrag wurde geändert.#} else {#Einträge wurden geändert#} #',
    bulk_delete_message: '#= affected # # if (affected == 1) {#Benutzer wurde gelöscht.#} else {#Benutzer wurden gelöscht#} #',
    bulk_assign_role_message: '#= affected # # if (affected == 1) {#Benutzer wurde die Rolle zugewiesen.#} else {#Benutzern wurde die Rolle zugewiesen#} #',
    bulk_confirm_message: '#= affected # # if (affected == 1) {#Benutzer wurde bestätigt.#} else {#Benutzer wurden bestätigt.#} #',
    bulk_access_granted_message: '#= affected # # if (affected == 1) {#Benutzer wurde Zugriff gewährt.#} else {#Benutzern wurde Zugriff gewährt.#} #',
    bulk_access_revoked_message: '#= affected # # if (affected == 1) {#Benutzer wurde der Zugriff entzogen.#} else {#Benutzern wurde der Zugriff entzogen.#} #',
    change: 'Ändern',
    contains: 'Beinhaltet',
    created_at: 'Registriert am',
    eq: 'ist',
    neq: 'ist nicht',
    is_after_or_equal_to: 'gleich oder später ist',
    is_before_or_equal_to: 'gleich oder früher ist',
    is_equal_to: 'gleich ist',
    username: 'Benutzername',
    role: 'Rolle',
    edit_user: 'Benutzer bearbeiten',
    action_transit_content: 'Benutzer-Beiträge übertragen',
    action_assign_role: 'Rolle zuweisen',
    action_delete: 'Löschen',
    action_delete_tooltip: 'Benutzer aus der Plattform löschen',
    action_restore: 'Wiederherstellen',
    action_grant_access: 'Zugriff gewähren',
    action_revoke_access: 'Zugriff entziehen',
    action_grant_access_tooltip: 'Benutzer zur Zugriffsliste der aktuellen Site hinzufügen',
    action_revoke_access_tooltip: 'Benutzer von der Zugriffsliste der aktuellen Site entfernen',
    action_delete_permanent: 'Nutzer endgültig löschen',
    filter_user_is_deleted: 'Gelöschte Benutzer',
    user_limit_reached: 'Das maximale Benutzerlimit ist erreicht.',
    user_limit: '(Limit: #= limit # Benutzer)',
    not_confirmed_yet: 'Noch nicht bestätigt',
    action_confirm: 'Bestätigen',
    could_not_confirm: 'Der Benutzer konnte nicht bestätigt werden!',
    filter_unconfirmed_users: 'Unbestätigte Benutzer',
    filter_with_access: 'Nur zugelassene Benutzer',
    filter_without_access: 'Nur nicht zugelassene Benutzer',
    has_access: 'Zugriff?',
    user_has_access: 'Ja',
    user_has_access_tooltip: 'Benutzer hat Site-Zugriff',
    user_has_no_access: 'Nein',
    user_has_no_access_tooltip: 'Benutzer hat keinen Site-Zugriff'
  },
  en: {
    action_edit: 'Edit',
    and: 'and',
    bulk_change_message: '#= affected # # if (affected == 1) {#Entry was changed.#} else {#Entries were changed.#} #',
    bulk_delete_message: '#= affected # # if (affected == 1) {#User has been deleted.#} else {#Users have been deleted#} #',
    bulk_assign_role_message: '#= affected # # if (affected == 1) {#User has been assigned the role.#} else {#Users have been assigned the role#} #',
    bulk_confirm_message: '#= affected # # if (affected == 1) {#User was confirmed.#} else {#Users were confirmed.#} #',
    bulk_access_granted_message: '#= affected # # if (affected == 1) {#User was granted access.#} else {#Users were granted access.#} #',
    bulk_access_revoked_message: '#= affected # # if (affected == 1) {#User was revoked access.#} else {#Users have had their access revoked.#} #',
    change: 'Change',
    contains: 'Contains',
    created_at: 'Registered on',
    eq: 'equals',
    neq: 'is not equal',
    is_after_or_equal_to: 'Is after or equal to',
    is_before_or_equal_to: 'Is before or equal to',
    is_equal_to: 'Is equal to',
    username: 'Username',
    role: 'Role',
    edit_user: 'Edit user',
    action_transit_content: 'Transfer user contributions',
    action_assign_role: 'Assign role',
    action_delete: 'Delete',
    action_delete_tooltip: 'Delete user from the platform',
    action_restore: 'Recovery',
    action_grant_access: 'Grant access',
    action_revoke_access: 'Revoke access',
    action_grant_access_tooltip: 'Add user to the access list of the current site',
    action_revoke_access_tooltip: 'Remove user from the access list of the current site',
    action_delete_permanent: ' Delete user permanently',
    filter_user_is_deleted: 'Deleted users',
    user_limit_reached: 'The maximum user limit has been reached.',
    user_limit: '(Limit: #= limit # Users)',
    not_confirmed_yet: 'Not confirmed yet',
    action_confirm: 'Confirm',
    could_not_confirm: 'The user could not be confirmed!',
    filter_unconfirmed_users: 'Unconfirmed users',
    filter_with_access: 'Authorized users only',
    filter_without_access: 'Only non-authorized users',
    has_access: 'Access?',
    user_has_access: 'Yes',
    user_has_access_tooltip: 'User has site access',
    user_has_no_access: 'No',
    user_has_no_access_tooltip: 'User does not have site access'
  },
  es: {
    action_edit: 'Corregir',
    and: 'y',
    bulk_change_message: '#= affected # # if (affected == 1) {#Se ha modificado la entrada.#} else {#Se han modificado las entradas.#} #',
    bulk_delete_message: '#= affected # # if (affected == 1) {#El usuario ha sido borrado.#} else {#Los usuarios han sido borrados#} #',
    bulk_assign_role_message: '#= affected # # if (affected == 1) {#Se ha asignado el rol al usuario.#} else {#A los usuarios se les ha asignado el rol#} #',
    bulk_confirm_message: '#= affected # # if (affected == 1) {#Usuario fue confirmado.#} else {#Usuarios fueron confirmados.#} #',
    bulk_access_granted_message: '#= affected # # if (affected == 1) {#Usuario ha recibido acceso.#} else {#Usuarios tuvieron acceso.#} #',
    bulk_access_revoked_message: '#= affected # # if (affected == 1) {#Usuario ha visto revocado su acceso.#} else {#Usuarios han visto revocado su acceso.#} #',
    change: 'Cambiar',
    contains: 'Contiene',
    created_at: 'Registrado el',
    eq: 'inguales',
    neq: 'no es igual',
    is_after_or_equal_to: 'Es igual o posterior a',
    is_before_or_equal_to: 'Es anterior o igual a',
    is_equal_to: 'Es igual a',
    username: 'Nombre de usuario',
    role: 'Papel',
    edit_user: 'Editar usuario',
    action_transit_content: 'Transferir contribuciones de usuarios',
    action_assign_role: 'Asignar rol',
    action_delete: 'Borrar',
    action_delete_tooltip: 'Eliminar usuarios de la plataforma',
    action_restore: 'Mejoría',
    action_grant_access: 'Conceder acceso',
    action_revoke_access: ' Revocar el acceso',
    action_grant_access_tooltip: 'Añadir usuario a la lista de acceso del sitio actual',
    action_revoke_access_tooltip: 'Eliminar el usuario de la lista de acceso del sitio actual',
    action_delete_permanent: 'Eliminar el usuario de forma permanente',
    filter_user_is_deleted: 'Usuario borrado',
    user_limit_reached: 'Se ha alcanzado el límite máximo de usuarios.',
    user_limit: '(Límite: #= limit # usuarios)',
    not_confirmed_yet: 'Aún no está confirmado',
    confirm: 'Confirmar',
    could_not_confirm: '¡El usuario no pudo ser confirmado!',
    filter_unconfirmed_users: 'Usuarios no confirmados',
    filter_with_access: 'Sólo usuarios autorizados',
    filter_without_access: 'Sólo usuarios no autorizados',
    has_access: '¿Acceso?',
    user_has_access: 'Sí',
    user_has_access_tooltip: 'El usuario tiene acceso al sitio',
    user_has_no_access: 'No',
    user_has_no_access_tooltip: 'El usuario no tiene acceso al sitio'
  }
};

export function t(key) {
  return locals[_app.info.currentLocale][key] || key;
}

export function tt(key, variables = {}) {
  let template = kendo.template(t(key));
  return template(variables);
}
