import { MutationTree } from 'vuex';
import { LibraryState } from './state';
import { Take, Slideshow, SelectionMode, Clip, SearchFilter, Image } from '../interfaces';
import { v4 as uuid } from 'uuid';
import Vue from 'vue';

export const Mutations = {
  SET_BASE_STATE: 'SET_BASE_STATE',
  ADD_MEDIA_RESULTS: 'ADD_MEDIA_RESULTS',
  RESET_MEDIA_RESULTS: 'RESET_MEDIA_RESULTS',
  SET_PRIVATE_TAKES: 'SET_PRIVATE_TAKES',
  SET_PUBLIC_TAKES: 'SET_PUBLIC_TAKES',
  SET_DOCUMENTS: 'SET_DOCUMENTS',
  SET_IMAGES: 'SET_IMAGES',
  SET_CLIPS: 'SET_CLIPS',
  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
  CREATE_OR_UPDATE_SEARCH: 'CREATE_OR_UPDATE_SEARCH',
  DESTROY_SEARCH: 'DESTROY_SEARCH',
  SET_SELECTED_TAKES: 'SET_SELECTED_TAKES',
  SET_SELECTED_SLIDES: 'SET_SELECTED_SLIDES',
  SET_SELECTED_IMAGES: 'SET_SELECTED_IMAGES',
  SET_INSPECT_ITEM: 'SET_INSPECT_ITEM',
  REFRESH_TAKE: "REFRESH_TAKE",
  UPDATE_TAKE: "UPDATE_TAKE",
  DELETE_MEDIUM: "DELETE_MEDIUM",
  DELETE_CLIP: "DELETE_CLIP",
  ADD_OR_UPDATE_DOCUMENT: "ADD_OR_UPDATE_DOCUMENT",
  UPDATE_DOCUMENT_PROGRESS: "UPDATE_DOCUMENT_PROGRESS",
  SET_SELECTION_MODE: "SET_SELECTION_MODE",
  UPDATE_DOCUMENT: "UPDATE_DOCUMENT",
  UPDATE_IMAGE: "UPDATE_IMAGE",
  ADD_PUBLIC_TAKE: "ADD_PUBLIC_TAKE",
  ADD_PUBLIC_TAKES: "ADD_PUBLIC_TAKES",
  ADD_UPLOADED_TAKE: "ADD_UPLOADED_TAKE",
  TAB_LOADING: "TAB_LOADING",
  UNLOAD_PUBLIC_TAKES_WITH_STATE: "UNLOAD_PUBLIC_TAKES_WITH_TYPE",
  UPDATE_CLIP: "UPDATE_CLIP",
  ADD_PRIVATE_TAKE: "ADD_PRIVATE_TAKE",
  SET_SEARCH_FILTER: "SET_SEARCH_FILTER",
  ADD_CLIP: "ADD_CLIP",
  SET_MEDIA_TYPES: "SET_MEDIA_TYPES",
  SHOW_SHARE_AND_DELETE_BUTTON: "SHOW_SHARE_AND_DELETE_BUTTON",
}

export const mutations: MutationTree<LibraryState> = {
  [Mutations.SET_BASE_STATE](state, baseState: { apiUrl: string, brandUrl: string, uploadUrl: string, settings: any }) {
    state.apiBaseUrl = baseState.apiUrl;
    state.brandUrl = baseState.brandUrl;
    state.uploadUrl = baseState.uploadUrl;
    state.settings = baseState.settings;
  },

  [Mutations.ADD_MEDIA_RESULTS](state, payload: { collection: string, results: Array<any>, totalEntries?: number }) {
    state[payload.collection].results.push(...payload.results);

    if (payload.totalEntries) {
      state[payload.collection].totalEntries = payload.totalEntries;
    }
  },

  [Mutations.RESET_MEDIA_RESULTS](state, identifier) {
    state[identifier].results = [];
    state[identifier].totalEntries = null;

    // Generate new key in order to reset infinite loading
    state[identifier].infinityId = uuid();
  },

  [Mutations.TAB_LOADING](state, payload: { identifier: string, value: boolean }) {
    let tab = state[payload.identifier];
    tab.isLoading = payload.value;
  },

  [Mutations.SET_PRIVATE_TAKES](state, takes: Array<Take>) {
    state.privateTakes.results = takes;
  },

  [Mutations.SET_PUBLIC_TAKES](state, takes: Array<Take>) {
    state.publicTakes.results = takes;
  },

  [Mutations.UNLOAD_PUBLIC_TAKES_WITH_STATE](state, assetState: 'requested' | 'approved') {
    state.publicTakes.results = state.publicTakes.results.filter((take) => take.asset_state !== assetState);
  },

  [Mutations.SET_DOCUMENTS](state, documents: Array<Slideshow>) {
    state.documents.results = documents;
  },

  [Mutations.SET_IMAGES](state, images: Array<Image>) {
    state.images.results = images;
  },

  [Mutations.SET_CLIPS](state, clips: Array<Clip>) {
    state.clips.results = clips;
  },

  [Mutations.SET_ACTIVE_TAB](state, identifier: string) {
    state.activeTab = identifier;
  },

  [Mutations.CREATE_OR_UPDATE_SEARCH](state, payload: { query: string, items: Array<Take | Slideshow> }) {
    let searchResult = state.searchResults.find(result => result.query === payload.query);

    if (searchResult) {
      searchResult.results = payload.items;
    } else {
      state.searchResults.push({
        query: payload.query,
        results: payload.items,
        filter: SearchFilter.ALL
      });
    }
  },

  [Mutations.DESTROY_SEARCH](state, query: string) {
    const search = state.searchResults.find(result => result.query === query);
    state.searchResults.splice(state.searchResults.indexOf(search), 1);
  },

  [Mutations.SET_SELECTED_TAKES](state, ids: Array<number>) {
    state.selectedTakes = ids;
  },

  [Mutations.SET_SELECTED_SLIDES](state, ids: Array<number>) {
    state.selectedSlides = ids;
  },

  [Mutations.SET_SELECTED_IMAGES](state, ids: Array<number>) {
    state.selectedImages = ids;
  },

  [Mutations.SET_INSPECT_ITEM](state, item: Take | Slideshow | Image) {
    state.inspectItem = item;
  },

  [Mutations.REFRESH_TAKE](state, payload) {
    const takesToRefresh = takeItemsById(state, payload.id);

    for (let i = 0; i < takesToRefresh.length; i++) {
      let take = takesToRefresh[i];

      take.thumbnail_url = payload.thumbnail_url + '?' + Date.now();
      take.state = payload.state;
      take.video.url = payload.source;
      take.video.duration = payload.duration;
      take.video.resolution_height = payload.height;
      take.video.resolution_width = payload.width;

      if (payload.asset_state) {
        take.asset_state = payload.asset_state;
      }
    }

  },

  [Mutations.UPDATE_TAKE](state, payload: { amor_id?: number, id?: number, values: any }) {
    const takesToRefresh = payload.amor_id ? takeItemsByAmorId(state, payload.amor_id) : takeItemsById(state, payload.id);

    for (let i = 0; i < takesToRefresh.length; i++) {
      let take = takesToRefresh[i];

      for (let key in payload.values) {
        Vue.set(take, key, payload.values[key]);
      }
    }
  },

  [Mutations.UPDATE_DOCUMENT](state, payload: { id: number, values: any }) {
    const documents = documentItemsById(state, payload.id);

    for (let i = 0; i < documents.length; i++) {
      let document = documents[i];

      for (let key in payload.values) {
        Vue.set(document, key, payload.values[key]);
      }
    }
  },

  [Mutations.UPDATE_IMAGE](state, payload: { id: number, values: any }) {
    const images = imagesItemsById(state, payload.id);

    for (let i = 0; i < images.length; i++) {
      let document = images[i];

      for (let key in payload.values) {
        Vue.set(document, key, payload.values[key]);
      }
    }
  },

  [Mutations.UPDATE_CLIP](state, payload: { id: number, values: any }) {
    let clip = state.clips.results.find(clip => clip.id === payload.id);
    for (let key in payload.values) {
      Vue.set(clip, key, payload.values[key]);
    }
  },

  [Mutations.DELETE_MEDIUM](state, payload: { medium_type: string, medium_id: number }) {
    if (payload.medium_type === 'take') {
      // Remove from lists
      removeMediumFromList(state.privateTakes.results, payload.medium_type, payload.medium_id);
      removeMediumFromList(state.publicTakes.results, payload.medium_type, payload.medium_id);
      removeMediumFromList(state.uploadedTakes, payload.medium_type, payload.medium_id);

      for (let i = 0; i < state.searchResults.length; i++) {
        removeMediumFromList(state.searchResults[i].results, payload.medium_type, payload.medium_id);
      }

      // Remove from selected
      if (state.selectedTakes.indexOf(payload.medium_id) != -1) {
        state.selectedTakes.splice(state.selectedTakes.indexOf(payload.medium_id), 1);
      }
    } else if (payload.medium_type === 'slideshow') {
      // Remove from documents
      const obj = state.documents.results.find(obj => (obj.type === payload.medium_type) && (obj.id === payload.medium_id));
      const slideIds = obj.slides.map(slide => slide.id);
      state.selectedSlides = state.selectedSlides.filter((id) => slideIds.indexOf(id) === -1);
      state.documents.results.splice(state.documents.results.indexOf(obj), 1);
    } else {
      const obj = state.images.results.find(obj => (obj.type === payload.medium_type) && (obj.id === payload.medium_id));
      state.images.results.splice(state.images.results.indexOf(obj), 1);
    }

    // Close details if inspected
    if (state.inspectItem && state.inspectItem.id === payload.medium_id && state.inspectItem.type === payload.medium_type) {
      state.inspectItem = null;
    }
  },

  [Mutations.DELETE_CLIP](state, id) {
    removeMediumFromList(state.clips.results, 'clip', id);

    for (let i = 0; i < state.searchResults.length; i++) {
      removeMediumFromList(state.searchResults[i].results, 'clip', id);
    }

    // Close details if inspected
    if (state.inspectItem && state.inspectItem.id === id && state.inspectItem.type === 'clip') {
      state.inspectItem = null;
    }
  },

  [Mutations.ADD_OR_UPDATE_DOCUMENT](state, document: Slideshow | Image) {
    if (document.type === 'slideshow'){
      let object = state.documents.results.find(doc => doc.id === document.id);
      if (object) {
        Object.assign(object, document);
      } else {
        state.documents.results.unshift(document as Slideshow);
      }
    }else{
      let object = state.images.results.find(doc => doc.id === document.id);
      if (object) {
        Object.assign(object, document);
      } else {
        state.images.results.unshift(document);
      }
    }
   
  },

  [Mutations.UPDATE_DOCUMENT_PROGRESS](state, payload: { document_id: number, state: string, page_quantity: number, slides: Array<any> }) {
    let slideshow = state.documents.results.find((doc) => doc.id === payload.document_id);
    if (slideshow) {
      if (payload.slides.length > 0) {
        slideshow.thumbnail_url = payload.slides[0].thumbnail_url
      }
      const newSlides = payload.slides.filter(s => slideshow.slides.map(s2 => s2.id).indexOf(s.id) < 0)
      slideshow.state = payload.state;
      slideshow.page_quantity = payload.page_quantity;
      slideshow.slides.push(...newSlides);
    }
  },

  [Mutations.SET_SELECTION_MODE](state, value: SelectionMode) {
    state.selectionMode = value;
  },

  [Mutations.ADD_PUBLIC_TAKE](state, take: Take) {
    state.publicTakes.results.unshift(take);
  },

  [Mutations.ADD_PUBLIC_TAKES](state, takes: Array<Take>) {
    state.publicTakes.results.push(...takes);
  },

  [Mutations.ADD_PRIVATE_TAKE](state, take: Take) {
    state.privateTakes.results.unshift(take);
  },

  [Mutations.SET_SEARCH_FILTER](state, payload: { search: string, filter: SearchFilter }) {
    let resultSet = state.searchResults.find(result => result.query === payload.search);
    resultSet.filter = payload.filter;
  },

  [Mutations.ADD_UPLOADED_TAKE](state, take: Take) {
    state.uploadedTakes.push(take);
  },

  [Mutations.ADD_CLIP](state, clip: Clip) {
    state.clips.results.unshift(clip);
  },

  [Mutations.SET_MEDIA_TYPES](state, mediaTypes: Array<'Take' | 'Document' | 'Clip' | 'Image'>) {
    state.mediaTypes = mediaTypes;
  },

  [Mutations.SHOW_SHARE_AND_DELETE_BUTTON](state, showShareAndDelete: boolean) {
    state.showShareAndDeleteButton = showShareAndDelete;
  },
}

function removeMediumFromList(list: Array<Slideshow | Take | Clip | Image>, type: string, id: number) {
  const obj = list.find(obj => (obj.type === type) && (obj.id === id));

  if (obj) {
    list.splice(list.indexOf(obj), 1);
  }
}

function takeItemsById(state, id): Array<Take> {
  let privateTakes = state.privateTakes.results.filter((medium) => medium.type === 'take' && medium.id === id);
  let publicTakes = state.publicTakes.results.filter((medium) => medium.type === 'take' && medium.id === id);
  let uploadedTakes = state.uploadedTakes.filter(medium => medium.type === 'take' && medium.id === id);
  let takes = [...privateTakes, ...publicTakes, ...uploadedTakes];

  for (let i = 0; i < state.searchResults.length; i++) {
    const resultTakes = state.searchResults[i].results.filter((medium) => medium.type === 'take' && medium.id === id);

    takes = [...takes, ...(resultTakes as Array<Take>)];
  }

  return takes;
}

function documentItemsById(state, id): Array<Slideshow> {
  let documents = state.documents.results.filter((medium) => medium.type === 'slideshow' && medium.id === id);

  for (let i = 0; i < state.searchResults.length; i++) {
    const resultDocs = state.searchResults[i].results.filter((medium) => medium.type === 'slideshow' && medium.id === id);

    documents = [...documents, ...(resultDocs as Array<Slideshow>)];
  }
  return documents;
}

function imagesItemsById(state, id): Array<Image> {
  let documents = state.images.results.filter((medium) => medium.type === 'image' && medium.id === id);

  for (let i = 0; i < state.searchResults.length; i++) {
    const resultDocs = state.searchResults[i].results.filter((medium) => medium.type === 'image' && medium.id === id);

    documents = [...documents, ...(resultDocs as Array<Image>)];
  }
  return documents;
}

function takeItemsByAmorId(state, amor_id) {
  let privateTakes = state.privateTakes.results.filter((medium) => medium.type === 'take' && (medium as Take).amor_id === amor_id);
  let publicTakes = state.privateTakes.results.filter((medium) => medium.type === 'take' && (medium as Take).amor_id === amor_id);
  let uploadedTakes = state.uploadedTakes.filter((medium) => medium.type === 'take' && (medium as Take).amor_id === amor_id);
  let takes = [...privateTakes, ...publicTakes, ...uploadedTakes];

  for (let i = 0; i < state.searchResults.length; i++) {
    const resultTakes = state.searchResults[i].results.filter((medium) => medium.type === 'take' && (medium as Take).amor_id === amor_id);

    takes = [...takes, ...(resultTakes as Array<Take>)];
  }

  return takes;
}
