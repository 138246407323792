import { GetterTree } from "vuex";
import { LibraryState } from "./state";
import { RootState } from "../../store/types";
import { Take, Slideshow, SearchFilter, Image } from "../interfaces";

export const Getters = {
  PRIVATE_TAKES: 'library/privateTakes',
  PUBLIC_TAKES: 'library/publicTakes',
  DOCUMENTS: 'library/documents',
  ACTIVE_TAB: 'library/activeTab',
  SEARCH_RESULT: 'library/searchResult',
  SEARCH_RESULTS: 'library/searchResults',
  ACTIVE_FILTER: 'library/activeFilter',
  SELECTED_TAKES: 'library/selectedTakes',
  SELECTED_SLIDES: 'library/selectedSlides',
  SELECTED_IMAGES: 'library/selectedImages',
  INSPECT_ITEM: 'library/inspectItem',
  TAB_ITEMS: 'library/tabItems',
  BRAND_URL: 'library/brandUrl',
  UPLOAD_URL: 'library/uploadUrl',
  CONVERTING_DOCUMENTS: 'library/convertingDocuments',
  BASE_URL: 'library/baseUrl',
  SELECTION_MODE: 'library/selectionMode',
  REQUESTED_TAKES: 'library/requestedTakes',
  APPROVED_TAKES: 'library/approvedTakes',
  INFINITY_ID: 'library/infinityId',
  MEDIA_THIS_WEEK: 'library/mediaThisWeek',
  MEDIA_LAST_WEEK: 'library/mediaLastWeek',
  MEDIA_OLDER: 'library/mediaOlder',
  UPLOADED_TAKES: 'library/uploadedTakes',
  SETTINGS: 'library/settings',
  MEDIA_TYPES: 'library/mediaTypes',
  SHOW_SHARE_AND_DELETE_BUTTON: 'library/showShareAndDeleteButton',
  REQUESTED_AND_APPROVED_TAKES: 'library/requestedAndApprovedTakes',
  DELETABLE_SELECTED: 'library/deletableSelected',
  SHAREABLE_SELECTED: 'library/shareableSelected'
}

export const getters: GetterTree<LibraryState, RootState> = {
  privateTakes(state): Array<Take> {
    return state.privateTakes.results;
  },
  publicTakes(state): Array<Take> {
    return state.publicTakes.results;
  },
  documents(state): Array<Slideshow> {
    return state.documents.results;
  },
  images(state): Array<Image> {
    return state.images.results;
  },
  requestedTakes(state): Array<Take> {
    // skip filtering if user is not master editor
    if (_app.info.user.roles.indexOf('master_editor') === -1) {
      return [];
    } else {
      return state.publicTakes.results.filter(take => take.asset_state === 'requested');
    }
  },
  approvedTakes(state): Array<Take> {
    return state.publicTakes.results.filter(take => take.asset_state === 'approved');
  },
  requestedAndApprovedTakes(state, getters): Array<Take> {
    return state.publicTakes.results.filter(take => {
      if (take.asset_state === 'requested' || take.asset_state === 'approved') {
        return true;
      } else {
        return false;
      }
    })
  },
  activeTab(state): string {
    return state.activeTab;
  },
  searchResult: (state) => (query) => {
    const resultSet = state.searchResults.find(result => result.query === query);

    switch (resultSet.filter) {
      case SearchFilter.DOCUMENTS: {
        return resultSet.results.filter(result => result.type === 'slideshow');
      }
      case SearchFilter.CLIPS: {
        return resultSet.results.filter(result => result.type === 'clip');
      }
      case SearchFilter.PRIVATE_TAKES: {
        return resultSet.results.filter(result => (result.user.id === _app.info.user.userId) && result.type === 'take');
      }
      case SearchFilter.PUBLIC_TAKES: {
        return resultSet.results.filter(result => result.type === 'take' && ['approved', 'requested'].indexOf((result as Take).asset_state) != -1);
      }
      case SearchFilter.IMAGES: {
        return resultSet.results.filter(result => result.type === 'image');
      }
      default: {
        return resultSet.results;
      }
    }
  },
  activeFilter: (state) => (query) => state.searchResults.find(result => result.query === query).filter,
  searchResults: (state) => {
    return state.searchResults;
  },
  selectedTakes: (state) => {
    return state.selectedTakes;
  },
  selectedSlides: (state) => {
    return state.selectedSlides;
  },
  selectedImages: (state) => {
    return state.selectedImages;
  },
  inspectItem: (state) => {
    return state.inspectItem;
  },
  tabItems: (state, getters) => (identifier: string) => {
    if (identifier === 'publicTakes') {
      return getters.approvedTakes;
    } else {
      return state[identifier].results;
    }
  },
  infinityId: (state) => (identifier: string) => {
    if (['privateTakes', 'publicTakes', 'documents', 'clips', 'images'].some(tab => tab === identifier)) {
      return state[identifier].infinityId;
    } else {
      return null;
    }
  },
  brandUrl: (state) => state.brandUrl,
  uploadUrl: (state) => state.uploadUrl,
  convertingDocuments: (state) => state.documents.results.filter((doc) => doc.state === 'converting').map(doc => doc.id),
  baseUrl: (state) => state.apiBaseUrl,
  selectionMode: state => state.selectionMode,
  mediaThisWeek: (state, getters) => (identifier: string, tab?: string) => {
    const thisWeek = getThisWeek();
    const items = getters.tabItems(identifier).filter(item => {
      const dateString = (tab === 'publicTakes' ? item.asset_updated_at : item.created_at);
      return new Date(dateString) >= thisWeek;
    });

    return items;
  },
  mediaLastWeek: (state, getters) => (identifier: string, tab?: string) => {
    const thisWeek = getThisWeek();
    const lastWeek = getLastWeek();

    const items = getters.tabItems(identifier).filter(item => {
      const dateString = (tab === 'publicTakes' ? item.asset_updated_at : item.created_at);
      const date = new Date(dateString);
      return date >= lastWeek && date < thisWeek;
    });

    return items;
  },
  mediaOlder: (state, getters) => (identifier: string, tab?: string) => {
    const lastWeek = getLastWeek();
    
    const items = getters.tabItems(identifier).filter(item => {
      const dateString = (tab === 'publicTakes' ? item.asset_updated_at : item.created_at);
      return new Date(dateString) < lastWeek;
    });

    return items;
  },
  uploadedTakes: (state) => state.uploadedTakes,
  settings: (state) => state.settings,
  mediaTypes: (state) => state.mediaTypes,
  showShareAndDeleteButton: (state) => state.showShareAndDeleteButton,
  selectedTakeObjects: (state) => state.selectedTakes.map((takeId) => {
    // Workaround to get take from store,
    // search first in private takes and then in public takes if none was found
    let take = state.privateTakes.results.find((take) => take.id === takeId);
    if (!take) {
      take = state.publicTakes.results.find((take) => take.id === takeId);
    }

    // In case the take was just uploaded
    if (!take) {
      take = state.uploadedTakes.find(take => take.id === takeId);
    }

    return take;
  }),
  deletableSelected: (state, getters) => {
    return getters.selectedTakeObjects.some((take) => take.can_manage && (take.asset_state === 'private'|| take.asset_state === null));
  },
  shareableSelected: (state, getters) => {
    const canUserShare = _app.info.user.highestRoleId >= _app.roles.find(role => role.key === getters.settings.releaseTakeRole).id;
    return getters.selectedTakeObjects.some((take) => canUserShare && take.can_manage && (take.asset_state === 'private' || take.asset_state === null));
  }
};

function getThisWeek(): Date {
  return moment().startOf('week').toDate();
}

function getLastWeek(): Date {
  return moment().startOf('week').subtract(1, 'd').startOf('week').toDate();
}
