import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import App from './components/app.vue';
import routes from './routes';
import { Actions } from './store/actions';
import { WebConferenceModule } from './store';
import { getUserSettingsFromLS } from './user_settings_store';

let content: Vue;

export function init() {
  this.router = new VueRouter({
    routes
  });

  initContent(this.router);
}

export function initKendoDateTimePicker() {
  var startDate = getDefaultDate()
  var altField = $('.datepicker-scheduled-at input[name="web_conference[scheduled_at]"]');

  $('#datepicker-scheduled-at').kendoDateTimePicker({
    value: startDate,
    dateInput: true,
    change: function() {
      altField.val(this.value().toISOString());
    }
  });
}

function getDefaultDate() {
  var defaultDate = new Date();
  var field = $('#web_conference_scheduled_at');

  if (field.val() !== '' && field.val() != '0') {
    var dateString = field.val() as any;
    dateString = dateString.split(" ") ;
    
    dateString.pop();
    dateString = dateString.join("T");

    defaultDate = new Date(Date.parse(dateString));
  }

  return defaultDate;
};

async function initContent(router: VueRouter) {
  const appNode = document.getElementById('v-web-conference');
  const urls = JSON.parse(appNode.dataset.urls);
  const webConferenceDetails = JSON.parse(appNode.dataset.webconferenceDetails);
  const webConferenceSettings = JSON.parse(appNode.dataset.webconferenceSettings);
  const userDetails = JSON.parse(appNode.dataset.userDetails);
  const livekitUrl = JSON.parse(appNode.dataset.livekitWssUrl);

  store.registerModule('webConference', WebConferenceModule);
  store.dispatch(Actions.SET_URLS, urls);
  store.dispatch(Actions.SET_DETAILS, webConferenceDetails);
  store.dispatch(Actions.SET_SETTINGS, webConferenceSettings);
  store.dispatch(Actions.SET_USER_DETAILS, userDetails);
  store.dispatch(Actions.SET_BREAKOUT_STARTED, webConferenceDetails.breakout_started);
  store.dispatch(Actions.SET_LIVEKIT_URL, livekitUrl);

  const userSettings = await getUserSettingsFromLS();
  store.dispatch(Actions.SET_USER_SETTINGS, userSettings);

  content = new Vue({
    store,
    router,
    i18n: VERSTEHE.vueI18n,
    el: appNode,
    render: h => h(App),
  });
}