import { t } from './localization';
import { tagsFilterUi } from './filter/tags_filter_ui.js';

function simpleContainsFilterUi (element) {
  element[0].classList.add('k-textbox');

  // Remove operator label & select
  var operatorSelect = Array.prototype.slice.call(element[0].parentNode.childNodes, 0, 2);

  for (var i = 0; i < operatorSelect.length; i++) {
    operatorSelect[i].parentNode.removeChild(operatorSelect[i]);
  }
}

function datepickerFilterUi (element) {
  element.closest('form').find('.k-filter-and option[value="or"]').remove();
  element.closest('form').find('select').kendoDropDownList();
  element.kendoDatePicker({
    parseFormats: ['yyyy/MM/dd']
  });
}

function booleanColumnTemplate (fieldName) {
  return `<div class="grid-cell-wrapper">` +
      `<i class="material-icons"># if (${fieldName}) {#check#} else {#close#} #</i>` +
    `</div>`;
}

function dateColumnTemplate (fieldName) {
  return `# if (${fieldName}) { #` +
    `<span title="#: kendo.format('{0:F}', ${fieldName}) #">#: kendo.format('{0:D}', ${fieldName}) #</span>` +
  `# } #`;
}

export const columnConfiguration = [
  {
    attributes: {
      'class': 'grid-checkbox'
    },
    selectable: true,
    hideable: false,
    hidden: false,
    width: 50,
    name: 'select'
  },
  {
    field: 'thumbnail',
    title: ' ',
    sortable: false,
    filterable: false,
    hideable: false,
    attributes: {
      'class': 'grid-thumbnail'
    },
    template:
      `<a href="#: actions.edit #" class="grid-thumbnail-container" title="${t('edit_contribution')}">`+
        `<div class="grid-thumbnail-image-wrapper" data-type="#: type #">` +
          `# if (thumbnail) {# <div class="grid-thumbnail-image" style="background-image: url('#: thumbnail #');"></div> #} #` +
        `</div>` +
        `# if (featured) {# <div class="featured-contribution-ribbon">${t('featured_ribbon')}</div> #} #` +
      `</a>`
  },
  {
    field: 'title',
    title: t('contribution'),
    attributes: {
      'class': 'grid-title'
    },
    template:
      `<a href="#: actions.edit #" title="${t('edit_contribution')}">` +
        `# if(title) {#` +
          `#: title #` +
        `# } else { #` +
          `# if (type === 'post') { #` +
            `${t('untitled_post')}` +
          `# } else if (type === 'topic') { #` +
            `${t('untitled_topic')}` +
          `# } else if (type === 'playlist') { #` +
            `${t('untitled_playlist')}` +
          `# } else if (type === 'course') { #` +
            `${t('untitled_course')}` +
          `# } else if (type === 'course_plan') { #` +
            `${t('untitled_course_plan')}` +
          `# } #` +
        `# } #` +
      `</a>` +
      `<p>#: description #</p>`,
    filterable: {
      operators: {
        string: {
          contains: t('contains')
        }
      },
      messages: {
        value: ''
      },
      ui: simpleContainsFilterUi
    },
    hideable: false
  },
  {
    field: 'author',
    title: t('author'),
    attributes: {
      'class': 'grid-author'
    },
    template:
      `<div class="grid-author-wrapper">` +
        `<div class="user-profile-picture" style="background-image: url(#: user.avatar #)"></div>` +
        `<div class="user-info # if(co_authors.length) { #co-authors-info# } #">` +
          `# if (user.deleted) {#` +
            `<a href="#: user.show_url #">${t('deleted_user')}</a>` +
          `# } else { #` +
            `<a title="#: user.username #" href="#: user.show_url #">#: user.username #</a>` +
          `# } #` +
          `# if(co_authors.length) {#` +
            `<a href="#: co_authors_url #" data-target="\\#co_authors_modal" data-toggle="modal" class="co-author-link" data-toggle="modal">`
              + `${t('and_others')}` +
            `</a>` +
          `# } #` +
        `</div>` +
      `</div>`,
    filterable: {
      operators: {
        string: {
          contains: t('contains')
        }
      },
      messages: {
        value: ''
      },
      ui: simpleContainsFilterUi
    },
    hideable: true,
    media: '(min-width: 800px)'
  },
  {
    field: 'tags',
    title: t('tags'),
    hidden: true,
    filterable: {
      operators: {
        string: {
          contains: t('contains')
        }
      },
      messages: {
        value: ''
      },
      ui: tagsFilterUi
    }
  },
  {
    field: 'topic_areas',
    title: t('topic_areas'),
    hidden: true,
    filterable: {
      operators: {
        string: {
          contains: t('contains')
        }
      }
    }
  },
  {
    field: 'topic_area_list',
    title: t('topic_areas'),
    attributes: {
      'class': 'grid-topic-areas'
    },
    hidden: true,
    hideable: true,
    width: 250,
    media: '(min-width: 1200px)',
    filterable: false,
    template:
    `<div title="#= topic_area_list.map(function(t) { return t.join(' » '); }).join("<br/>") #" data-toggle="tooltip">` +
      `# for (var i = 0; i < topic_area_list.length && i < 3; i++) { #` +
      `<div>#= topic_area_list[i][topic_area_list[i].length - 1] #</div>` +
      `# } #` +
    `</div>`
  },
  {
    field: 'updated_at',
    title: t('updated_at'),
    width: 250,
    attributes: {
      'class': 'grid-updated'
    },
    format: '{0:D}',
    filterable: {
      extra: true,
      operators: {
        date: {
          eq: t('is_equal_to'),
          gte: t('is_after_or_equal_to'),
          lte: t('is_before_or_equal_to')
        }
      },
      messages: {
        operator: '',
        value: '',
        logic: '',
        additionalValue: '',
        additionalOperator: ''
      },
      ui: datepickerFilterUi
    },
    hideable: true,
    template: dateColumnTemplate('updated_at'),
    media: '(min-width: 1200px)'
  },
  {
    field: 'created_at',
    title: t('created_at'),
    width: 250,
    attributes: {
      'class': 'grid-updated'
    },
    format: '{0:D}',
    filterable: {
      extra: true,
      operators: {
        date: {
          eq: t('is_equal_to'),
          gte: t('is_after_or_equal_to'),
          lte: t('is_before_or_equal_to')
        }
      },
      messages: {
        operator: '',
        value: '',
        logic: '',
        additionalValue: '',
        additionalOperator: ''
      },
      ui: datepickerFilterUi
    },
    hidden: true,
    hideable: true,
    template: dateColumnTemplate('created_at'),
    media: '(min-width: 1200px)'
  },
  {
    field: 'expires_at',
    title: t('expires_at'),
    width: 250,
    attributes: {
      'class': 'grid-expires'
    },
    format: '{0:D}',
    filterable: {
      extra: true,
      operators: {
        date: {
          eq: t('is_equal_to'),
          gte: t('is_after_or_equal_to'),
          lte: t('is_before_or_equal_to')
        }
      },
      messages: {
        operator: '',
        value: '',
        logic: '',
        additionalValue: '',
        additionalOperator: ''
      },
      ui: datepickerFilterUi
    },
    hidden: true,
    hideable: true,
    template: dateColumnTemplate('expires_at'),
    media: '(min-width: 1200px)'
  },
  {
    field: 'views',
    title: t('views'),
    width: 150,
    attributes: {
      'class': 'grid-views'
    },
    filterable: false,
    hidden: true,
    hideable: true,
    media: '(min-width: 1200px)'
  },
  {
    field: 'comments',
    title: t('comments'),
    width: 150,
    attributes: {
      'class': 'grid-comments'
    },
    filterable: false,
    hidden: true,
    hideable: true,
    media: '(min-width: 1200px)'
  },
  {
    field: 'visibility',
    title: t('visibility'),
    width: 200,
    hidden: false,
    attributes: {
      'class': 'grid-visibility'
    },
    filterable: {
      operators: {
        string: {
          eq: t('eq')
        }
      },
      messages: {
        info: '',
        operator: ''
      }
    },
    sortable: false,
    hidden: true,
    hideable: true,
    media: '(min-width: 800px)',
    template:
      `# if (state === 'public') { #` +
        `<i class="material-icons">public</i> ${t('visibility_public')}` +
      `# } else if (state === 'private') { #` +
        `<i class="material-icons">visibility</i> ${t('visibility_private')}` +
      `# } else if (state === 'unlisted') { #` +
        `<i class="material-icons">grid_off</i> ${t('visibility_unlisted')}` +
      `# } else if (state === 'unpublished') { #` +
        `<i class="material-icons">visibility_off</i> ${t('visibility_unpublished')}` +
      `# } #`
  },
  {
    field: 'featured',
    title: t('featured'),
    width: 150,
    hidden: true,
    attributes: {
      'class': 'grid-featured'
    },
    filterable: {
      messages: {
        info: '',
        isTrue: t('filter_featured_true'),
        isFalse: t('filter_featured_false')
      }
    },
    sortable: false,
    hideable: true,
    template: booleanColumnTemplate('featured'),
    media: '(min-width: 1200px)'
  },
  {
    field: 'is_collaborative_content',
    title: t('is_collaborative_content'),
    width: 150,
    hidden: true,
    attributes: {
      'class': 'grid-is_collaborative_content'
    },
    filterable: {
      messages: {
        info: '',
        isTrue: t('filter_is_collaborative_content_true'),
        isFalse: t('filter_is_collaborative_content_false')
      }
    },
    sortable: false,
    hideable: true,
    template: booleanColumnTemplate('is_collaborative_content'),
    media: '(min-width: 1200px)'
  },
  {
    field: 'expired',
    title: t('expired'),
    width: 150,
    hidden: true,
    attributes: {
      'class': 'grid-expired'
    },
    filterable: {
      messages: {
        info: '',
        isTrue: t('filter_expired_true'),
        isFalse: t('filter_expired_false')
      }
    },
    sortable: false,
    hideable: false,
    template: booleanColumnTemplate('expired'),
    media: '(min-width: 1200px)'
  },
  {
    field: 'is_deleted',
    title: t('deleted_contributions'),
    labelText: t('deleted_contributions'),
    hidden: true,
    filterable: {
      onlyPreset: true
    },
    attributes: {
      'class': 'grid-is-deleted'
    }
  },
  {
    field: 'actions',
    title: ' ',
    attributes: {
      'class': 'grid-actions'
    },
    sortable: false,
    filterable: false,
    hideable: false,
    template:
      `# if (!is_deleted) { #` +
      `# if (actions.analyse) {# <a class="grid-action-analyse" href="#: actions.analyse #"><i class="material-icons">assignment</i> ${t('action_evaluate')}</a> #} #` +
      `# if (actions.rollout && state !== 'unpublished') {# <a class="grid-action-rollout" href="#: actions.rollout #" data-toggle="modal" data-target="\\#rollout_modal"><i class="material-icons">notifications</i> ${t('action_rollout')}</a> #} #` +
      `# if (actions.share) {# <a class="grid-action-share" href="#: actions.share #" data-toggle="modal" data-target="\\#share_contributions_modal"><i class="material-icons">share</i> ${t('action_share')}</a> #} #` +
      `<a class="grid-action-edit" href="#: actions.edit #"><i class="material-icons">edit</i> ${t('action_edit')}</a>` +
      `<a class="grid-action-delete" href="\\#"><i class="material-icons">delete</i> ${t('action_delete')}</a>` +
      `# } else { #` +
        `<a class="grid-action-restore" href="#: actions.restore #"><i class="material-icons">history</i> ${t('action_restore')}</a>` +
      `# } #`
  }
];
