import { t } from './localization';
import { simpleContainsFilterUi, datepickerFilterUi, booleanColumnTemplate, dateColumnTemplate } from '../../grid/configurations/base_column_configuration';

export const manageColumnConfiguration = [
  {
    attributes: {
      'class': 'grid-checkbox'
    },
    selectable: true,
    hideable: false,
    hidden: false,
    width: 50
  },
  {
    field: 'thumbnail',
    title: ' ',
    sortable: false,
    filterable: false,
    hideable: false,
    attributes: {
      'class': 'grid-thumbnail'
    },
    template:
      `<div class="grid-thumbnail-image-wrapper">` +
        `# if (topic.thumbnail) {# <div class="grid-thumbnail-image" style="background-image: url('#: topic.thumbnail #');"></div> #} #` +
      `</div>`
  },
  {
    field: 'title',
    title: t('contribution'),
    attributes: {
      'class': 'grid-title'
    },
    template:
    `#: topic.title #` +
    `<p>#: topic.description #</p>`,
    filterable: {
      operators: {
        string: {
          contains: t('contains')
        }
      },
      ui: simpleContainsFilterUi
    },
    hideable: false
  },
  {
    field: 'author',
    title: t('author'),
    attributes: {
      'class': 'grid-author'
    },
    template:
      `<div class="grid-author-wrapper">` +
        `<div class="user-profile-picture" style="background-image: url(#: topic.user.avatar #)"></div>` +
        `<div class="user-info">` +
          `<a title="#: topic.user.username #" href="#: topic.user.show_url #">#: topic.user.username #</a>` +
        `</div>` +
      `</div>`,
    filterable: {  
      operators: {
        string: {
          contains: t('contains')
        }
      },
      ui: simpleContainsFilterUi
    },
    hideable: true,
    minScreenWidth: 800
  },
  {
    field: 'created_at',
    title: t('created_at'),
    width: 150,
    attributes: {
      'class': 'grid-updated'
    },
    format: '{0:D}',
    filterable: {
      extra: true,
      operators: {
        date: {
          eq: t('is_equal_to'),
          gte: t('is_after_or_equal_to'),
          lte: t('is_before_or_equal_to')
        }
      },
      ui: datepickerFilterUi
    },
    hidden: false,
    hideable: true,
    template: dateColumnTemplate('created_at'),
    minScreenWidth: 1600
  },
  {
    field: 'updated_at',
    title: t('updated_at'),
    width: 150,
    attributes: {
      'class': 'grid-updated'
    },
    format: '{0:D}',
    filterable: {
      extra: true,
      operators: {
        date: {
          eq: t('is_equal_to'),
          gte: t('is_after_or_equal_to'),
          lte: t('is_before_or_equal_to')
        }
      },
      ui: datepickerFilterUi
    },
    hidden: false,
    hideable: true,
    template: dateColumnTemplate('updated_at'),
    minScreenWidth: 1200
  },
  {
    field: 'primary_topicarea',
    title: t('primary_topicarea'),
    width: 250,
    attributes: {
      'class': 'grid-primary-topicarea'
    },
    template: `<span title="#: topic.primary_topic_area_path #">#: topic.primary_topic_area #</span>`,
    hideable: true,
    hidden: false,
    minScreenWidth: 1600
  },
  {
    field: 'evaluation_state',
    title: t('evaluation_state'),
    width: 250,
    attributes: {
      'class': 'grid-state'
    },
    filterable: {
      operators: {
        string: {
          eq: t('eq')
        }
      },
      messages: {
        info: ''
      }
    },
    template: 
    `# if(evaluation_state === 'done') {#` +
      `<i class="material-icons">done</i>${t('state_done')}` +
    `# } else if (evaluation_state === 'pending'){ #` +
      `<i class="material-icons">assignment_late</i>${t('state_pending')}` +
    ` # } else { #` +
      `<i class="material-icons">work</i> ${t('state_new')}` +
    `# } #`,
    hideable: false,
    hidden: false,
    minScreenWidth: 1200
  },
  {
    field: 'topic_areas',
    title: t('topic_areas'),
    hidden: true,
    filterable: {
      operators: {
        string: {
          contains: t('contains')
        }
      }
    }
  },
  {
    field: 'actions',
    title: ' ',
    attributes: {
      'class': 'grid-actions'
    },
    sortable: false,
    filterable: false,
    hideable: false,
    template:
    `# if ( actions.history) { #` +
      `<a class="grid-action-history" href="#: actions.history #" data-target="\\#review_modal" data-toggle="modal"><i class="material-icons">schedule</i> ${t('action_history')}</a>` +
    `# } #` +
    `# if ( actions.manual_assign) { #` +
      `<a class="grid-action-history" href="#: actions.manual_assign #" data-target="\\#review_modal" data-toggle="modal" data-backdrop="static"><i class="material-icons">account_circle</i> ${t('action_manual_assign')}</a>` +
    `# } #`
  }
];
