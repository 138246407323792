import { DataPayload, DataType } from "./dataPayload";

interface ChatMessage extends DataPayload {
  seen: boolean;
}

let translationPathForDataKind = new Map([
  [DataType.SHARESCREEN, 'web_conference.shares_screen'],
  [DataType.CHAT, null],
  [DataType.RAISEDHAND, 'web_conference.has_question'],
  [DataType.DELETERAISEDHAND, null],
  [DataType.JOINEDROOM, 'web_conference.has_entered'],
  [DataType.JOINENQUIRY, 'web_conference.wanna_participate'],
  [DataType.COPYCLIPBOARD, 'web_conference.copy_clipboard'],
  [DataType.ISPRESENTATOR, 'web_conference.has_share_permission'],
  [DataType.ENDRECORDING, 'web_conference.end_recording'],
  [DataType.STARTRECORDING, 'web_conference.start_recording']
]);


export { ChatMessage, translationPathForDataKind };