<template>
  <kendo-dialog
    ref="kendoDialog"
    button-layout="normal"
    width="600"
    @close="onClose"
    :visible="false"
    :title="'Token'">
    <ValidationObserver ref="form">
      <ValidationProvider :name="$t('api_tokens.description')" rules="required|max:255" v-slot="v">
        <div class="form-group is-empty label-floating" :class="{ 'has-error': v.valid === false }">
          <div class="form-group label-floating" :class="{ 'is-empty': description.length === 0 }">
            <label class="control-label">{{ $t('api_tokens.description') }} <small>({{ $t('api_tokens.required') }})</small></label>
            <input type="text" v-model="description" class="form-control">
            <p class="help-block">{{ v.errors[0] }}</p>
          </div>
        </div>
      </ValidationProvider>
      <div class="form-group">
        <div class="radio">
          <label>
            <input type="radio" name="access_type" v-model="accessType" value="read_access">
            {{ $t('api_tokens.access_type_read') }}
          </label>
        </div>
        <div class="radio">
          <label>
            <input type="radio" name="access_type" v-model="accessType" value="write_access">
            {{ $t('api_tokens.access_type_write') }}
          </label>
        </div>
      </div>
    </ValidationObserver>
    <kendo-dialog-action
      v-show="isNewToken"
      :primary="true"
      :text="$t('api_tokens.btn_save')"
      :action="() => createOrUpdateApiToken() && false"></kendo-dialog-action>
  </kendo-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { successMessage } from '../../mixins/flash_message';

export default {
  computed: {
    ...mapGetters({
      showForm: 'apiTokens/showForm',
      token: 'apiTokens/token'
    }),
    description: {
      get() {
        return this.token.description
      },
      set(value) {
        this.$store.commit('apiTokens/UPDATE_DESCRIPTION', value);
      }
    },
    accessType: {
      get() {
        return this.token.access_type
      },
      set(value) {
        this.$store.commit('apiTokens/UPDATE_ACCESS_TYPE', value);
      }
    },
    isNewToken() {
      return this.token.id === null;
    }
  },
  watch: {
    showForm(newValue, _oldValue) {
      if (newValue) {
        this.$refs.form.reset();
        this.$refs.kendoDialog.kendoWidget().open();
      }
    }
  },
  methods: {
    ...mapActions({
      closeForm: 'apiTokens/CLOSE_FORM',
      createApiToken: 'apiTokens/CREATE_API_TOKEN',
      updateApiToken: 'apiTokens/UPDATE_API_TOKEN'
    }),
    onClose() {
      this.closeForm();
    },
    async createOrUpdateApiToken() {
      const success = await this.$refs.form.validate();

      if (success) {
        if (this.isNewToken) {
          await this.createApiToken();
          successMessage(this.$t('api_tokens.api_token_created'));
        } else { 
          await this.updateApiToken();
        }

        this.$refs.kendoDialog.kendoWidget().close();
      }
    }
  }
}
</script>
