import { openDB } from 'idb';
import type { IDBPDatabase } from 'idb';
import Backup from './backup';
import store from './store/index';
import { Actions } from './store/actions';
import type BackupStoreDB from './types/backup_store_db';
import type { BackupStoreDBV1 } from './types/backup_store_db';
import { isFirefox } from './utils/environment';
import { AppState } from './store/state';
import type { Messages } from './i18n';
import type VueI18n from 'vue-i18n';
import NotificationService from './notification_service';

export default class BackupStore {
  private static DATABASE_NAME = 'CooviCaptureBackup';
  private static DATABASE_VERSION = 2;
  private static HEARTBEAT_INACTIVE_AFTER = 5000;

  private static i18n?: VueI18n;

  private static db: IDBPDatabase<BackupStoreDB>;
  private static backup?: Backup;

  public static isBlocked = false;
  public static blockedNotification?: string;
  public static activeUserPermissionRequest = false;

  public static get activeBackup(): Backup | null {
    return this.backup || null;
  }

  private static async requestPersistentStorage(): Promise<boolean> {
    let isUserDecision = false;
    let notificationId: string | undefined;

    const overlayTimeout = setTimeout(() => {
      isUserDecision = true;
      BackupStore.activeUserPermissionRequest = true;

      if (BackupStore.i18n) {
        const text = BackupStore.i18n.t('backup.notification.permissionRequest') as unknown as Messages['backup']['notification']['permissionRequest'];

        notificationId = NotificationService.create({
          title: text.title,
          content: [
            {
              text: text.block1,
            }, {
              text: text.block2,
              expandable: true,
              newBlock: true,
            }, {
              text: text.block3,
              expandable: true,
            }, {
              text: text.block4,
              expandable: true,
              newBlock: true,
            },
          ],
          autoClose: false,
        });
      }
    }, 50);

    const granted = await navigator.storage.persist();

    clearTimeout(overlayTimeout);
    BackupStore.activeUserPermissionRequest = false;

    if (notificationId) {
      NotificationService.remove(notificationId);
    }

    return granted;
  }

  public static async init(i18n?: VueI18n): Promise<void> {
    BackupStore.i18n = i18n;

    BackupStore.db = await openDB<BackupStoreDB>(BackupStore.DATABASE_NAME, BackupStore.DATABASE_VERSION, {
      blocked() {
        BackupStore.isBlocked = true;

        if (BackupStore.i18n) {
          BackupStore.blockedNotification = NotificationService.create({
            content: [
              {
                text: BackupStore.i18n.t('backup.notification.dbInitBlocked') as string,
              },
            ],
            autoClose: false,
          });
        }

      },
      async upgrade(db, oldVersion, newVersion, transaction) {

        if (oldVersion < 1) {
          const v1Db = db as unknown as IDBPDatabase<BackupStoreDBV1>;

          const recordingsStore = v1Db.createObjectStore('recordings', {
            keyPath: 'id',
          });

          recordingsStore.createIndex('byUserId', 'userId');

          const chunksStore = v1Db.createObjectStore('chunks', {
            autoIncrement: true,
          });

          chunksStore.createIndex('byRecordingId', 'recordingId');
        }

        // ToDo: Check what happens if two tabs are open
        if (oldVersion < 2) {
          const recordingsStore = transaction.objectStore('recordings');

          recordingsStore.createIndex('[userId+siteId]', ['userId', 'siteId']);

          // add the siteId 0 to all recordings to work with new index
          let cursor = await recordingsStore.openCursor();
          while (cursor) {
            if (cursor.value.siteId === undefined) {
              cursor.update({
                ...cursor.value,
                siteId: 0,
              });
            }
            cursor = await cursor.continue();
          }

          recordingsStore.deleteIndex('byUserId');
        }

        BackupStore.isBlocked = false;
        if (BackupStore.blockedNotification) {
          NotificationService.remove(BackupStore.blockedNotification);
        }
      },
    });
  }

  public static async checkPersistentStorage(): Promise<void> {
    if (
      !store.state.settings.backupActivated
      || BackupStore.isBlocked
      || this.activeUserPermissionRequest
      || await navigator.storage.persisted()
    ) {
      return;
    }

    const persisted = await BackupStore.requestPersistentStorage();

    // firefox requires the permission to get enough storage space
    // chromium based browsers allow enough space without the permission
    if (!persisted && isFirefox()) {
      store.dispatch(Actions.setSettingBackupActivated, false);

      if (BackupStore.i18n) {
        NotificationService.create({
          content: [{
            text: BackupStore.i18n.t('backup.notification.permissionDeclined') as string,
          }],
        });
      }
    }
  }

  public static async startBackup(): Promise<void> {
    if (
      !store.state.settings.backupActivated
      || BackupStore.isBlocked
      || BackupStore.activeUserPermissionRequest
      || (isFirefox() && !await navigator.storage.persisted())
    ) {
      return;
    }

    if (BackupStore.activeBackup) {
      console.error('An active backup already exist');
      return;
    }

    if (store.state.appState !== AppState.READY) {
      console.error('Application not in READY state');
      return;
    }

    BackupStore.backup = await Backup.createInDB(BackupStore.db);
  }

  public static async loadBackup(): Promise<boolean> {
    if (!store.state.settings.backupActivated) {
      return false;
    }

    if (BackupStore.activeBackup) {
      console.error('An active backup already exist');
      return false;
    }

    const { userId, siteId } = store.state.appData!;

    // At the db update from v1 to v2 all recordings without a siteId got the siteId 0.
    // Remove this getAllFromIndex after a period of time when all backups should include a siteId
    const legacyRecordingsPromise = BackupStore.db.getAllFromIndex('recordings', '[userId+siteId]', [userId, 0]);
    const newRecordingsPromise = BackupStore.db.getAllFromIndex('recordings', '[userId+siteId]', [userId, siteId]);

    const [legacyRecordings, newRecordings] = await Promise.all([legacyRecordingsPromise, newRecordingsPromise]);
    const recordings = [...legacyRecordings, ...newRecordings];

    const time = Date.now() - BackupStore.HEARTBEAT_INACTIVE_AFTER;

    const unfinishedRecordings = recordings.filter((rec) => rec.heartbeat < time);

    if (unfinishedRecordings.length === 0) {
      return false;
    }

    let backupId: string;
    if (unfinishedRecordings.length === 1) {
      backupId = unfinishedRecordings[0].id;
    } else {
      backupId = unfinishedRecordings.sort((a, b) => b.heartbeat - a.heartbeat)[0].id;
    }

    const backup = new Backup(backupId, BackupStore.db);

    if (await backup.getChunksCount() === 0) {
      await backup.destroy();

      return BackupStore.loadBackup();
    }

    BackupStore.backup = backup;

    return true;
  }

  public static async loadBackupById(id: string): Promise<boolean> {
    if (!store.state.settings.backupActivated || BackupStore.isBlocked) {
      return false;
    }

    if (BackupStore.activeBackup) {
      console.error('An active backup already exist');
      return false;
    }

    const recording = await BackupStore.db.get('recordings', id);

    if (!recording) {
      return false;
    }

    const backup = new Backup(id, BackupStore.db);

    if (await backup.getChunksCount() === 0) {
      await backup.destroy();

      return false;
    }

    BackupStore.backup = backup;

    return true;
  }

  public static detachBackup(): Backup | null {
    if (!BackupStore.backup) {
      return null;
    }

    const detachedBackup = BackupStore.backup;
    BackupStore.backup = undefined;

    return detachedBackup;
  }

  public static reset(): void {
    BackupStore.detachBackup()?.destroy();
  }
}
