<template>
  <div class="lesson" :class="{ error: errors.has(validateName) }" v-bind:style="highlighted(lesson)">
    <i class="material-icons draghandle">drag_indicator</i>
    <div class="lesson-type-icon coovi-icon">{{ lessonTypeIcon }}</div>
    <div class="lesson-number">{{lessonNumber}}</div>
    <div class="lesson-label">
      <input
        type="text"
        :name="validateName"
        :placeholder="$t('course.lesson_title')"
        maxlength="75"
        :disabled="isLocked(lesson)"
        :value="lesson.title"
        @input="updatedTitle"
        v-on:focus="focusTitle"
        v-on:blur="unlockLesson(lesson)">
      <div v-if="errors.has(validateName)" class="lesson-error">{{ errors.first(validateName) }}</div>
      <div class="lesson-type">{{ $t(`course.${lessonTypeIcon}`) }}</div>
    </div>
    <div class="lesson-actions">
      <a v-on:click.prevent="isLocked(lesson) ?  null : editLessonFunc(lesson)" href="#" :class="['material-icons', {disabled: isLocked(lesson)}]">edit</a>
      <a v-on:click.prevent="isLocked(lesson) ?  null : removeLesson(lesson)" href="#" :class="['material-icons', {disabled: isLocked(lesson)}]">delete</a>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('course');

export default {
  name: 'lesson',
  props: ['lesson'],
  inject: ['$validator'],
  $_veeValidate: {
    value() {
      return this.lesson;
    },
    name() {
      return this.validateName;
    }
  },
  methods: {
    ...mapActions([
      'changeLessonTitle',
      'removeLesson',
      'editLesson',
      'lockLesson',
      'unlockLesson'
    ]),
    updatedTitle(e) {
      const title = e.target.value;
      const { lesson } = this;

      this.changeLessonTitle({ lesson, title });
      this.$emit('input');
    },
    focusTitle(){
      const { lesson } = this;

      this.lockLesson({
        lesson: lesson,
        userId: _app.info.user.userId
      })
    },
    editLessonFunc(lesson){
      this.editLesson(lesson);
      this.lockLesson({
        lesson: lesson,
        userId: _app.info.user.userId
      })
    },
    isLocked(lesson){
      if (lesson.lockedByUserId != null) {
        return lesson.lockedByUserId != _app.info.user.userId
      } else {
        return false
      }
    },
    highlighted(lesson){
      if (this.isLocked(lesson)){
        let chat = collabCenter.chat;
        let user = lesson.lockedByUserId
        let color = user === chat.owner ? chat.options.ownerColor : chat.options.colors[user % 20];
        return { boxShadow: `0px 0px 3pt 2pt ${color}` };
      } else {
        return '';
      }
    }
  },
  computed: {
    ...mapGetters([
      'lessonGroups',
      'lessonsInGroup'
    ]),
    lessonNumber() {
      const lessonGroups = this.lessonGroups;
      let position = this.lesson.position;

      for (let i = 0; i < lessonGroups.length; i++) {
        if (lessonGroups[i].id === this.lesson.lessonGroupId) {
          break;
        }

        position += this.lessonsInGroup(lessonGroups[i].id).length;
      }

      // Return zero padded position
      return `0${position}`.slice(-2);
    },
    lessonTypeIcon() {
      return this.lesson.type.split('::').pop().split(/(?=[A-Z])/).join('_').toLowerCase();
    },
    validateName() {
      return `lesson_${this.lesson.id}`;
    },
  }
}
</script>

<style lang="scss" scoped>
.lesson {
  display: flex;
  position: relative;
  border: 1px solid $color-light-grey-2;
  border-radius: 3px;
  height: 60px;
  padding: 10px;
  margin: 10px 0;
  align-items: center;
  background-color: $color-white;
  transition: border-color .15s linear;

  &.error {
    border-color: $color-error-red;

    .lesson-type {
      display: none;
    }
  }

  .draghandle {
    font-size: 24px;
    line-height: 60px;
    color: $black-12-opacity;
    padding: 0 10px;
    margin-left: -10px;
    cursor: move;
  }

  .lesson-type-icon {
    color: var(--primary-color);
    font-size: 30px;
  }

  .lesson-number {
    color: $black-54-opacity;
    padding: 0 30px 0 15px;
  }

  .lesson-label {
    flex: 1 auto;

    input {
      background: transparent;
      border: 0;
      outline: none;
      font-size: 16px;
      padding: 0;
      margin: 0;
      line-height: 24px;
      width: 100%;
    }

    .lesson-type {
      font-size: 12px;
      line-height: 14px;
      color: $black-54-opacity;
    }

    .lesson-error {
      font-size: 12px;
      line-height: 14px;
      color: $color-error-red;
    }
  }
}

.lesson-actions {
  display: flex;
  align-items: center;

  > a {
    font-size: 20px;
    padding: 10px;
    color: $black-54-opacity;
    &.disabled{
      color: $black-38-opacity;
      cursor: not-allowed;
    }
    &:not(.disabled){
      &:hover,
      &:focus,
      &:active {
        color: $black-87-opacity;
      }
    }
  }
}
</style>
