import {ParticipantEvent,RoomEvent,Track,Room,Participant,RemoteParticipant, LocalParticipant, RemoteTrackPublication, DataPacket_Kind, MediaDeviceFailure} from 'livekit-client'
import { state } from '../store/state';
import { v4 as uuid } from 'uuid';
import { DataPayload, DataType} from '../entities/dataPayload';
import { EventEmitter } from 'events';
import ConferenceService from './ConferenceService';
import { ModalInstance, ModalLayout, ModalTypes } from '../entities/modalInstance';
import {ConferenceLayout} from '../entities/ConferenceLayout';
import Vue from 'vue';

let currentRoom : Room;
let textDecoder = new TextDecoder;
let eventEmitter = new EventEmitter();
let enc = new TextEncoder();
let timeOut = null;
let manualDisconnect = false;

export default {
  eventEmitter,
  playAudio() {
    currentRoom.startAudio();
  },
  setRoomListeners(room: Room) {
    currentRoom = room;
    room.on(RoomEvent.ParticipantConnected, participantConnected)
    room.on(RoomEvent.ParticipantDisconnected, participantDisconnected);
    room.on(RoomEvent.ActiveSpeakersChanged, handleSpeakerChanged)
    room.on(RoomEvent.Disconnected, handleRoomDisconnect);
    room.on(RoomEvent.Reconnecting, handleReconnecting);
    room.on(RoomEvent.Reconnected, handleReconnected);
    room.on(RoomEvent.TrackSubscribed, handelNewTrackOnRoom )
    room.on(RoomEvent.TrackUnsubscribed, handleRemoveTrackOnRoom)
    room.on(RoomEvent.TrackUnpublished, handleTrackUnpublished)
    room.on(RoomEvent.DataReceived, handleDataReceived);
    room.on(RoomEvent.MediaDevicesError, handleMediaDeviceError)
    room.on(RoomEvent.AudioPlaybackStatusChanged, handleAudioPlaybackStatusChanged);
    room.localParticipant.on(ParticipantEvent.MetadataChanged,handleParticipantMetadataChanged)
    room.localParticipant.on(ParticipantEvent.TrackMuted, handleTrackMuted)

    state.sharedScreenParticipant = null;
    state.roomName = room.name;
    state.localParticipant = room.localParticipant;
    state.participants = Array.from(room.participants.values());  
    setSharedScreenIfShared();

  },
  disconnect() {
    manualDisconnect = true;
    currentRoom.disconnect();
  },

  async shareData(type: DataType, message: string) {
    let messageObject: DataPayload = {
      id: uuid(),
      messageType: type,
      message:message,
      participant: state.localParticipant.identity,
      participant_metadata: state.localParticipant.metadata,
      created_at: new Date()
    }
    
    try {
      state.localParticipant.publishData(
        enc.encode(JSON.stringify(messageObject)),
        DataPacket_Kind.RELIABLE
      );
    }catch(error) {
      let modal = {
        type: ModalTypes.CHAT_ERROR,
        text: VERSTEHE.vueI18n.t('web_conference.error.chat_share_error'),
        headline: VERSTEHE.vueI18n.t('web_conference.error.chat_error_headline'),
        layout: ModalLayout.ACCEPT
      }
      state.modals.push(modal)
    }
    
    
    if(type === DataType.CHAT || type === DataType.SHARESCREEN ||
       type === DataType.RAISEDHAND || type === DataType.JOINEDROOM ||
       type === DataType.JOINENQUIRY || type === DataType.STARTRECORDING ||
       type === DataType.ENDRECORDING){
      if(!state.isBreakout && state.details.save_chat_messages){
        //Save as message if not breakout-Session && conference allows saving
        try {
          await ConferenceService.saveMessage(messageObject);
        }catch(error) {
          let modal = {
            type: ModalTypes.CHAT_ERROR,
            text: VERSTEHE.vueI18n.t('web_conference.error.chat_save_error'),
            headline: VERSTEHE.vueI18n.t('web_conference.error.chat_error_headline'),
            layout: ModalLayout.ACCEPT
          }
          state.modals.push(modal)
        }
      }
    }
    return messageObject
  },

  toOwnNotification(message, type){
    let obj: DataPayload = {
      id: uuid(),
      messageType: type,
      message:message,
      participant: '',
      created_at: new Date()
    };
    inNotification(obj, false);
  },

  leaveBreakout() {
    eventEmitter.emit('switchToMainRoom')
  },
}

function participantConnected(participant: RemoteParticipant) {
  state.participants = Array.from(currentRoom.participants.values());
  clearTimeout(timeOut);
  //sortParticipants(state.participants);
}

function participantDisconnected(participant: RemoteParticipant) {   
  if(participant == state.sharedScreenParticipant) {
    state.sharedScreenParticipant = null;
  }

  if(currentRoom.participants.size == 0) {
    timeOut = setTimeout(() => {
      let modal = {
        type: ModalTypes.INACTIVE,
        text: VERSTEHE.vueI18n.t('web_conference.inactive'),
        headline: VERSTEHE.vueI18n.t('web_conference.inactive_headline'),
        layout: ModalLayout.ACCEPT
      }
      state.modals.push(modal)
    }, 180000)
  }

  state.participants = Array.from(currentRoom.participants.values());
  removeRaisedHand(participant);
}

function handleParticipantMetadataChanged(meta) {
  /*When Participant leaves waitingRoom and enters the conference,
  he has to subscribe to all tracks in the room which already exist.*/
  const oldMeta = JSON.parse(meta);
  const newMeta = JSON.parse(state.localParticipant.metadata);

  if(!oldMeta.inWaitingRoom) {
    return;
  }

  if(newMeta.inWaitingRoom) {
    return;
  }

  for(let participant of state.participants) {
    participant.getTracks().forEach((track) => {
      (track as RemoteTrackPublication).setSubscribed(true);
    });
  }
}

function handleTrackMuted(publication) {
  if(publication.kind == Track.Kind.Audio) {
    state.micActivated = false;
  }
}

function handleAudioPlaybackStatusChanged() {
  state.modals.push({
    type: ModalTypes.ALLOW_AUDIO,
    text: VERSTEHE.vueI18n.t('web_conference.error.allow_audio'),
    headline: VERSTEHE.vueI18n.t('web_conference.error.allow_audio_headline'),
    layout: ModalLayout.ACCEPT
  })
}

function handleRoomDisconnect() {
  if(!state.isBreakout && !manualDisconnect) {
    let modal : ModalInstance = {
      type: ModalTypes.CONNECTION_ERROR,
      text: VERSTEHE.vueI18n.t('web_conference.error.disconnect'),
      headline: VERSTEHE.vueI18n.t('web_conference.error.disconnect_headline'),
      layout: ModalLayout.ACCEPT 
    }
    state.modals.push(modal)
    manualDisconnect = false;
  }
}

function handleSpeakerChanged(speakers: Participant[]) {
  for(let speaker of speakers) {
    let amountParticipantsShowed
    
    switch(state.userSettings.conferenceLayout){
      case ConferenceLayout.Standard:
        amountParticipantsShowed = 5;
        break;
      case ConferenceLayout.DoubleColumn:
        amountParticipantsShowed = 10;
        break;
    }
    let found = state.participants.slice(0, amountParticipantsShowed).filter(participant => participant.identity == speaker.identity || state.localParticipant.identity == speaker.identity)
    if(!found.length) {
      sortParticipants(state.participants);
      break;
    }
  }
}

async function handelNewTrackOnRoom(track, publication, participant) {
  if(track.kind == Track.Kind.Video){
    sortParticipants(state.participants);
  }
  
  if(track && track.source === "screen_share"){
    eventEmitter.emit('screenFromOtherParticipant')
    state.sharedScreenParticipant = participant;
  } 
}

function handleRemoveTrackOnRoom(publication, participant) {
  sortParticipants(state.participants);
}

function handleTrackUnpublished(track, participant) {
  if(track.source === 'screen_share'){
    if(state.sharedScreenParticipant.identity != state.localParticipant.identity){
      state.sharedScreenParticipant = null;
    }
  }
}

function handleMediaDeviceError(error) {
  MediaDeviceFailure.getFailure(error)
}

function handleDataReceived(payload, participant, kind) {
  //We have to check if it is possible to share corrupted files
  let payloadJSON :DataPayload = JSON.parse(textDecoder.decode(payload as any));

  switch(payloadJSON.messageType) {
    case DataType.CHAT:
      inChatMessages(payloadJSON);
      break;
    case DataType.SHARESCREEN:
      inChatMessages(payloadJSON)
      inNotification(payloadJSON, false);
      break;
    case DataType.RAISEDHAND:
      inChatMessages(payloadJSON)
      state.raiseHandNotifications.push(payloadJSON);
      inNotification(payloadJSON, false);
      break;
    case DataType.DELETERAISEDHAND:
      removeRaisedHand(participant);
      break;
    case DataType.JOINEDROOM:
      inChatMessages(payloadJSON)
      inNotification(payloadJSON, false);
      break;
    case DataType.JOINENQUIRY:
      inChatMessages(payloadJSON)
      inNotification(payloadJSON, true);
      break;
    case DataType.ISPRESENTATOR:
      inChatMessages(payloadJSON)
      inNotification(payloadJSON, false)
      break;
    case DataType.ENDRECORDING:
      state.isRecording = false;
      inChatMessages(payloadJSON)
      inNotification(payloadJSON, false);
      break;
    case DataType.STARTRECORDING:
      state.isRecording = true;
      inChatMessages(payloadJSON)
      inNotification(payloadJSON, false);
      break;
    case DataType.BREAKOUTSTARTED:
      eventEmitter.emit('switchToBreakout', payloadJSON.message)
      break;
    case DataType.BREAKOUTSTOPED:
      eventEmitter.emit('switchToMainRoom')
      break;
  }
}

async function inChatMessages(message) {
  message.seen = false;
  state.chatMessages.push(message);
}

async function inNotification(notification, ableToAccept) {
  notification.ableToAccept = ableToAccept;
  state.notifications.push(notification);
}

function removeRaisedHand(participant) {
  for(let i = 0; i < state.raiseHandNotifications.length; i++){
    if(state.raiseHandNotifications[i].participant === participant.identity){
      state.raiseHandNotifications.splice(i, 1);
    }
  }
}

function handleReconnecting() {
  let modal: ModalInstance = {
    type: ModalTypes.RECONNECTING,
    text: VERSTEHE.vueI18n.t('web_conference.reconnecting'),
    headline: VERSTEHE.vueI18n.t('web_conference.reconnecting_header'),
    layout: ModalLayout.SPINNER 
  }
  state.modals.push(modal)
}

function handleReconnected() {
  const removeIndex = state.modals.findIndex(modal => modal.type === ModalTypes.RECONNECTING);
  state.modals.splice(removeIndex, 1);
}

function setSharedScreenIfShared() {
  currentRoom.participants.forEach((participant) => {
    participant.videoTracks.forEach((track) => {
      if(track.trackName === "screen"){
        state.sharedScreenParticipant = participant;
      } 
    })    
  });
}

function sortParticipants(participants: Participant[],localParticipant?: LocalParticipant) {
  participants.sort((a, b) => {
    // loudest speaker first
    if (a.isSpeaking && b.isSpeaking) {
      return b.audioLevel - a.audioLevel;
    }

    // speaker goes first
    if (a.isSpeaking !== b.isSpeaking) {
      if (a.isSpeaking) {
        return -1;
      } else {
        return 1;
      }
    }

    // last active speaker first
    if (a.lastSpokeAt !== b.lastSpokeAt) {
      const aLast = a.lastSpokeAt?.getTime() ?? 0;
      const bLast = b.lastSpokeAt?.getTime() ?? 0;
      return bLast - aLast;
    }

    // video on
    const aVideo = a.videoTracks.size > 0;
    const bVideo = b.videoTracks.size > 0;
    if (aVideo !== bVideo) {
      if (aVideo) {
        return -1;
      } else {
        return 1;
      }
    }
    // joinedAt
    return (a.joinedAt?.getTime() ?? 0) - (b.joinedAt?.getTime() ?? 0);
  });

  if (localParticipant) {
    const localIdx = participants.indexOf(localParticipant);
    if (localIdx >= 0) {
      participants.splice(localIdx, 1);
      if (participants.length > 0) {
        participants.splice(1, 0, localParticipant);
      } else {
        participants.push(localParticipant);
      }
    }
  }
}
