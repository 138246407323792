import EventEmitter from '../common/event_emitter';
import RecordingService from '../recording/recorder';

export class Recorder extends EventEmitter {
  constructor () {
    super();

    this.recorder = RecordingService.recorderInstance;
    this._state = 'unloaded';
    this.recordedVideo = null;
    this.autoOpen = true;

    this.recorder.on('loaded', () => {
      this.trigger('loaded');
      // Open recorder, as soon as the application is loaded
      if (this.autoOpen && this.recorder.record()) {
        this.state = 'recording';
      }

      if (this.launchButton) {
        this.launchButton.classList.remove('disabled');
      }
    });

    this.recorder.on('exit', () => {
      if (this.state === 'editing') {
        this.launchButton.classList.remove('disabled');
        return;
      }

      if (this.state !== 'uploading') {
        this.state = 'idle';
      }

    });

    this.recorder.on('closed', () => {
      this.state = 'unloaded';
    });

    this.recorder.on('record-success', recordedVideo => {
      this.recordedVideo = recordedVideo;
      this.state = 'uploading';
      this.trigger('record-success', this.recordedVideo.take.id);
    });
  }

  set state (state) {
    this.trigger('state-change', state);
    return this._state = state;
  }

  get state () {
    return this._state;
  }

  get amorId () {
    return this.recordedVideo ? this.recordedVideo.amor.id : null;
  }

  get autoOpen () {
    // Make sure we only open the recorder automatically if started from
    // within the "new video" dialogue
    return this._autoOpen && document.body.classList.contains('video-creator-visible');
  }

  set autoOpen (value) {
    this._autoOpen = value;
  }

  loadAmor (amorId) {
    return new Promise((resolve, reject) => {
      this.recorder.getAmor(amorId).then(recordedVideo => {
        this.recordedVideo = recordedVideo;
        resolve(this.recordedVideo);
      }).catch(reject);
    });
  }

  start () {
    if (this.isReady() && this.recorder.record()) {
      this.state = 'recording';
    } else {
      this.recorder.launch();

      if (this.launchButton) {
        this.launchButton.classList.add('disabled');
      }
    }
  }

  resetRecorder () {
    this.recorder.resetRecorder();
  }

  isReady () {
    return this.recorder.isReady();
  }

  setLaunchButton (launchButton) {
    this.launchButton = launchButton;
    this.recorder.setOptions({ setupButton: false, origin: null });
    return this.recorder.setLaunchButton(launchButton);
  }
}
