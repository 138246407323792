<template>
  <div class="lesson-group" :class="{ error: errors.has(validateName) }" v-bind:style="highlighted(lessonGroup)">
    <div class="lesson-group-header">
      <i class="material-icons draghandle">drag_indicator</i>
      <div class="lesson-group-label">
        <input
          :placeholder="$t('course.lesson_group_placeholder')"
          :value="lessonGroup.title"
          @input="updatedTitle"
          :disabled="isLocked(lessonGroup)"
          v-on:focus="focusTitle"
          v-on:blur="unlockLessonGroup(lessonGroup)">
        <div class="lessons-count">{{ $tc('course.lessons_count', lessonsCount) }}</div>
      </div>

      <div class="lesson-group-actions">
        <a v-if="lessonsCount > 0" v-on:click.prevent="toggleExpand" class="material-icons" href="#">{{isExpanded ? 'expand_less' : 'expand_more'}}</a>
        <a v-on:click.prevent="isLocked(lessonGroup) ? null : deleteLessonGroup()" href="#" :class="['material-icons', {disabled: isLocked(lessonGroup)}]">delete</a>
      </div>
    </div>

    <collapse-transition :duration="200">
      <draggable v-if="isExpanded" v-model="lessons" class="lesson-group-lessons" v-bind="dragOptions" @change="onDraggableChange">
        <transition-group>
          <lesson
            v-for="lesson in lessons"
            v-validate="'valid_lesson|accessible_content'"
            :key="lesson.id"
            :lesson="lesson"></lesson>
        </transition-group>
      </draggable>
    </collapse-transition>

    <div class="lesson-group-add">
      <button type="button" v-on:click="addLesson" class="btn btn-default btn-flat">{{$t('course.add_lesson')}}</button>
      <button type="button" v-on:click="addLessonGroup" class="btn btn-default btn-flat">{{$t('course.add_lesson_group')}}</button>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { mapActions } from 'vuex';
import { CollapseTransition } from 'vue2-transitions';
import { confirmDialog } from '../../../common/dialog';
import draggable from 'vuedraggable';
import lesson from './lesson.vue';
import Vue from 'vue';
import { v4 as uuid } from 'uuid';

const { mapGetters } = createNamespacedHelpers('course');

export default {
  name: 'lesson-group',
  inject: ['$validator'],
  props: ['lessonGroup'],
  $_veeValidate: {
    value() {
      return {
        ...this.lessonGroup,
        lessonsCount: this.lessonsCount
      };
    },
    name() {
      return this.validateName;
    }
  },
  components: {
    lesson,
    draggable,
    CollapseTransition
  },
  data: () => {
    return {
      isExpanded: true
    }
  },
  methods: {
    ...mapActions({
      changeTitle: 'course/changeLessonGroupTitle',
      updateLessonPosition: 'course/updateLessonPosition',
      createLessonGroup: 'course/createLessonGroup',
      removeLessonGroup: 'course/removeLessonGroup',
      newLesson: 'course/newLesson',
      addDeletedLesson: 'course/addDeletedLesson',
      processGroupSwitchForLesson: 'course/processGroupSwitchForLesson',
      lockLessonGroup: 'course/lockLessonGroup',
      unlockLessonGroup: 'course/unlockLessonGroup'
    }),
    updatedTitle(e) {
      const title = e.target.value;
      const { lessonGroup } = this;

      this.changeTitle({ lessonGroup, title });
    },
    focusTitle(){
      const { lessonGroup } = this;

      this.lockLessonGroup({
        lessonGroup: lessonGroup,
        userId: _app.info.user.userId
      })
    },
    isLocked(lessonGroup){
      if (lessonGroup.lockedByUserId != null) {
        return lessonGroup.lockedByUserId != _app.info.user.userId
      } else {
        return false
      }
    },
    highlighted(lessonGroup){
      if (this.isLocked(lessonGroup)){
        let chat = collabCenter.chat;
        let user = lessonGroup.lockedByUserId
        let color = user === chat.owner ? chat.options.ownerColor : chat.options.colors[user % 20];
        return { boxShadow: `0px 0px 3pt 2pt ${color}` };
      } else {
        return '';
      }
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
    addLesson() {
      this.newLesson({ lessonGroupId: this.lessonGroup.id });
    },
    addLessonGroup() {
      this.createLessonGroup({ position: this.lessonGroup.position + 1, id: uuid() });
    },
    deleteLessonGroup() {
      if (this.lessonsCount > 0) {
        confirmDialog().then(() => this.removeLessonGroup(this.lessonGroup), () => {});
      } else {
        this.removeLessonGroup(this.lessonGroup);
      }
    },
    onDraggableChange(event) {
      if (event.added) {
        this.processGroupSwitchForLesson({ newLessonId:  uuid(), lessonId: event.added.element.id, lessonGroupId: this.lessonGroup.id });
      } else if (event.removed && event.removed.element.oldId) {
        if (!(typeof event.removed.element.oldId === 'string')) {
          this.addDeletedLesson({ lesson: event.removed.element, lessonGroupId: this.lessonGroup.id });
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'isAnythingLocked'
    ]),
    lessonsCount() {
      return this.lessons.length;
    },
    dragOptions() {
      return {
        disabled: this.isAnythingLocked,
        handle: '.draghandle',
        group: { name: 'lessons', pull: true, put: true },
        animation: 100
      }
    },
    lessons: {
      get() {
        return this.$store.getters['course/lessonsInGroup'](this.lessonGroup.id);
      },
      set(lessons) {
        lessons.forEach((lesson, index) => {
          this.updateLessonPosition({ lesson, position: index + 1, lessonGroupId: this.lessonGroup.id });
        });
      }
    },
    validateName() {
      return `lesson_group_${this.lessonGroup.id}`;
    }
  }
};
</script>

<style lang="scss" scoped>
  .lesson-group-lessons > span {
    display: block;
    min-height: 20px;
  }

  .lesson-group-add {
    text-align: center;
  }

  .lesson-group-header {
    display: flex;
    border-bottom: 1px solid $color-light-grey-2;
  }

  .lesson-group-label {
    flex: 1 auto;

    input {
      width: 100%;
      background: transparent;
      border: 0;
      outline: none;
      font-size: 24px;
    }
  }

  .lessons-count {
    line-height: 20px;
    margin-bottom: 8px;
    color: $black-54-opacity;
  }

  .lesson-group.error .lessons-count {
    color: $color-error-red;
    font-weight: 400;
  }

  .lesson-group-actions {
    display: flex;
    align-items: center;
    padding-right: 11px;

    > a {
      font-size: 20px;
      padding: 10px;
      color: $black-54-opacity;
      &.disabled{
        color: $black-38-opacity;
        cursor: not-allowed;
      }
      &:not(.disabled){
        &:hover,
        &:focus,
        &:active {
          color: $black-87-opacity;
        }
      }
    }
  }

  .draghandle {
    font-size: 24px;
    line-height: 60px;
    color: $black-12-opacity;
    padding: 0 10px;
    cursor: move;
  }
</style>
