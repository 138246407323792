export function init() {
  this.component = new CommentMentions();
}

export class CommentMentions {

  constructor() {
    this.myLocals = {};
    VERSTEHE.i18n_bridge.translate(['helpers.links.save', 'helpers.links.cancel', 'helpers.links.answer', 'comments.comment_not_found', 'comments.comment_could_not_be_updated'], (locals) => {
      this.myLocals = locals;
    });
    this.setListeners();
    this.initPage();
    this.refreshComments();
  };

  setListeners() {
    $(document).on('click', '.create-answer', (e) => {
      e.preventDefault();
      this.setCreateAnswerListeners(e);
    });

    this.getMentionInputs('textarea.get-mention', null)

    $(document).on('click', 'textarea', function (e) {
      let $textarea = $(e.target);
      let top = $textarea.get(0).scrollTop;
      $textarea.get(0).parentElement.parentElement.getElementsByClassName('mentions')[0].getElementsByTagName('div')[0].style.height = $textarea.get(0).offsetHeight + 'px';
      $textarea.get(0).parentElement.parentElement.getElementsByClassName('mentions')[0].getElementsByTagName('div')[0].scrollTop = top;
      $('div.mentions').click()
    })

    $('textarea.get-mention').on('scroll', function (e) {
      let $textarea = $(e.target);
      let top = $textarea.get(0).scrollTop;
      $textarea.get(0).parentElement.parentElement.getElementsByClassName('mentions')[0].getElementsByTagName('div')[0].scrollTop = top;
    })

    $(document).on('ajax:success', '.delete-comment', () => { this.refreshComments(); });

    $(document).on('click', '.edit-comment', (e) => {
      e.preventDefault();

      this.setEditCommentListener(e);
    });


    $('section.comments .paginate a').on('ajax:success', (e, result) => {
      if (result.status == 200) {
        this.buildComments(result.data);
      }
    });


    $('.new-comment form').on('ajax:before', function (e) {
      $(e.target).find('button.btn').button('loading');

      $('.comment-input-field .form-control').mentionsInput('val', (text) => {
        this.oldText = $('textarea.get-mention').val();
        $('textarea.get-mention').val(text);
        $('textarea.get-mention').addClass('transparent')
      });

    });

    $('.new-comment form').on('ajax:error', () => {
      $('textarea.mention').val(this.oldText);
      $('textarea.mention').removeClass('transparent')
    });

    $('.new-comment form').on('ajax:success', (e, result) => {
      $(e.target).find('textarea').val('');
      $('textarea.get-mention').removeClass('transparent')
      const mentions = document.getElementsByClassName('mentions')[0];
      while (mentions.firstChild) {
        mentions.removeChild(mentions.lastChild);
      }
      let divEl = document.createElement('div');
      mentions.appendChild(divEl);
      this.refreshComments();
      $('.dislike-notification').hide();
    });
  }

  setEditCommentListener(e) {
    let $wrapper = $(e.target).closest('.comment-body').find('.text');

    $wrapper.find('.edit').html(
      '<form action="' + $(e.target).data('url') + '">' +
      '<textarea class="form-control edit-mention" name="comment[text]">' + $wrapper.find('.view').html() + '</textarea>' +
      '<button type="submit" class="btn btn-primary btn-xxs">' + this.myLocals['helpers.links.save'] + '</button>' +
      '<button type="button" class="cancel btn btn-default btn-xxs">' + this.myLocals['helpers.links.cancel'] + '</button>' +
      '</form>'
    );

    $wrapper.find('.view, .edit').toggle();
    let $form = $wrapper.find('form');

    $wrapper.on('click', 'button.cancel', function () {
      $wrapper.find('.edit').html('').hide();
      $wrapper.find('.view').show();
    });

    let parsedText = this.parseHrefToMention($wrapper.find('.view').html())

    this.getMentionInputs('.edit-mention', parsedText)
    this.updateInputDiv(document.getElementsByClassName('edit-mention')[0])

    // update defaultvalue-text without @
    $wrapper.find('textarea').focus();
    $wrapper.find('textarea').val($wrapper.find('.view').text())
    $wrapper.find('textarea').trigger('input');

    $form.on('submit', (e) => {

      e.preventDefault();
      let commentText;
      $('.edit-mention').mentionsInput('val', function (text) {
        commentText = text;
      });

      $.ajax({
        url: $form.attr('action'),
        method: 'PUT',
        data: { comment: { text: commentText } },
        success: (data) => {
          $(e.target).find('textarea').val('');
          this.refreshComments();
        },
        error: (xhr) => {
          if (xhr.status === 404) {
            // Comment was probably deleted in the meantime
            $.snackbar({ content: this.myLocals['comments.comment_not_found'], style: 'error', duration: 4000 });
            this.refreshComments();
          } else {
            $.snackbar({ content: this.myLocals['comments.comment_could_not_be_updated'], style: 'error', duration: 4000 });
          }
        }
      });

    });
  }

  setCreateAnswerListeners(e) {
    let $wrapper = $(e.target).closest('.comment');

    let answerForm = $(
      '<form class="comment-answer-form" data-remote="true" method="POST" action="' + $(e.target).data('url') + '">' +
      '<textarea class="form-control mention" name="comment[text]"></textarea>' +
      '<button type="submit" class="btn btn-primary btn-xxs">' + this.myLocals['helpers.links.answer'] + '</button>' +
      '<button type="button" class="answer-cancel btn btn-default btn-xxs">' + this.myLocals['helpers.links.cancel'] + '</button>' +
      '</form>'
    );
    $wrapper.after(answerForm);
    $.material.init();

    answerForm.find('.answer-cancel').on('click', function () {
      answerForm.remove();
    });

    answerForm.on('ajax:success', (e, xhr) => {
      this.refreshComments();
    });

    answerForm.on('ajax:error', function (e, xhr) {
      let errorMessage = xhr.statusText;
      $.snackbar({ content: errorMessage, style: 'error', duration: 4000 });
    });

    answerForm.find('textarea').focus();

    this.getMentionInputs('.comment-answer-form .mention', null);
    this.updateInputDiv(document.getElementsByClassName('mention')[0])

    answerForm.on('ajax:before', function () {
      $('textarea.mention').mentionsInput('val', (text) => {
        this.oldText = $('textarea.mention').val();
        $('textarea.mention').val(text);
        $('textarea.mention').addClass('transparent');
      });
    });
  }

  refreshCounter(count) {
    $('.voting-comment').find("span").text(count);
  }

  refreshComments() {
    $.ajax({
      url: $('section.comments .comment-list').data('url'),
      type: 'GET',
      success: (result) => {
        if (result.status == 200) {
          let commentCount = document.querySelector('.content-comments .caption');

          if (commentCount && result.data.total_comments) {
            commentCount.innerHTML = result.data.total_comments;
          }

          $('section.comments .comment-list').html('');
          this.buildComments(result.data);
        }
      }
    });
  };

  initPage() {
    $.ajax({
      url: $('#infinite-scrolling').data('url'),
      type: 'GET',
      success: (result) => {
        $('#infinite-scrolling').html(result);

        if ($('#infinite-scrolling').length > 0) {
          return this.bindScrolling();
        }
      }
    });
  };

  buildComments(data) {
    let template = Handlebars.compile($('#comment-template').text());
    let comments = data.comments;
    let current_page = parseInt(data.current_page);
    let total_pages = data.total_pages;
    let total_comments = data.total_comments;
    let comment_list = '';
    this.refreshCounter(total_comments);

    for (let i = 0; i < comments.length; i++) {
      let comment = comments[i];
      comment_list += template({ comment: comment });
    }

    $('section.comments .comment-list').append(comment_list).slideDown();

    if (total_pages > 1) {
      if (current_page < total_pages) {
        $('#infinite-scrolling a.next_page')
          .removeClass('disabled')
          .attr('href', $('section.comments .comment-list').data('url') + '?page=' + (current_page + 1));
        $(window).unbind("scroll");
        this.bindScrolling();
      } else {
        $('#infinite-scrolling a.next_page')
          .addClass('disabled')
          .attr('href', '#');
        $(window).unbind("scroll");
      }
    }
    $('.new-comment form button.disabled, section.comments a.disabled').button('reset');

    $('.block-heading .comment-counter').text(total_comments);
  };

  bindScrolling() {
    $(window).bind("scroll", () => {
      let more_comments_url;
      more_comments_url = $('#infinite-scrolling a.next_page').attr('href');
      if (more_comments_url && $(window).scrollTop() > $(document).height() - $(window).height() - 200) {
        $(window).unbind("scroll");
        $('.ajaxloader-holder').show();
        $.ajax({
          url: more_comments_url,
          type: 'GET',
          success: (result) => {
            if (result.status == 200) {
              $('.ajaxloader-holder').hide();
              this.buildComments(result.data);
            }
          }
        });
      }
    });
  }

  getMentionInputs(element, parsedText) {
    return $(element).mentionsInput(
      {
        onDataRequest: function (mode, query, callback) {
          $.ajax({
            url: $('section.comments .comment-list').data('url') + '/mentions',
            method: 'GET',
            data: { query: query },
            success: function (data) {
              callback.call(this, data);
            },

          });

        },
        minChars: 0,
        showAvatars: false,
        allowRepeat: true,
        elastic: false,
        defaultValue: parsedText ? parsedText : ''
      });
  }

  parseHrefToMention(text) {
    let linkRx = /<a href="(?<userId>(.*?))">(?<userName>(.*?))<\/a>/g;
    let found = text.replace(linkRx, function (match, b, c, d, e, f, g, group) {
      let usrId = group['userId'].split('/');
      usrId = usrId[usrId.length - 1];
      return '@[' + group.userName + '](contact:' + usrId + ')';
    })
    return found;
  }

  updateInputDiv(div) {
    div.addEventListener('scroll', function (e) {
      let top = div.scrollTop;
      div.parentElement.parentElement.getElementsByClassName('mentions')[0].getElementsByTagName('div')[0].scrollTop = top;
    })
  }
}
