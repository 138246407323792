import { t } from '../configurations/localization';
import { BaseFilterColumn } from '../../grid/filter/base_filter_column';

export class FilterColumn extends BaseFilterColumn {
    /**
   * @constructor
   * @param {Object} kendoColumn - Column object from Kendo UI library
   */
  constructor (kendoColumn, filter) {
    super(kendoColumn, filter);
  }

  /**
   * Remove the filter for this column
   */
  reset () {
    // check if the current filter a custom meta data filter
    let customUi = this.filterUi(this.field);

    if (customUi) {
      customUi.setChecked([]);
    }

    this.filters = null;
    this.filterMenu.clear();
    this.removeFilterLabel();
  }

  /**
   * Create & prepare the filter label for display in filter input
   */
  setupFilterLabel () {
    this.filterLabel = document.createElement('div');
    this.filterLabel.className = 'grid-filter-label';

    let filterLabelInner = document.createElement('div');
    filterLabelInner.className = 'grid-filter-label-inner';

    let filterText = document.createElement('span');
    let filterReset = document.createElement('i');
    filterReset.className = 'material-icons';
    filterReset.innerText = 'clear';

    filterReset.addEventListener('click', () => this.reset());

    this.filterLabel.addEventListener('click', e => {
      if (!e.target.isEqualNode(filterReset)) {
        if (!this.filters.find(filter => filter.readOnly)) {
          this.filter.fieldSelect.close();
          let customUi = this.filterUi(this.filters[0].field);
          if (customUi) {
            this.filterLabelCustomUI(customUi);
          } else {
            this.open();
          }
          
        }
      }
    });

    filterLabelInner.appendChild(filterText);
    filterLabelInner.appendChild(filterReset);
    this.filterLabel.appendChild(filterLabelInner);
  }

  /**
   * checks the active filter elements in the tree
   * @param {*} customUi 
   */
  filterLabelCustomUI(customUi) {
    let checkedIds = this.isActive() ? this.filters[0].value.split(',').map(Number) : [];
    customUi.setChecked(checkedIds);
    this.open();
  }

  /**
   * Build the label text for filter input
   * e.g. "Created by contains 'Test'"
   */
  filterLabelText () {
    return new Promise((resolve, reject) => {
      let customUi = this.filterUi(this.field);
      // Use selected option text for boolean filters
      if (this.filterMenu.type === 'boolean') {
        return this.filters[0].value === 'true' ? this.filterable.messages.isTrue : this.filterable.messages.isFalse;
      }

      if (this.filters[0].field === 'has_access') {
        if (this.filters[0].value === 'true') {
          resolve(t('filter_with_access'));
        } else {
          resolve(t('filter_without_access'));
        }
      }

      if (this.kendoColumn.labelText) {
        resolve(this.kendoColumn.labelText);
      }

      let text = this.title + " ";
      text += this.filters.map(filter => {
        let operator = this.filterOperator(filter.operator);
        let value = filter.value;

        if (this.filterMenu.type === 'date') {
          value = kendo.parseDate(value);
        }

        if (this.kendoColumn.format) {
          value = kendo.format(this.kendoColumn.format, value);
        }

        if (filter.field === 'role') {
          value = this.filterLabelTextRole(filter);
        }

        if (customUi) {
          let ids = filter.value.split(',').map(Number);
          text = this.getMetaDataLabel(ids, customUi).then((selectedItems) => {
            value = selectedItems.map(selectedItem => selectedItem.title).join(", ");
            return `${this.title}  ${operator} '${value}'`;
          });

          resolve(text);
        }

        return `${operator} '${value}'`;
      }).join(` ${t('and')} `);
      
      resolve(text);
    });
  }

  /**
   * Builds the label text for the role filter
   * @param {*} filter 
   */
  filterLabelTextRole(filter) {
    let singleFilter = filter.value.split(',');
    let value = "";
    value += singleFilter.map(v => {
      return _app.roles.find(role => role.id.toString() === v).name;
    }).join(', ');

    return value;
  }

  /**
   * promise to get the elements from the MetadataTrees data attribute
   * if the data attribute is not loaded yet it will bind a eventlistener
   * to get the elements as soon as the data attribute is loaded
   */
  getMetaDataLabel(filterIds, customUi) {
    return new Promise((resolve, reject) => {
      if (customUi.data) {
        resolve(this.getSelectedItemsfromIds(filterIds, customUi));
      } else {
        customUi.once("dataLoaded", () => {
          resolve(this.getSelectedItemsfromIds(filterIds, customUi));
        });
      }
    });
  }

  /**
   * gets the tree items by the ids
   * @param {*} filterIds collection of selected ids
   * @param {*} customUi the initalized custom meta data filter
   */
  getSelectedItemsfromIds(filterIds, customUi) {
    let selectedItems = [];

    filterIds.forEach((id) => {
      selectedItems.push(customUi.getItemById(id));
    });

    return selectedItems;
  }

  /**
   * Opens the filter popup
   */
  open () {
    let customUi = this.filterUi(this.field);
    if (customUi) {
      return $(customUi._dialog).modal();
    } else {
      this.popup.open();
      return this.popupStyling();
    }
    
  }

  /**
   * check if the current field a custom meta data field
   * @param {String} field the field name 
   */
  filterUi(field) {
    if (this.filter.treeFilters) {
      return this.filter.treeFilters.find( f => f.type === field);
    } else {
      return undefined;
    }
  }
}
