import Join from './components/join.vue';
import Conference from './components/conference.vue';
import {state} from './store/state';
import RoomService from './services/RoomService';
export const routes = [
    {
      path: '/',
      name: 'Join',
      component: Join
    },
    {
      path: '/conference',
      name: 'conference',
      component: Conference,
      beforeEnter(to, from, next) {
        if(from.name == null) {
          //If somebody wants to enter the Web_Conference Directly he will be redirected to the join page
          //He has to enter our room with a dom interaction - else the browser could block the video start and the user
          //won't see any videoStream.
          next('/');
        }else {
          next();
        }
      }
    },
  ];
  
  export default routes;
  