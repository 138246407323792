import { t, tt } from './localization';
import { flashMessage } from '../mixins/flash_message';
import { Datepicker } from '../globals/datepicker';
import { PrivateSelect } from '../contributions/private_select';
import { ExportGrid } from '../grid/export_grid';

export class BulkActions {
  constructor (grid) {
    this.grid = grid;

    this.wrapper = grid.gridElement.querySelector('.contribution-grid-bulk-actions');
    this.countLabel = grid.gridElement.querySelector('.bulk-action-item-count');
    this.actionsMessage = grid.gridElement.querySelector('.bulk-actions-message');
    this.selectAll = this.wrapper.querySelector('.bulk-actions-select-all');
    this.closeAction = this.wrapper.querySelector('.bulk-actions-close');
    this.cancelAction = this.wrapper.querySelector('.bulk-actions-cancel');
    this.saveAction = this.wrapper.querySelector('.bulk-actions-save');
    this.shareAction = this.wrapper.querySelector('.bulk-share');
    this.cloneAction = this.wrapper.querySelector('.bulk-clone');
    this.validUntilAction = this.wrapper.querySelector('.bulk-change-valid-until');
    this.deleteAction = this.wrapper.querySelector('.bulk-delete');
    this.exportAction = this.wrapper.querySelector('.bulk-export');
    this.editorWrappers = {
      featured: this.wrapper.querySelector('.bulk-action-edit[data-attribute="featured"]'),
      owner: this.wrapper.querySelector('.bulk-action-edit[data-attribute="owner"]'),
    };

    this.selectedContributions = [];

    this.initFeatureAction();
    this.initOwnerAction();
    this.bindListeners();
  }

  selected () {
    return this.selectedContributions;
  }

  updateSelection (contributions) {
    this.selectedContributions = contributions;
    this.countLabel.innerText = contributions.length;

    if (contributions.length > 0) {
      this.wrapper.classList.add('opened');
    } else {
      this.closeEdit();
      this.wrapper.classList.remove('opened');
    }

    let totalItems = this.grid.dataSource.total();

    // Hide select all action when all contributions are selected
    if (contributions.length !== totalItems) {
      let totalItemsLabel = this.selectAll.querySelector('.bulk-action-total-items');
      totalItemsLabel.innerHTML = totalItems;
      this.selectAll.style.display = 'block';
    } else {
      this.selectAll.style.display = 'none';
    }
  }

  openEdit (attribute) {
    this.activeAttribute = attribute;
    this.editorWrappers[attribute].classList.add('opened');
    this.wrapper.classList.add('editing');
  }

  closeEdit () {
    let editors = Object.values(this.editorWrappers);

    for (let i = 0; i < editors.length; i++) {
      editors[i].classList.remove('opened');
    }

    this.activeAttribute = null;
    this.wrapper.classList.remove('editing');
  }

  initFeatureAction () {
    let wrapper = this.editorWrappers.featured;
    let select = wrapper.querySelector('select');

    this._featuredSelect = $(select).kendoDropDownList().data('kendoDropDownList');
  }

  initOwnerAction () {
    let wrapper = this.editorWrappers.owner;
    let select = wrapper.querySelector('select');

    this._ownerSelect = $(select).kendoDropDownList({
      filter: 'contains',
      minLength: 2,
      enforceMinLength: true,
      dataTextField: 'username',
      dataValueField: 'id',
      noDataTemplate: t('find_users'),
      dataSource: {
        serverFiltering: true,
        transport: {
          read: (options) => {
            let filter = '';
            let url = select.getAttribute('data-url');

            if (options.data.filter && options.data.filter.filters.length > 0) {
              filter = options.data.filter.filters[0].value;
            }

            if (filter.length > 1) {
              $.ajax({
                url: url,
                data: {
                  q: filter
                },
                success: options.success,
                error: options.error
              });
            } else {
              options.success([]);
            }
          }
        }
      }
    }).data('kendoDropDownList');
  }

  showBulkMessage (message) {
    clearTimeout(this._actionsMessageTimeout);
    this.actionsMessage.classList.add('fade-in');
    this.actionsMessage.innerHTML = message;

    this._actionsMessageTimeout = setTimeout(() => {
      this.actionsMessage.classList.remove('fade-in');
    }, 8000);
  }

  bulkDelete (contributions) {
    let removeFromList = response => {
      contributions.forEach(token => {
        this.grid.removeFromList(token);
        this.showBulkMessage(tt('bulk_delete_message', response));
      });
    };
    if (!confirm(tt('bulk_delete_confirm', { count: this.selected().length }))) {
      return;
    }

    $.ajax({
      url: this.deleteAction.href,
      method: 'POST',
      data: {
        ids: contributions
      },
      success: (response) => {
        removeFromList(response);
      },
      error: (xhr) => {
        if (parseInt(xhr.status, 10) === 409) {
          let modal = $('#contribution_destroy_strategy');

          $.ajax({
            url: this.deleteAction.getAttribute('data-conflict'),
            data: {
              ids: contributions
            },
            success: data => {
              modal.find('.modal-content').html(data);

              modal.find('form').on('ajax:success', (e, response) => {
                removeFromList(response);
                modal.modal('hide');
              });

              modal.modal('show');
            }
          });
        }
      }
    });
  }

  bulkShare () {
    $.ajax({
      url: this.shareAction.href,
      data: {
        ids: this.selected()
      },
      success: (response) => {
        VERSTEHE.utils.showModal('#share_contributions_modal', response);
      }
    });
  }

  bulkClone () {
    $.ajax({
      url: this.cloneAction.href,
      data: {
        ids: this.selected()
      },
      success: (response) => {
        VERSTEHE.utils.showModal('#copy_strategy', response);
        let modal = $('#copy_strategy');
        let copyBtn =  modal.find('.copy-btn');
        let self = this;
        copyBtn.on('click', ()=>{
          flashMessage(VERSTEHE.vueI18n.tc('globals.contributions.copy_process', this.selected().length) + "\n \n \r \r\n ! " + VERSTEHE.vueI18n.t("globals.contributions.not_public"))
          $.ajax({
            url: "clone_topic",
            type: 'POST',
            data: {
              selected_site: selected_site,
              ids: copyBtn.attr('data-values')
            },
            success: () => {
              self.grid.kendoGrid.refresh();
              self.grid.dataSource.read();
            }
          });
        });
      }
    });
  }

  initValidAction () {
    $.ajax({
      url: this.validUntilAction.href,
      data: {
        ids: this.selected()
      },
      success: (response) => {
        VERSTEHE.utils.showModal('#valid_until_strategy', response);
        let modal = $('#valid_until_strategy');
        modal.addClass('modal-open valid-until-strategy-modal-open');
        let copyBtn =  modal.find('.update-btn');
        let self = this;

        this.container = modal.find('#change-multiple-valid-until-container');
        this.privateSelectWrap = modal.find('.private-select-wrapper');
        this.pickerField = modal.find(`.multiple-datepicker`);
        this.initValue = this.pickerField.attr('value');
        this.maxValidity = this.pickerField.data('maxvalidity');
        this.datepicker = new Datepicker("multiple", this.container, { "max": this.getMaxDate(), "min": this.getMinDate(), "noValueField": true, "value": this.getValue(), "minToDayDiff": true });
        this.datepicker.datepicker.bind('change', () =>{
          if(this.datepicker.getValue()){
            copyBtn.removeClass('disabled');
          }else{
            copyBtn.addClass('disabled')
          }
        })
        this.privateSelect = new PrivateSelect();
        this.initStateSelect();
        copyBtn.on('click', ()=>{
          $.ajax({
            url: "update_valid_until",
            type: 'POST',
            data: {
              valid_until: this.datepicker.getValue(),
              visibility: this.currentVisibility,
              ids: copyBtn.attr('data-values'),
              private_selected: this.getPrivateSelected()
            },
            success: (response) => {
              if(response?.failed_contents){
                let failedText = VERSTEHE.vueI18n.t("globals.contributions.update_failed");
                for(let i = 0; i< response.failed_contents.length > 0; i++){
                  failedText += response.failed_contents[i].title
                  if(i !== response.failed_contents.length - 1){
                    failedText += ", "
                  }
                }
                flashMessage(failedText,'error', 20000)
              }
              self.grid.kendoGrid.refresh();
              self.grid.dataSource.read();
            }
          });
        });
      }
    });
  }

  getPrivateSelected(){
    let values = {private_users: [], private_target_groups: []}
    this.privateSelect.privateSelect.value().map(val => {
      const splitted = val.split(', ');
      if (splitted[1] === 'user'){
        values.private_users.push(splitted[0])
      } else{
        values.private_target_groups.push(splitted[0])
      }
    });
    return values;
  }

  initStateSelect() {
    this.privateSelect.enable(false);
    const radioButtons = document.querySelectorAll('.contribution-publish-visibility input[type="radio"');
    radioButtons.forEach((button) => {
      if (button.checked) {
        this.currentVisibility = button.value;
      }
      button.addEventListener('change', (e) => {
        this.stateSelectChange(e.target.value);
      });
    });
  }

  stateSelectChange(visibility) {
    this.currentVisibility = visibility;
    switch(visibility) {
      case 'private':
        this.privateSelect.enable(true);
        this.privateSelectWrap && this.privateSelectWrap.find('.control-label').each((i, el) => {
          $(el).removeClass('disabled');
        });
        break;
      default:
        this.privateSelect.enable(false);
        this.privateSelectWrap && this.privateSelectWrap.find('.control-label').each((i,el) => {
          $(el).addClass('disabled');
        });
      }
  }

  getMinDate() {
    let minDate = new Date().setHours(0,0,0,0);

    if(this.initValue !== '' && this.initValue != '0') {
      let validUntil = new Date(this.initValue);

      if (validUntil < minDate) {
        minDate = validUntil;
      }
    }

    return new Date(minDate);
  }

  getValue() {
    let value = null;

    if(this.initValue !== null && this.initValue !== undefined) {
      value = new Date(this.initValue);
    }

    return value;
  }

  getMaxDate() {
    let max = null;

    if(this.maxValidity !== '' && this.maxValidity != '0') {
      max = moment(new Date()).add(this.maxValidity, 's').toDate();
    }

    return max;
  }

  bulkChange (action) {
    this.saveAction.classList.add('disabled');

    // Map requested action to function
    action = {
      featured: 'bulkChangeFeatured',
      owner: 'bulkChangeOwner'
    }[action];

    if (action) {
      this[action]()
        .then((response, ids) => {
          this.closeEdit();
          this.showBulkMessage(tt('bulk_change_message', response));
          this.saveAction.classList.remove('disabled');

          // Update grid data
          if (response.field_update) {
            this.selected().forEach(id => {
              this.grid.updateRow(id, response.field_update);
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  bulkChangeFeatured () {
    return new Promise((resolve, reject) => {
      let url = this.editorWrappers.featured.getAttribute('data-url');
      let value = this._featuredSelect.value();

      $.ajax({
        url: url,
        method: 'POST',
        data: {
          ids: this.selected(),
          featured: value
        },
        success: resolve,
        error: reject
      });
    });
  }

  bulkChangeOwner () {
    return new Promise((resolve, reject) => {
      let url = this.editorWrappers.owner.getAttribute('data-url');
      let userId = this._ownerSelect.value();

      if (userId && userId.length > 0) {
        $.ajax({
          url: url,
          method: 'POST',
          data: {
            ids: this.selected(),
            user_id: userId
          },
          success: resolve,
          error: reject
        });
      } else {
        reject();
      }
    });
  }

  bulkExport() {
    let exportGrid = new ExportGrid();
    let columns = "";
    let activeColumns = this.getActiveColumns();
    columns += exportGrid.exportHeaderLine(this.grid.kendoGrid.columns, activeColumns);

    $.ajax({
      url: "bulk/export_contributions",
      method: 'GET',
      data: {
        ids: this.selected()
      },
      success: (result) => {
        result.contributions.forEach(item => {
          if(item){
            activeColumns.forEach((column,idx) =>{
              if(column === 'author'){ // instead of author the user is used for this column
                columns += item['user']['username'];
              }else if(column === 'topic_area_list'){
                columns += item[column];
              }else if(column === 'visibility'){
                columns += t("visibility_"+item['state']);
              }else if(item && item[column] && (column === 'created_at' || column === 'updated_at' || column === 'expired_at')){
                columns += exportGrid.getDateString(item[column]);
              }
              else{
                (item && item[column] === null) ? "" : columns += item[column];
              }
              if(idx != activeColumns.length -1){
                columns += ";";
              }
            })
            columns += "\n";
          }
        });
        exportGrid.exportGrid(columns, 'contribution_export');
      }
    });
  }

  getActiveColumns(){
    let activeColumns= ["title"]; // title has no hidden attribute and is always visible
    this.grid.kendoGrid.columns.forEach((column)=>{
      // column author and updated_at have hidden:undefined on first start
      if(column.hidden === false && column.field || column.field && column.hidden === undefined && (column.field === 'author' || column.field === 'updated_at')){
        activeColumns.push(column.field);
      }
    });
    return activeColumns;
  }

  bindListeners () {
    this.closeAction.addEventListener('click', e => {
      e.preventDefault();
      this.wrapper.classList.remove('opened');

      // Deselect all
      this.grid.kendoGrid._selectedIds = {};
      this.grid.kendoGrid.clearSelection();
    });

    this.cancelAction.addEventListener('click', e => {
      e.preventDefault();
      this.closeEdit();
    });

    this.wrapper.querySelectorAll('.bulk-edit').forEach(action => {
      action.addEventListener('click', e => {
        e.preventDefault();

        let attribute = action.getAttribute('data-attribute');
        this.openEdit(attribute);
      });
    });

    this.shareAction.addEventListener('click', e => {
      e.preventDefault();
      this.bulkShare();
    });

    this.cloneAction?.addEventListener('click', e => {
      e.preventDefault();
      this.bulkClone();
    });

    this.deleteAction.addEventListener('click', e => {
      e.preventDefault();
      this.bulkDelete(this.selected());
    });

    this.saveAction.addEventListener('click', e => {
      this.bulkChange(this.activeAttribute);
    });

    this.validUntilAction.addEventListener('click', e => {
      this.initValidAction();
    });

    this.selectAll.addEventListener('click', e => {
      this.grid.selectAll();
      this.selectAll.style.display = 'none';
    });

    this.exportAction && this.exportAction.addEventListener('click', e => {
      e.preventDefault();
      this.bulkExport();
    });
  }
}
