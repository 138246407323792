import { state } from './store/state';
import type { UserSettings } from './store/state';

const baseLsKey = 'web_conferences_settings';

function lsKey() {
  return `${baseLsKey}_${state.userDetails!.id}`;
}

export async function getUserSettingsFromLS(): Promise<Partial<UserSettings>> {
  const lsValue = window.localStorage.getItem(lsKey());

  if (!lsValue) {
    return {};
  }

  try {
    const settings = JSON.parse(lsValue) as Partial<UserSettings>;

    const enumerateDevices = await navigator.mediaDevices.enumerateDevices();
    const deviceExists = (deviceId: string) => enumerateDevices.find((device: MediaDeviceInfo) => device.deviceId === deviceId) !== undefined;

    if (settings.selectedMic && !deviceExists(settings.selectedMic)) {
      settings.selectedMic = undefined;
    }

    if (settings.selectedCam && !deviceExists(settings.selectedCam)) {
      settings.selectedCam = undefined;
    }

    if (settings.selectedAudio && !deviceExists(settings.selectedAudio)) {
      settings.selectedAudio = undefined;
    }

    return settings;
  } catch (e) {
    if (e instanceof Error && e.name === 'SyntaxError') {
      console.error('Stored Settings could not be parsed:', e.message);
    } else {
      throw e;
    }
  }

  return {};
}

export function saveUserSettingsToLS(userSettings: UserSettings): void {
  window.localStorage.setItem(lsKey(), JSON.stringify(userSettings));
}
