<template>
<div class="lesson-topic">
  <div :class="['lesson-media', { 'no-detail': !detail }]">
    <no-access v-if="!lessonDetail.subject.hasAccess"/>

    <video-player
      v-if="mediaType === 'film'"
      ref="videoPlayer"
      :options="videoOptions"
      :screenplay="screenplay"
      :thumbnails="thumbnails"
      :tracking="tracking"
      :sources="sources"
      :annotations="annotations"
      @progress="$emit('progress', $event)"
      @loadedmetadata="onLoadedMetadata"></video-player>

    <slideshow-player
      v-if="mediaType === 'slideshow'"
      ref="slideshowPlayer"
      :slides="sources"
      :tracking="tracking"
      @progress="$emit('progress', $event)"></slideshow-player>
  </div>

  <div class="lesson-detail" v-if="detail">
    <p class="lesson-description">{{ currentLesson.description }}</p>

    <div class="lesson-subject">
      <h3 class="lesson-subject-title">{{ lessonDetail.subject.title }}</h3>
      <a :href="lessonDetail.subject.authorUrl" class="lesson-subject-author">
        <div class="lesson-subject-author-avatar" :style="{ backgroundImage: `url(${lessonDetail.subject.authorAvatar})` }"></div>
        <div class="lesson-subject-author-name">{{ lessonDetail.subject.author }}</div>
      </a>
      <p class="lesson-subject-description">{{ lessonDetail.subject.description }}</p>
    </div>
  </div>
</div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('coursePerform');

import VideoPlayer from '../../../../globals/vue/video_player.vue';
import SlideshowPlayer from '../../../../globals/vue/slideshow_player.vue';
import NoAccess from './no_access.vue';

export default {
  name: 'topic-lesson',
  props: {
    detail: {
      default: true
    },
    embedMode: {
      default: false
    }
  },
  components: {
    VideoPlayer,
    SlideshowPlayer,
    NoAccess
  },
  computed: {
    ...mapGetters([
      'currentLesson'
    ]),
    lessonDetail() {
      return this.currentLesson.detail;
    },
    lessonProgress() {
      return this.currentLesson.progress;
    },
    videoOptions() {
      return {
        fluid: false,
        autoplay: true,
        playbackRates: [0.5, 1, 1.25, 1.5, 2]
      }
    },
    mediaType() {
      return this.lessonDetail.subject.mediaType;
    },
    sources() {
      return this.lessonDetail.subject.sources;
    },
    thumbnails() {
      return this.embedMode ? null : this.lessonDetail.subject.thumbnails;
    },
    screenplay() {
      return this.lessonDetail.subject.screenplay;
    },
    annotations() {
      return this.lessonDetail.subject.annotations;
    },
    tracking() {
      return this.embedMode ? null : this.lessonDetail.subject.progressUrl;
    }
  },
  mounted() {
    if (this.mediaType === 'slideshow' && this.lessonProgress > 0 && this.lessonProgress < 1) {
      const page = Math.round(this.$refs.slideshowPlayer.totalPages * this.lessonProgress);
      this.$refs.slideshowPlayer.setSlide(page);
    }
  },
  methods: {
    onLoadedMetadata() {
      if (this.lessonProgress > 0 && this.lessonProgress < 1) {
        const time = this.$refs.videoPlayer.duration * this.lessonProgress;
        this.$refs.videoPlayer.setInitialTime(time);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.lesson-media {
  height: calc(100vh - 60px);
}

.lesson-media.no-detail {
  height: calc(100vh);
}

::v-deep .video-player {
  height: 100%;

  .video-js {
    width: 100%;
    height: 100%;
    padding: 0;
  }
}

::v-deep .document-player {
  height: 100%;

  .slides {
    // Need to override height from Ideal-Image-Slider-JS
    height: calc(100% - 36px) !important;
  }
}
</style>
