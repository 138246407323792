import Vue from 'vue';
import axios from 'axios';
import { strictEqual } from 'assert';

export const initialState = {
  url: '',
  loaded: false,
  tokens: [],
  documentationUrl: '',
  showForm: false,
  token: {
    id: null,
    description: '',
    access_type: 'read_access'
  }
};

export const Actions = {
  INIT: 'apiTokens/INIT'
};

export default {
  namespaced: true,
  state: initialState,
  getters: {
    documentationUrl(state) {
      return state.documentationUrl;
    },
    loaded(state) {
      return state.loaded;
    },
    tokens(state) {
      return state.tokens;
    },
    token(state) {
      return state.token;
    },
    showForm(state) {
      return state.showForm;
    }
  },
  mutations: {
    INIT(state, { url, documentationUrl }) {
      state.url = url;
      state.documentationUrl = documentationUrl;
    },
    SET_TOKENS(state, tokens) {
      Vue.set(state, 'tokens', tokens);
      state.loaded = true;
    },
    FORM_VISIBILITY(state, show) {
      state.showForm = show;
    },
    EDIT_TOKEN(state, tokenId) {
      if (tokenId) {
        state.token = {
          ...state.tokens.find(token => token.id === tokenId)
        };
      } else {
        state.token = {
          id: null,
          description: '',
          access_type: 'read_access'
        };
      }
    },
    UPDATE_DESCRIPTION(state, description) {
      state.token.description = description;
    },
    UPDATE_ACCESS_TYPE(state, accessType) {
      state.token.access_type = accessType;
    },
    ADD_API_TOKEN(state, token) {
      state.tokens.push(token);
    },
    UPDATE_API_TOKEN(state, { id, token }) {
      const index = state.tokens.findIndex(t => t.id === id);
      state.tokens.splice(index, 1, {
        ...token
      });
    },
    DELETE_API_TOKEN(state, token) {
      state.tokens.splice(state.tokens.indexOf(token), 1);
    }
  },
  actions: {
    async INIT({ commit }, { url, documentationUrl }) {
      commit('INIT', { url, documentationUrl });
      const response = await axios.get(url);
      commit('SET_TOKENS', response.data);
    },
    async CREATE_API_TOKEN({ commit, state }) {
      const tokenAttributes = {
        description: state.token.description,
        access_type: state.token.access_type
      };
      const response = await axios.post(state.url, tokenAttributes);

      commit('ADD_API_TOKEN', response.data);
      return response.data;
    },
    async UPDATE_API_TOKEN({ commit, state }) {
      const tokenAttributes = {
        description: state.token.description,
        access_type: state.token.access_type
      };
      const response = await axios.patch(state.token.update, tokenAttributes);

      commit('UPDATE_API_TOKEN', { id: state.token.id, token: response.data });
    },
    async DELETE_API_TOKEN({ commit }, token) {
      await axios.delete(token.delete);
      commit('DELETE_API_TOKEN', token);
    },
    OPEN_FORM({ commit }, tokenId = null) {
      commit('EDIT_TOKEN', tokenId);
      commit('FORM_VISIBILITY', true);
    },
    CLOSE_FORM({ commit }) {
      commit('FORM_VISIBILITY', false);
    }
  }
};
