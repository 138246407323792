import { createLocalAudioTrack, createLocalVideoTrack, LocalAudioTrack, LocalParticipant, LocalVideoTrack, Track } from "livekit-client";
import { ConferenceSettings } from "../entities/ConferenceSettings";
import { state } from '../store/state';

let disableCamButton = false;
let disableMicButton = false;


export default {

  setCameraAndMicWhenJoining() {
    if(state.userSettings.selectedMic && state.micActivated) {
      state.localParticipant.setMicrophoneEnabled(true);
    }else {
      state.micActivated = false;
    }
    
    if(state.userSettings.selectedCam && state.camActivated) {
      state.localParticipant.setCameraEnabled(true);
    }else {
      state.camActivated = false;
    }
  },

  async changeAudioTrack() {
    let audioTracks = state.localParticipant.getTracks().filter(track => track.kind === Track.Kind.Audio);
    if(audioTracks.length > 0){
      await (audioTracks[0].track as LocalAudioTrack).restartTrack({
        deviceId: state.userSettings.selectedMic,
        echoCancellation: true,
        noiseSuppression: true
      })     
    }else {
      let localAudioTrack = await createLocalAudioTrack({
        deviceId: state.userSettings.selectedMic,
        echoCancellation: true,
        noiseSuppression: true
      })
      
      if(!state.micActivated) {
        localAudioTrack.mute();
      } 

      await state.localParticipant.publishTrack(localAudioTrack);
    }
  },

  async changeVideoTrack() {
    //Does a video track exist which isnt the shared screen?
    let videoTracks = state.localParticipant.getTracks().filter(track => track.kind === Track.Kind.Video && track.source !== 'screen_share');
    if(videoTracks.length > 0){
      try{
        await (videoTracks[0].track as LocalVideoTrack).restartTrack({
          deviceId: state.userSettings.selectedCam
        })
        state.showCameraError = false;
      }catch(error) {
        (videoTracks[0].track as LocalVideoTrack).restartTrack();
        this.cameraError(error);
      }
    }else {
      try {
        let localVideoTrack = await createLocalVideoTrack({deviceId: state.userSettings.selectedCam, resolution: {
          width: ConferenceSettings.videoWidthPx ,
          height: ConferenceSettings.videoHeightPx,
          frameRate: ConferenceSettings.frameRate,
        }})
        
        if(!state.camActivated) {
          localVideoTrack.mute();
        }
        
        await state.localParticipant.publishTrack(localVideoTrack, {
          simulcast: true
        });
        state.showCameraError = false;
      }catch(error) {
        this.cameraError(error);
      }
    }
  },

  async changeLocalVideoTrack() {
    try {
      let tr = await createLocalVideoTrack({
        deviceId: state.userSettings.selectedCam,
        resolution: {
          width: ConferenceSettings.videoWidthPx,
          height: ConferenceSettings.videoHeightPx,
          frameRate: ConferenceSettings.frameRate,
        }
      });
      state.localVideoTrack = tr;
      this.appendVideoElement(state.localVideoTrack);

      if(!state.camActivated) {
        state.localVideoTrack.mute()
      }

      state.showCameraError = false;
    }catch(error) {
      this.cameraError(error);
    }
  },

  cameraError(error) {
    state.showCameraError = true;
    setTimeout(() => {
      state.showCameraError = false;
    }, 10000)
  },

  async changeLocalAudioTrack() {
    let tr = await createLocalAudioTrack({
      deviceId: state.userSettings.selectedMic,
      echoCancellation: true,
      noiseSuppression: true
    });
    state.localAudioTrack = tr;
  },

  async toggleMic() {
    if(this.disableMicButton) {
      return;
    }
    this.disableMicButton = true;
    try {
      const status = !state.micActivated
      await state.localParticipant.setMicrophoneEnabled(status);
      state.micActivated = status
    }catch(error) {
      console.log(error);
    }        
    this.disableMicButton = false; 
  },

  async toggleCam() {
    if(this.disableCamButton) {
      return;
    }
    this.disableCamButton = true;
    try {
      const status = !state.camActivated
      await state.localParticipant.setCameraEnabled(status)
      state.camActivated = status
    }catch(error) {
      console.log(error);
    }
    this.disableCamButton = false;
  },

  async toggleMicJoinPage() {
    if(state.localAudioTrack) {
      state.micActivated = !state.micActivated;
      if (state.localAudioTrack.isMuted) {
        let tr = await createLocalAudioTrack({
          deviceId: this.selectedCam,
          echoCancellation: true,
          noiseSuppression: true
        });
        state.localAudioTrack = tr;
      } else {
        state.localAudioTrack.mute()
      }
    }
  },

  async toggleCamJoinPage() {
    if(state.localVideoTrack){
      state.camActivated = !state.camActivated
      if (state.localVideoTrack.isMuted) {
        document.getElementById('videoContainer').innerHTML = ``;
        let tr = await createLocalVideoTrack({
          deviceId: state.userSettings.selectedCam,
          resolution: {
            width: ConferenceSettings.videoWidthPx ,
            height: ConferenceSettings.videoHeightPx,
            frameRate: ConferenceSettings.frameRate,
          }
        });
        state.localVideoTrack = tr;
        this.appendVideoElement(state.localVideoTrack)
      } else {
        state.localVideoTrack.mute()
      }
    }
  },

  appendVideoElement(element) {
    document.getElementById('videoContainer').innerHTML = ``;
    let videoElement = element.attach();
    videoElement.style.height = '100%';
    videoElement.style.width = '100%';
    videoElement.style.position = 'relative';
    videoElement.style.borderRadius = '8px';
    videoElement.style.transform = 'scale(-1,1)'
    document.getElementById('videoContainer').appendChild(videoElement);
  }
}

