import { MutationTree } from 'vuex';
import { QuizManageState, QuizGeneralState } from './state';
import Vue from 'vue';
import { Answer, Question } from '../entities';
import { MatchingOption, matchingOptionSchema } from '../entities/matching_option';
import { DragAndDropOption } from '../entities/drag_and_drop_option';

export const Mutations = {
  UPDATE_ENTITIES: 'UPDATE_ENTITIES',
  SET_QUIZ: 'SET_QUIZ',
  SET_QUESTION_IDS: 'SET_QUESTION_IDS',
  SET_CURRENT_QUESTION: 'SET_CURRENT_QUESTION',
  CLEAR_QUESTION_IMG: 'CLEAR_QUESTION_IMG',
  SET_QUESTION_IMG: 'SET_QUESTION_IMG',
  SET_ANSWER_IMG: 'SET_ANSWER_IMG',
  SET_QUESTION_ATTRIBUTES: 'SET_QUESTION_ATTRIBUTES',
  SET_ANSWER_ATTRIBUTES: 'SET_ANSWER_ATTRIBUTES',
  SET_MATCHING_ANSWER_ATTRIBUTES: 'SET_MATCHING_ANSWER_ATTRIBUTES',
  SET_ANSWER_IDS: 'SET_ANSWER_IDS',
  ADD_ANSWER: 'ADD_ANSWER',
  ADD_MATCHING_ANSWER: 'ADD_MATCHING_ANSWER',
  ADD_MATCHING_OPTION: 'ADD_MATCHING_OPTION',
  SET_MATCHING_OPTION: 'SET_MATCHING_OPTION',
  DELETE_ANSWER: 'DELETE_ANSWER',
  DELETE_MATCHING_ANSWER: 'DELETE_MATCHING_ANSWER',
  DELETE_QUESTION: 'DELETE_QUESTION',
  ADD_QUESTION: 'ADD_QUESTION',
  UPDATE_ARRANGEMENT_SOLUTIONS: 'UPDATE_ARRANGEMENT_SOLUTIONS',
  SET_MODE: 'SET_MODE',
  SET_GENERAL_ATTRIBUTES: 'SET_GENERAL_ATTRIBUTES',
  SET_GENERAL_IMG: "SET_GENERAL_IMG",
  SET_QUESTION_EDITORS: "SET_QUESTION_EDITORS",
  REFRESH_QUESTION_POSITIONS: "REFRESH_QUESTION_POSITIONS",
  SET_STATE: 'SET_STATE',
  INITIALIZE: 'INITIALIZE',
  SET_DRAG_AND_DROP_OPTIONS: "SET_DRAG_AND_DROP_OPTIONS",
  UPDATE_DRAG_AND_DROP_OPTION: "UPDATE_DRAG_AND_DROP_OPTION",
}

export const mutations: MutationTree<QuizManageState> = {
  [Mutations.UPDATE_ENTITIES] (state, entities) {
    Object.assign(state.entities, entities);
  },

  [Mutations.SET_QUIZ] (state, payload: { id: EntityId, questions: EntityId[], general: QuizGeneralState, urls: any, isNew: boolean }) {
    state.quiz = payload.id;
    state.questions = payload.questions;
    state.general = payload.general;
    state.urls = payload.urls;
    state.isNew = payload.isNew;
  },

  [Mutations.SET_QUESTION_IDS] (state, questionIds: EntityId[]) {
    state.questions = questionIds;
  },

  [Mutations.SET_ANSWER_IDS] (state, payload: { questionId: EntityId, answerIds: EntityId[], schema: string }) {
    state.entities.questions[payload.questionId].answers = payload.answerIds.map(id => ({ id: id, schema: payload.schema }));
  },

  [Mutations.UPDATE_ARRANGEMENT_SOLUTIONS] (state, questionId: EntityId) {
    state.entities.questions[questionId].answers.forEach(answerObject => {
      state.entities[answerObject.schema][answerObject.id].solution = state.entities.questions[questionId].answers.indexOf(answerObject);
    });
  },

  [Mutations.SET_CURRENT_QUESTION] (state, questionId: EntityId) {
    state.currentQuestion = questionId;
  },

  [Mutations.SET_QUESTION_EDITORS] (state, payload: { questionId: EntityId, editor: number }) {
    Vue.set(state.questionEditors, payload.editor, payload.questionId);
  },

  [Mutations.CLEAR_QUESTION_IMG] (state, questionId: EntityId) {
    state.entities.questions[questionId].image = null;
    state.entities.questions[questionId].imageUpload = true;
  },

  [Mutations.SET_QUESTION_IMG] (state, payload: { questionId: EntityId, fileObject: { fileName: string, file: string } }) {
    state.entities.questions[payload.questionId].image = payload.fileObject.file;
    state.entities.questions[payload.questionId].imageFileName = payload.fileObject.fileName;
    state.entities.questions[payload.questionId].imageUpload = true;
  },

  [Mutations.SET_ANSWER_IMG] (state, payload: { answerId: EntityId, fileObject: { fileName: string, file: string } }) {
    state.entities.answers[payload.answerId].image = payload.fileObject.file;
    state.entities.answers[payload.answerId].imageFileName = payload.fileObject.fileName;
    state.entities.answers[payload.answerId].imageUpload = true;
  },

  [Mutations.SET_GENERAL_IMG] (state, fileObject: { fileName: string, file: string }) {
    state.general.image = fileObject.file;
    state.general.imageFileName = fileObject.fileName;
    state.general.imageUpload = true;
  },

  [Mutations.SET_QUESTION_ATTRIBUTES] (state, payload: { id: EntityId, attributes: any }) {
    Object.assign(state.entities.questions[payload.id], payload.attributes);
  },

  [Mutations.SET_ANSWER_ATTRIBUTES] (state, payload: { id: EntityId, attributes: any }) {
    Object.assign(state.entities.answers[payload.id], payload.attributes);
  },

  [Mutations.SET_MATCHING_ANSWER_ATTRIBUTES] (state, payload: { id: EntityId, attributes: any }) {
    Object.assign(state.entities.matchingAnswers[payload.id], payload.attributes);
  },

  [Mutations.SET_GENERAL_ATTRIBUTES] (state, attributes: any) {
    Object.assign(state.general, attributes);
  },

  [Mutations.ADD_ANSWER] (state, answer: Answer) {
    Vue.set(state.entities.answers, answer.id, answer);
    state.entities.questions[answer.questionId].answers.push({ id: answer.id, schema: 'answers' });
  },

  [Mutations.ADD_MATCHING_ANSWER] (state, answer: Answer) {
    Vue.set(state.entities.matchingAnswers, answer.id, answer);
    state.entities.questions[answer.questionId].answers.push({ id: answer.id, schema: 'matchingAnswers' });
  },

  [Mutations.DELETE_ANSWER] (state, answerId: EntityId) {
    Vue.set(state.entities.answers[answerId], 'deleted', true);
  },

  [Mutations.DELETE_MATCHING_ANSWER] (state, answerId: EntityId) {
    Vue.set(state.entities.matchingAnswers[answerId], 'deleted', true);
  },

  [Mutations.DELETE_QUESTION] (state, questionId: EntityId) {
    Vue.set(state.entities.questions[questionId], 'deleted', true);
  },

  [Mutations.ADD_QUESTION] (state, question: Question) {
    Vue.set(state.entities.questions, question.id, question);
    state.questions.push(question.id);
  },

  [Mutations.SET_MODE] (state, mode) {
    state.mode = mode;
  },

  [Mutations.ADD_MATCHING_OPTION] (state, option: MatchingOption) {
    Vue.set(state.entities.matchingOptions, option.id, option);
  },

  [Mutations.SET_MATCHING_OPTION] (state, payload: { id: EntityId, text: string }) {
    state.entities.matchingOptions[payload.id].text = payload.text;
  },

  [Mutations.REFRESH_QUESTION_POSITIONS] (state, questionIds: Array<EntityId>) {
    for (let i = 0; i < questionIds.length; i++) {
      state.entities.questions[questionIds[i]].position = i + 1;
    }
  },

  [Mutations.SET_STATE] (state, payload) {
    Object.assign(state, payload);
  },

  [Mutations.INITIALIZE] (state, { entities, quiz }) {
    Object.assign(state.entities, entities);
    state.quiz = quiz.id;
    state.questions = quiz.questions;
    state.general = quiz.general;
    state.urls = quiz.urls;
    state.isNew = quiz.isNew;
  },

  [Mutations.SET_DRAG_AND_DROP_OPTIONS] ({ entities: { answers, dragAndDropOptions } }, { answerId, options }: { answerId: EntityId, options: Array<DragAndDropOption>}) {
    const optionIds = [];

    options.forEach(option => {
     Vue.set(dragAndDropOptions, option.id, { ...option });
     optionIds.push(option.id);
    });

    Vue.set(answers[answerId], 'dragAndDropOptions', optionIds);
  },

  [Mutations.UPDATE_DRAG_AND_DROP_OPTION] (state, { optionId, attributes }) {
    Object.assign(state.entities.dragAndDropOptions[optionId], attributes);
  }
}
