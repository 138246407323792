import { nanoid } from 'nanoid';
import logger from '../logger/logger';

type Recording = { type: string, data: Blob, backupId?: string };

async function registerServiceWorker(): Promise<boolean> {
  try {
    await navigator.serviceWorker.register('/assets/service_worker.js', { scope: '/' });
    return true;
  } catch (e) {
    logger.error({
      message: 'Failed to register service worker',
      error: e as Error,
      section: 'service_worker_controller:registerServiceWorker',
    });
    return false;
  }
}

function waitForServiceWorker() {
  return new Promise<void>((resolve) => {
    if (navigator.serviceWorker.controller) {
      // A serviceWorker is already registered and active.
      resolve();
    } else {
      navigator.serviceWorker.addEventListener('controllerchange', () => resolve());
    }
  });
}

export async function upload(recording: Recording): Promise<string | null> {
  try {
    const registered = await registerServiceWorker();

    if (!registered) {
      return null;
    }

    await waitForServiceWorker();

    const id = nanoid(5);

    navigator.serviceWorker.controller!.postMessage({
      type: 'capture/ADD_RECORDING',
      id,
      recording,
    });

    return id;
  } catch (e) {
    logger.error({
      message: 'Failed to upload to service worker',
      error: e as Error,
      section: 'service_worker_controller:upload',
    });
    return null;
  }
}

export async function download(id: string): Promise<Recording> {
  return new Promise(async (resolve) => {
    await registerServiceWorker();
    await waitForServiceWorker();

    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.data.type === 'capture/GET_RECORDING' && event.data.id === id) {
        resolve(event.data.recording);
      }
    });

    navigator.serviceWorker.controller!.postMessage({
      type: 'capture/GET_RECORDING',
      id,
    });
  });
}
