<template>
  <div class="integration-settings">
    <div class="integration-settings-heading">
      <button @click="$router.go(-1)" type="button" class="btn btn-default btn-flat">
        <i class="material-icons">chevron_left</i>
      </button>
      <h2>{{ $t('integrations.integrations') }} / {{ title }}</h2>
    </div>
    <div class="integration-settings-body">
      <ValidationObserver ref="validator">
        <component :is="id.replace('_', '-')" :id="id" ref="form">
          <div class="integration-settings-actions">
            <button @click="$router.go(-1)" type="button" class="btn btn-default btn-flat">{{ $t('integrations.cancel') }}</button>
            <button @click="apply" type="button" class="btn btn-primary">{{ $t('integrations.apply') }}</button>
          </div>
        </component>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';
  const { mapGetters, mapActions } = createNamespacedHelpers('integrations');

  import IdentityOkta from './settings/identity_okta.vue';
  import IdentitySaml from './settings/identity_saml.vue';
  import AiAws from './settings/ai_aws.vue';
  import AiAzure from './settings/ai_azure.vue';
  import CdnAzure from './settings/cdn_azure.vue';
  import CdnQumu from './settings/cdn_qumu.vue';
  import ThirdpartyStaffbase from './settings/thirdparty_staffbase.vue';
  import AiGcp from './settings/ai_gcp.vue';
  import { errorMessage } from '../../mixins/flash_message';

  export default {
    props: ['id'],
    components: {
      IdentityOkta,
      IdentitySaml,
      AiAws,
      AiAzure,
      CdnAzure,
      CdnQumu,
      ThirdpartyStaffbase,
      AiGcp,
    },
    computed: {
      ...mapGetters([
        'integrationById'
      ]),
      integration() {
        return this.integrationById(this.id);
      },
      title() {
        return this.$t(`integrations.${this.id}_title`)
      },
    },
    methods: {
      ...mapActions([
        'updateSettings'
      ]),
      async apply() {
        const success = await this.$refs.validator.validate();
        if (!success) {
          return;
        }

        const settings = this.$refs.form.settings;
        const integration = this.integration;

        if (await this.updateSettings({ integration, settings })) {
          this.$router.push('/');
        } else {
          errorMessage(this.$i18n.t('integrations.settings_save_failed'));
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .integration-settings-heading {
    display: flex;
    align-items: center;

    h2 {
      font-size: 20px;
      font-weight: 300;
      margin: 0;
      margin: 30px 0 15px;
    }

    button {
      margin: 15px 15px 0 0;
    }
  }

  .integration-settings-body {
    ::v-deep .form-group.label-static {
      margin: 24px 0 0 0;
    }

    ::v-deep .form-group label.control-label {
      margin: 16px 0;
      color: $black-54-opacity;
      font-weight: 500;
    }

    ::v-deep .form-group.is-focused label.control-label {
      color: var(--primary-color);
    }
  }

  .integration-settings-actions {
    margin-top: 30px;
  }
</style>
