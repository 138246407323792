import { View, AvatarName, Outfit } from '@webcomputing/pose-animator';
import type { BackgroundReplacement } from '../types/background_replacement';

export enum AppState {
  'READY' = 'READY',
  'RECORDING' = 'RECORDING',
  'PROCESSING_RECORDING' = 'PROCESSING_RECORDING',
  'PREVIEW' = 'PREVIEW',
}

export interface PoseAnimatorSettings {
  view: View | undefined;
  avatar: AvatarName;
  outfit: Outfit;
}

export type CustomBackgroundImageId = string;

export interface CustomBackgroundImage {
  id: CustomBackgroundImageId;
  image: Blob;
  thumbnail: Blob;
}

export interface Settings {
  countdownDuration: number;
  webcamRatio: number;
  mirrorWebcam: boolean;
  backupActivated: boolean;
  backgroundReplacement: undefined | BackgroundReplacement;
  poseAnimator: PoseAnimatorSettings;
}

export interface State {
  appData: {
    manageContributionsUrl: string,
    siteId: number,
    siteNamespace: string,
    userId: number,
  } | null;
  settings: Settings;
  customBackgroundImages: CustomBackgroundImage[];
  appState: AppState;
  microphoneStream: MediaStream | null;
  webcamStream: MediaStream | null;
  displayStream: MediaStream | null;
  hybridStream: MediaStream | null;
  currentCountdown: number | null;
  duration: number;
  recording: Blob | null;
  settingsDialogOpen: boolean;
}

export const state: State = {
  appData: null,
  settings: {
    countdownDuration: 0,
    webcamRatio: 16 / 9,
    mirrorWebcam: false,
    backupActivated: true,
    backgroundReplacement: undefined,
    poseAnimator: {
      view: undefined,
      avatar: AvatarName.LUKE,
      outfit: Outfit.BUSINESS,
    },
  },
  customBackgroundImages: [],
  appState: AppState.READY,
  microphoneStream: null,
  webcamStream: null,
  displayStream: null,
  hybridStream: null,
  currentCountdown: null,
  duration: 0,
  recording: null,
  settingsDialogOpen: false,
};
