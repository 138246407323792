import Vue from 'vue';
import { v4 as uuid } from 'uuid';
import { vueApp } from '../../../course_plan/manage/component';

interface lessonGroups {
  id: Number,
  title: String,
  position: Number,
  isNew: Boolean,
  deleted: Boolean,
  lockedByUserId: Number,
  lessons: Array<{
    id: Number,
    title: String,
    description: String,
    lessonGroupId: Number,
    type: String,
    position: Number,
    isNew: Boolean,
    deleted: Boolean,
    subject: any,
    lockedByUserId: Number,
  }>
}

export const mutationsNeedingSave = [
  'course/setCourseLessonsAndLessonGroups',
  'course/updateLessonGroupPosition',
  'course/updateLessonPosition',
  'course/changeLessonGroupTitle',
  'course/changeLessonTitle',
  'course/createLessonGroup',
  'course/removeLessonGroup',
  'course/removeLesson',
  'course/saveEditorLesson',
  'course/addLesson',
]

export default {

  setLessonGroups(state, data : Array<lessonGroups>) {
    let lessonGroups = [];
    let lessons = [];
    // id & position are not set for a new course, so we set it to defaults Vue can handle
    data.forEach(lessonGroup => {
      if (!lessonGroup.id) {
        lessonGroup.id = uuid();
        lessonGroup.isNew = true;
      }
      lessonGroup.lockedByUserId = null;
      lessonGroup.lessons.forEach(lesson => {
        if (!lesson.id) {
          lesson.id = uuid();
          lesson.isNew = true;
        }
        lesson.lockedByUserId = null;
        lesson.lessonGroupId = lessonGroup.id;
        lessons.push(lesson)
      });

      lessonGroups.push(lessonGroup);
    })

    Vue.set(state, 'lessonGroups', lessonGroups);
    Vue.set(state, 'lessons', lessons);
  },

  setApiUrls(state, apiUrls) {
    state.apiUrls = apiUrls;
  },

  setIsCollaborative(state, isCollaborative) {
    state.isCollaborative = isCollaborative;
  },

  updateLessonGroupPosition(state, { lessonGroup, position }) {
    lessonGroup = state.lessonGroups.find(lg => lg.id === lessonGroup.id);
    lessonGroup.position = position;
  },

  updateLessonPosition(state, { lesson, position, lessonGroupId }) {
    lesson = state.lessons.find(l => l.id === lesson.id);
    lesson.position = position;
    lesson.lessonGroupId = lessonGroupId;
  },

  changeLessonGroupTitle(state, { lessonGroup, title = lessonGroup.title }) {
    lessonGroup = state.lessonGroups.find(lg => lg.id === lessonGroup.id);
    lessonGroup.title = title;
  },

  changeLessonTitle(state, { lesson, title = lesson.title }) {
    lesson = state.lessons.find(l => l.id === lesson.id);
    lesson.title = title;
  },

  createLessonGroup(state, { title = '', position = 1, id = uuid()}) {
    let index = position - 1;

    // Insert empty lesson group at given position
    state.lessonGroups.splice(index, 0, {
      id: id,
      title: title,
      position: position,
      isNew: true,
      lockedByUserId: null
    });

    // Update position for lesson groups after inserted group
    for (index++; index < state.lessonGroups.length; index++) {
      state.lessonGroups[index].position++;
    }
  },

  removeLessonGroup(state, lessonGroup) {
    let index = state.lessonGroups.findIndex(lg => lg.id === lessonGroup.id);

    if (index > -1) {
      if (state.lessonGroups[index].isNew) {
        state.lessonGroups.splice(index, 1);
      } else {
        Vue.set(state.lessonGroups[index], 'deleted', true);
      }

      for (; index < state.lessonGroups.length; index++) {
        state.lessonGroups[index].position--;
      }
    }
  },

  removeLesson(state, lesson) {
    let index = state.lessons.findIndex(l => l.id === lesson.id);

    if (index > -1) {
      if (state.lessons[index].isNew) {
        state.lessons.splice(index, 1);
      } else {
        Vue.set(state.lessons[index], 'deleted', true);
      }

      state.lessons.forEach(otherLesson => {
        if (lesson.lesson_group_id === otherLesson.lesson_group_id && otherLesson.position > lesson.position) {
          otherLesson.position--;
        }
      });
    }
  },

  setUiState(state, uiState) {
    state.uiState = uiState;
  },

  editLesson(state, lesson) {
    state.uiState = 'lessonEditor';
    state.lessonEditor.lessonType = lesson.type;
    state.lessonEditor.lesson = Object.assign({}, lesson);

    let newSubject = {
      ...lesson.subject,
      selected: true
    };

    Vue.set(state.lessonEditor, 'subject', newSubject);

    // Duplicate questions
    Vue.set(state.lessonEditor, 'questions', (lesson.questions || []).slice(0))
  },

  newLesson(state, { lessonType, lessonGroupId }) {
    if (lessonGroupId) {
      state.lessonEditor.lessonGroupId = lessonGroupId;
    }

    if (state.lessonEditor.lessonGroupId && lessonType) {
      const lesson = {
        id: uuid(),
        title: '',
        position: state.lessons.filter(lesson => !lesson.deleted && lesson.lessonGroupId === state.lessonEditor.lessonGroupId).length + 1,
        lessonGroupId: state.lessonEditor.lessonGroupId,
        isNew: true
      }

      state.uiState = 'lessonEditor';
      state.lessonEditor.lessonType = lessonType;
      state.lessonEditor.lesson = lesson;
      state.lessonEditor.questions = [];

      Vue.set(state.lessonEditor, 'subject', {
        id: null,
        type: null,
        previewUrl: null,
        previewHtml: null,
        selected: false
      });
    } else {
      state.uiState = 'lessonSelect';
    }
  },

  setLessonEditorSubject(state, subjectAttributes) {
    subjectAttributes.selected = subjectAttributes.id !== null && subjectAttributes.type !== null;

    state.lessonEditor.subject = subjectAttributes;
    Vue.set(state.lessonEditor.lesson, 'subject', subjectAttributes);
  },

  resetLessonEditorSubject(state) {
    state.lessonEditor.subject = {
      ...state.lessonEditor.subject,
      id: null,
      type: null,
      previewUrl: null,
      previewHtml: null,
      selected: false
    }
  },

  saveEditorLesson(state, lessonAttributes) {
    let lesson = Object.assign(state.lessonEditor.lesson, lessonAttributes);
    let lessonIndex = state.lessons.findIndex(l => l.id === lesson.id);
    Vue.set(lesson, "lockedByUserId", null);
    if (lessonIndex >= 0) {
      // Edited existing lesson
      state.lessons.splice(lessonIndex, 1, lesson);
    } else {
      // Created new lesson
      state.lessons.push(lesson);
    }

    state.uiState = 'lessonList'
    
  },

  addLesson(state, lesson) {
    let lessonIndex = state.lessons.findIndex(l => l.id === lesson.id);
    //Vue.set(lesson, "lockedByUserId", null);
    if (lessonIndex >= 0) {
      // Edited existing lesson
      state.lessons.splice(lessonIndex, 1, lesson);
    } else {
      // Created new lesson
      state.lessons.push(lesson);
    }
  },

  changeLessonType(state, type) {
    state.lessonEditor.lessonType = type;
    state.lessonEditor.lesson.type = type;
  },

  newQuestion(state, questionType) {
    state.lessonEditor.currentQuestion = {
      id: uuid(),
      title: '',
      hint: '',
      randomize: false,
      time: null,
      type: questionType,
      isNew: true,
      editing: false,
      options: [
        {
          id: uuid(),
          title: '',
          position: 1,
          correct: true,
          isNew: true
        },
        {
          id: uuid(),
          title: '',
          position: 2,
          correct: false,
          isNew: true
        }
      ]
    }
  },

  editQuestion(state, question) {
    state.lessonEditor.currentQuestion = question;
    Vue.set(state.lessonEditor.currentQuestion, 'editing', true);
  },

  removeQuestion(state, question) {
    const index = state.lessonEditor.questions.indexOf(question);

    if (state.lessonEditor.questions[index].isNew) {
      state.lessonEditor.questions.splice(index, 1);
    } else {
      Vue.set(state.lessonEditor.questions[index], 'deleted', true);
    }
  },

  resetQuestionChanges(state) {
    state.lessonEditor.currentQuestion = null;
  },

  clearQuestions(state) {
    state.lessonEditor.questions.forEach((question) => {
      Vue.set(question, 'deleted', true);
    });
  },

  saveQuestion(state, questionAttributes) {
    const question = Object.assign(state.lessonEditor.currentQuestion, questionAttributes);
    const questionIndex = state.lessonEditor.questions.findIndex(q => q.id === question.id);

    if (questionIndex >= 0) {
      state.lessonEditor.questions.splice(questionIndex, 1, question);
    } else {
      state.lessonEditor.questions.push(question);
    }

    state.lessonEditor.currentQuestion = null;
  },

  copyQuestions(state, questions) {
    state.lessonEditor.questions = [];

    questions.forEach(question => {
      question.id = uuid();
      question.isNew = true;

      question.options.forEach(option => {
        option.id = uuid();
        option.isNew = true;
      });

      state.lessonEditor.questions.push(question);
    });
  },

  setMediaData(state, mediaData) {
    state.lessonEditor.mediaData = mediaData;
  },

  addDeletedLesson(state, payload: { lesson: any, lessonGroupId: any }) {
    const deletedLesson = Object.assign({}, payload.lesson);
    deletedLesson.id = deletedLesson.oldId;
    deletedLesson.lessonGroupId = payload.lessonGroupId;
    deletedLesson.isNew = false;
    deletedLesson.deleted = true;
    state.lessons.push(deletedLesson);
  },

  processGroupSwitchForLesson(state, payload: { newLessonId: any, lessonId: any, lessonGroupId: any }) {
    let lesson = state.lessons.find(lesson => lesson.id === payload.lessonId);
    Vue.set(lesson, 'oldId', lesson.id);
    Vue.set(lesson, 'id', payload.newLessonId);
    Vue.set(lesson, 'isNew', true);
    Vue.set(lesson, 'lessonGroupId', payload.lessonGroupId);

    if (lesson.type === "Course::Lesson::ExternalLesson" || lesson.type === "Course::Lesson::CustomContentLesson") {
      Vue.set(lesson.subject, 'id', null);
      Vue.set(lesson.subject, 'type', null);
    }

    if (lesson.questions) {
      lesson.questions.forEach(question => {
        Vue.set(question, 'id', uuid());
        Vue.set(question, 'isNew', true);

        if (question.options) {
          question.options.forEach(option => {
            Vue.set(option, 'id', uuid());
            Vue.set(option, 'isNew', true);
          });
        }
      });
    }
  },

  lockLesson(state, {lesson, userId}) {
    lesson = state.lessons.find(l => l.id === lesson.id);
    Vue.set(lesson, 'lockedByUserId', userId);
  },

  unlockLesson(state, lesson) {
    lesson = state.lessons.find(l => l.id === lesson.id);
    Vue.set(lesson, 'lockedByUserId', null);
  },

  lockLessonGroup(state, {lessonGroup, userId}) {
    lessonGroup = state.lessonGroups.find(lg => lg.id === lessonGroup.id);
    Vue.set(lessonGroup, 'lockedByUserId', userId);
  },

  unlockLessonGroup(state, lessonGroup) {
    lessonGroup = state.lessonGroups.find(lg => lg.id === lessonGroup.id);
    Vue.set(lessonGroup, 'lockedByUserId', null);
  },
}
