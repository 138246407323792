function timestampFromSecond(seconds) {
  let minutes = ~~(seconds / 60);
  seconds = ~~(seconds % 60);

  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

export default class AudioPlayer {
  constructor (element, options) {
    this.element = element;
    this.player = element.querySelector('audio');
    this.control = element.querySelector('.audio-player-control');
    this.bar = element.querySelector('.audio-player-bar');
    this.barWrapper = this.bar.parentNode;
    this.options = options;

    this.currentWrapper = element.querySelector('.audio-player-time-current');
    this.durationWrapper = element.querySelector('.audio-player-time-duration');

    this.bindListeners();
  }

  loadSource (url) {
    return new Promise((resolve, reject) => {
      if (this.source === url) {
        resolve();
        return;
      }

      this.source = url;

      let xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 2 && xhr.status >= 300) {
          xhr.responseType = 'json';
        } else if (xhr.readyState === 4) {
          if (xhr.status >= 200 && xhr.status < 300) {
            this.player.src = URL.createObjectURL(xhr.response);

            this.element.classList.remove('audio-player-loading');
            this.control.classList.remove('disabled');
            resolve(xhr.response);
          } else {
            this.source = null;
            this.player.src = '';
            this.pause();

            this.element.classList.remove('audio-player-loading');
            this.control.classList.remove('disabled');
            reject(xhr);
          }
        }
      };

      if (typeof this.options.onRequest === 'function') {
        this.options.onRequest();
      }

      this.element.classList.add('audio-player-loading');
      this.control.classList.add('disabled');
      xhr.send();
    });
  }

  loadAndPlay (url) {
    this.loadSource(url).then(_ => {
      this.playPromise = this.player.play();
      this.control.innerText = 'pause';
    }).catch((xhr) => {
      this.bar.style.width = '0%';

      if (typeof this.options.onError === 'function') {
        this.options.onError(xhr.status, xhr.response, xhr);
      }
    });
  }

  pause () {
    let pause = () => {
      this.player.pause();
      this.control.innerText = 'play_arrow';
    };

    if (this.playPromise !== undefined) {
      this.playPromise.then(pause);
    } else {
      pause();
    }
  }

  update () {
    let currentTime = this.player.currentTime;
    let duration = this.player.duration;

    this.bar.style.width = parseInt(((currentTime / duration) * 100), 10) + '%';

    this.currentWrapper.innerText = timestampFromSecond(currentTime);
    this.durationWrapper.innerText = timestampFromSecond(duration);
  }

  setTimeFromMouseEvent (event) {
    event.preventDefault();
    if (!this.player.duration) { return; }

    let leftOffset = this.barWrapper.getBoundingClientRect().left;
    let position = (event.pageX + 10 - leftOffset) / this.barWrapper.offsetWidth;
    let time = position * this.player.duration;

    this.player.currentTime = time;
  }

  bindListeners () {
    this.player.addEventListener('timeupdate', () => {
      this.update();
    });

    this.player.addEventListener('loadedmetadata', () => {
      this.update();
    });

    this.player.addEventListener('ended', () => {
      this.control.innerText = 'play_arrow';
    });

    this.control.addEventListener('click', e => {
      if (this.player.paused) {
        let event = document.createEvent('Event');

        event.initEvent('beforeplay', true, true);
        this.player.dispatchEvent(event);
      } else {
        this.pause();
      }
    });

    let timeMouseEvt = this.setTimeFromMouseEvent.bind(this);

    this.barWrapper.addEventListener('click', timeMouseEvt);

    this.barWrapper.addEventListener('mousedown', (e) => {
      e.preventDefault();

      document.addEventListener('mousemove', timeMouseEvt);
    });

    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', timeMouseEvt);
    });
  }
}
