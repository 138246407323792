import { Panelbar } from './panelbar';
import { SelectionMode } from '../media_library/interfaces';

export function init () {
  this.component = new Panelbar();
  initLibraryButtons();
}

function initLibraryButtons() {
  const myTakesLink = document.getElementById('my-takes-link');
  const publicTakesLink = document.getElementById('public-takes-link');
  const myDocumentsLink = document.getElementById('my-documents-link');
  const clipsLink = document.getElementById('clips-link');
  const imageLink = document.getElementById('image-link');

  myTakesLink.addEventListener('click', () => {
    window.location.hash = 'library';
    VERSTEHE.MediaLibrary.library.openLibrary(SelectionMode.Multiple, 'privateTakes');
  });

  publicTakesLink.addEventListener('click', () => {
    window.location.hash = 'library';
    VERSTEHE.MediaLibrary.library.openLibrary(SelectionMode.Multiple, 'publicTakes');
  });

  myDocumentsLink.addEventListener('click', () => {
    window.location.hash = 'library';
    VERSTEHE.MediaLibrary.library.openLibrary(SelectionMode.Multiple, 'documents');
  });

  clipsLink.addEventListener('click', () => {
    VERSTEHE.MediaLibrary.library.openLibrary(SelectionMode.Multiple, 'clips');
  });

  imageLink.addEventListener('click', () => {
    VERSTEHE.MediaLibrary.library.openLibrary(SelectionMode.Multiple, 'images');
  });
}
