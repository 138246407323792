import { GetterTree } from "vuex";
import { ScreenplayState } from "./state";
import { RootState } from "../../store/types";
import { Take, Slide, Image } from "../interfaces";
import Utils from '../utils';
import { state } from "../../store";

export const Getters = {
  WORKSPACE_ITEMS: 'screenplay/workspaceItems',
  SECTIONS: 'screenplay/sections',
  SCENES: 'screenplay/scenes',
  SCREENPLAY: 'screenplay/screenplay',
  SCENE_FROM_WP_ITEM: 'screenplay/sceneFromWorkspaceItem',
  SCENE_DRAGGING: 'screenplay/sceneDragging',
  WORKSPACE_DRAGGING: 'screenplay/workspaceDragging',
  API_URLS: 'screenplay/apiUrls',
  SCREENPLAY_STATUS: 'screenplay/screenplayStatus',
  TOTAL_DURATION: 'screenplay/totalDuration',
  SCREENPLAY_SCENES: 'screenplay/screenplayScenes',
  SCENES_WITH_MEDIUM: 'screenplay/scenesWithMedium',
  WORKSPACE_MEDIUM: 'screenplay/workspaceMedium',
  TAKES_TO_UPLOAD: 'screenplay/takesToUpload',
  TAKE_UPLOADER_TOTAL_COUNT: 'screenplay/takeUploaderTotalCount',
  SCREENPLAY_VALID: 'screenplay/screenplayValid',
  NEEDS_PRODUCTION: 'screenplay/needsProduction',
  SCREENPLAY_ERRORS: 'screenplay/screenplayErrors',
  TRANSCRIPTION_LANGUAGE: 'screenplay/transcriptionLanguage',
  TRANSCRIPTION_LANGUAGE_REQUIRED: 'screenplay/transcriptionLanguageRequired',
  FIRST_SPEAKER: 'screenplay/firstSpeaker',
  AVAILABLE_VOICES: 'screenplay/availableVoices',
  HAS_CONVERTING: 'screenplay/hasConverting',
  NO_SPANISH_TRANSCRIPTION: 'screenplay/noSpanishTranscription'
}

export const getters: GetterTree<ScreenplayState, RootState> = {
  workspaceItems: state => {
    return state.workspace.sort((a: Take | Slide | Image, b: Take | Slide | Image) => (
      Date.parse(a.added_at) < Date.parse(b.added_at) ? 1 :
      (Date.parse(a.added_at) > Date.parse(b.added_at) ? -1 :
      (a.id < b.id ? 1 : -1 ))))
  },
  sections: state => state.screenplay.filter(section => !section.deleted),
  scenes: state => section_id => {
    const scenes = state.screenplay.find(section => section.id === section_id).scenes
    return scenes.filter(scene => !scene.deleted);
  },
  screenplay: state => state.screenplay,
  sceneFromWorkspaceItem: (state) => (type, id) => {
    let item = state.workspace.find(item => item.type === type && item.id === id);

    if(type === 'take') {
      return Utils.takeToScene(item as Take);
    } else if (type === 'slide') {
      return Utils.slideToScene(item as Slide);
    } else {
      return Utils.imageToScene(item as Image);
    }
  },
  sceneDragging: state => state.sceneDragging,
  workspaceDragging: state => state.workspaceDragging,
  apiUrls: state => state.apiUrls,
  screenplayStatus: state => state.screenplayStatus,
  totalDuration: state => {
    const sections = state.screenplay.filter(section => !section.deleted);
    return sections.map(section => {
      const scenes = section.scenes.filter(scene => !scene.deleted);
      return scenes.map(s => Number(s.duration)).reduce((a, b) => a + b, 0)
    }).reduce((a, b) => a + b, 0);
  },

  screenplayScenes: (state) => {
    return [].concat(...state.screenplay
      .filter(section => section.deleted === false)
      .map(section => section.scenes.filter(scene => scene.deleted === false)));
  },
  
  scenesWithMedium: (state, getters) => (medium: Take | Slide | Image) => {
    return getters.screenplayScenes.filter((scene) => {
      return scene.medium_type === `Media::${medium.type.charAt(0).toUpperCase() + medium.type.slice(1)}` && scene.medium_id === medium.id;
    });
  },
  workspaceMedium: state => (id, type) => state.workspace.find(medium => medium.id === id && medium.type === type),
  
  takesToUpload: (state, getters) => {
    // unique takes that are used in screenplay which aren't uploaded yet
    const takeIds: Array<Number> = getters.screenplayScenes.filter((scene) => scene.medium_type === 'Media::Take').map((scene) => scene.medium_id);
    const toUpload = getters.workspaceItems.filter(item => {
      return item.type === 'take' && takeIds.some(id => id === item.id) && item.state !== 'uploaded';
    }).filter((v, i, a) => a.indexOf(v) === i);

    return toUpload;
  },
  hasConverting: (state, getters) => {
    const takeIds: Array<Number> = getters.screenplayScenes.filter((scene) => scene.medium_type === 'Media::Take').map((scene) => scene.medium_id);
    const converting = getters.workspaceItems.filter(item => {
      return item.type === 'take' && takeIds.some(id => id === item.id) && item.state === 'converting';
    }).filter((v, i, a) => a.indexOf(v) === i);

    return converting.length > 0;
  },
  takeUploaderTotalCount: (state, getters) => {
    const takeIds: Array<Number> = getters.screenplayScenes.filter((scene) => scene.medium_type === 'Media::Take').map((scene) => scene.medium_id);
    const toUpload = getters.workspaceItems.filter(item => {
      return item.type === 'take' && takeIds.some(id => id === item.id) && (item.state === 'recorded' || item.state === 'uploading');
    }).filter((v, i, a) => a.indexOf(v) === i);

    return toUpload.length;
  },
  screenplayValid: (state) => state.valid,
  needsProduction: (state) => state.needsProduction,
  screenplayErrors: (state) => state.screenplayErrors,
  transcriptionLanguage: (state) => state.transcriptionLanguage,
  transcriptionLanguageRequired: (state) => state.transcriptionLanguageRequired,
  availableVoices: (state) => state.availableVoices,
  firstSpeaker: (state, getters) => {
    const firstWithTTS = getters.screenplayScenes.find(scene => scene.medium_type === 'Media::Slide' && scene.tts_enable === true);
    return firstWithTTS ? firstWithTTS.tts_speaker : null;
  },
  noSpanishTranscription: (state) =>  {
    return state.noSpanishTranscription
  }
};
